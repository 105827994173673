import { createSlice } from "@reduxjs/toolkit";
import IRequest from "../../../../models/IRequest";

type stateType = {
  requestList: {
    requestList: IRequest[];
    currentPage: number;
    totalPage: number;
  };
};

const initialRequestLists: IRequest[] = [];

const requestListSlice = createSlice({
  name: "requestList",
  initialState: {
    requestList: initialRequestLists,
    currentPage: 1,
    totalPage: 0,
  },
  reducers: {
    setRequestList(state, action) {
      state.requestList = action.payload;
    },
    setTotalPage(state, action) {
      state.totalPage = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const selectRequestList = (state: stateType) =>
  state.requestList.requestList;
export const selectTotalPage = (state: stateType) =>
  state.requestList.totalPage;
export const selectCurrentPage = (state: stateType) =>
  state.requestList.currentPage;

export const { setRequestList, setTotalPage, setCurrentPage } =
  requestListSlice.actions;

export default requestListSlice.reducer;
