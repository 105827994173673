import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  MdOutlineErrorOutline,
  MdOutlineHighlightOff,
  MdCheckCircleOutline,
} from "react-icons/md";

import { Input, Loader, UserContext } from "@gsp/gusto-front-common";

enum Status {
  PENDING,
  LOADING,
  ERROR_CLIENT,
  ERROR_SERVER,
  SUCCESS,
}

const StatusIcon = ({ status }: { status: Status }) => {
  switch (status) {
    case Status.SUCCESS:
      return (
        <MdCheckCircleOutline style={{ fontSize: 80, color: "#1A8067" }} />
      );
    case Status.ERROR_CLIENT:
    case Status.ERROR_SERVER:
      return (
        <MdOutlineHighlightOff style={{ fontSize: 80, color: "#E62336" }} />
      );
    default:
      return (
        <MdOutlineErrorOutline style={{ fontSize: 80, color: "#0065AE" }} />
      );
  }
};

interface ModalPhoneConfirmationProps {
  phoneNumber: string;
  callback: (confirmationCode: string) => Promise<any>;
  goToSubscription: () => void;
  closeModal: () => void;
}

const ModalPhoneConfirmation: FunctionComponent<
  ModalPhoneConfirmationProps
> = ({ phoneNumber, callback, goToSubscription, closeModal }) => {
  const [code, setCode] = useState("");
  const [status, setStatus] = useState<Status>(Status.PENDING);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (code.length === 4) {
      setStatus(Status.LOADING);
      callback(code)
        .then(() => {
          setUser({
            ...user,
            phoneNumber,
          });
          setStatus(Status.SUCCESS);
        })
        .catch((err) => {
          console.error("useEffect ModalPhoneConfirmation: ", err);
          setStatus(
            err.defaultMessageKey === "bad-code"
              ? Status.ERROR_CLIENT
              : Status.ERROR_SERVER
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, callback]);

  const subscribe = useCallback(() => {
    closeModal();
    goToSubscription();
  }, [closeModal, goToSubscription]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
  }, []);

  const sent = status !== Status.PENDING && status !== Status.LOADING;

  return (
    <div data-testid="modal-phone-confirmation" className="text-center">
      <div className="mb-3">
        <StatusIcon status={status} />
      </div>
      <div className="modal__title title bold mb-3">
        <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-title" />
      </div>
      {!sent && (
        <div className="mb-3">
          <div className="mb-3">
            <FormattedMessage
              id="mcel-request-bond-phone-confirmation-modal-label"
              values={{ phoneNumber }}
            />
          </div>
          <Input
            value={code}
            changeValue={setCode}
            disabled={status === Status.LOADING}
            id="mcel-phone-verification-code"
            dataTestid="mcel-phone-verification-code"
          />
        </div>
      )}
      {status === Status.SUCCESS && (
        <div data-testid="success" className="mb-3">
          <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-success" />
          <button
            className="button button-big modal__button"
            onClick={subscribe}>
            <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-subscribe" />
          </button>
        </div>
      )}
      {status === Status.ERROR_CLIENT && (
        <div data-testid="error-client" className="mb-3">
          <div className="mb-3">
            <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-fail" />
          </div>
          <button className="button modal__button" onClick={retry}>
            <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-retry" />
          </button>
        </div>
      )}
      {status === Status.ERROR_SERVER && (
        <div data-testid="error-server" className="mb-3">
          <div className="mb-3">
            <FormattedMessage id="technical-error" />
          </div>
        </div>
      )}
      {status === Status.LOADING && <Loader isVisible />}
      {status === Status.PENDING && (
        <div>
          <div className="label text-blue mb-3">
            <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-notice" />
          </div>
          <button className="button modal__button" onClick={closeModal}>
            <FormattedMessage id="mcel-request-bond-phone-confirmation-modal-retry" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ModalPhoneConfirmation;
