import React from "react";
import { Helmet } from "react-helmet";

const GoogleTagManager = () => {
  const googleTagId = "AW-1028712479";

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`}></script>
      <script>
        {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date()); gtag('config', '${googleTagId}');`}
      </script>
    </Helmet>
  );
};

export default GoogleTagManager;
