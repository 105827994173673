import { DateTime } from "luxon";
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  ReactNode,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  BondType,
  StepComponent,
  LanguageContext,
  getLocaleFormatter,
  Banner,
  Checkbox,
  Loader,
} from "@gsp/gusto-front-common";

import IQuotationResponse from "../../../../models/IQuotationResponse";
import IQuotationData from "../../../../models/IQuotationData";
import QuotationContext from "../../../../models/QuotationContext";

import apiUrls from "../../../../services/apiUrls";
import { authenticatedGet } from "../../../../services/authenticatedFetch";
import { callQuotationRequestAPI } from "../../../../services/quotation";

import "./style.scss";
import { useAdobeContext } from "../../../AdobeAnalytics/AdobeProvider";

const fetchMcelQuotation: (
  bondQuotationRequestId: string
) => Promise<IQuotationData> = (bondQuotationRequestId) => {
  return authenticatedGet(
    apiUrls.getQuotationRequest(bondQuotationRequestId)
  ) as Promise<IQuotationData>;
};

const getLinkByBondType = (bondType: BondType) => {
  switch (bondType) {
    case "PERSONAL_DEPOSIT":
    case "GUARANTEE_FIRST_REQUEST":
      return "/pdf/macautionenligne-modele_acte_de_caution_retenue_de_garantie_marche_public.pdf";
    case "MAIN_COMPANY":
    case "SUBCONTRACTOR":
      return "/pdf/macautionenligne-modele_acte_de_caution_retenue_de_garantie_marche_prive.pdf";
    case "SUBCONTRACTOR_PAYMENT_GUARANTEE":
      return "/pdf/macautionenligne-modele_acte_de_caution_caution_de_sous-traitance.pdf";
    case "JOINT_SURETY":
      return "/pdf/macautionenligne-modele_acte_de_caution_garantie_de_bonne_fin.pdf";
    default:
      return "#";
  }
};

const PriceOffer = ({
  context,
  restartStepper,
}: StepComponent<QuotationContext>) => {
  const [response, setResponse] = useState<IQuotationResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [premiumAmount, setPremiumAmount] = useState<number>(0);
  const { quotationRequest, selectRequest, pushStepDataLayer } = context;
  const { localeCode } = useContext(LanguageContext);
  const localeFormatter = getLocaleFormatter(localeCode);

  const intl = useIntl();

  const { pushEventDataLayer } = useAdobeContext();

  useEffect(() => {
    pushStepDataLayer(5, "J'obtiens un tarif");
  }, []);

  useEffect(() => {
    if (!response) {
      callQuotationRequestAPI(
        {
          amount: quotationRequest.bondValue,
          principalDebtAmount: quotationRequest.marketValue,
          taxIndication: quotationRequest.taxIndication,
          bondRate: quotationRequest.bondRate,
          beneficiaryCountry: 1,
          bondType: quotationRequest.bondType,
          duration: {
            days: quotationRequest.bondDuration?.quantity ?? 0,
            months: 0,
          },
          brokerIntermediaryCollectingFlag: false,
          companyId: "",
          startDate: quotationRequest.startDate,
          endDate: quotationRequest.endDate,
        },
        "fr"
      )
        .then((res) => {
          setPremiumAmount(res.premiumAmount);
          setResponse(res);
          pushEventDataLayer({
            eventType: "Quote request",
            eventName: "Quote created",
          });

          if (res.responseCode === "DENIED") {
            pushEventDataLayer({
              eventType: "Quote request",
              eventName: "Quote refused",
              eventDetail: res.reasonForResponseCode,
            });
          }
        })
        .catch((e) => {
          pushEventDataLayer({
            eventType: "Quote request",
            eventName: "Quote refused",
            eventDetail: intl.$t({
              id: (e.defaultMessageKey || "technical-error") as string,
            }),
          });
          setError((e.defaultMessageKey || "technical-error") as string);
        });
    }
  }, [response, quotationRequest, setPremiumAmount]);

  const handleCheckBoxChange = useCallback(() => {
    setIsValid((flag) => !flag);
  }, []);

  const goToDraftStep = useCallback(async () => {
    if (!response) {
      return;
    }

    const quotation = await fetchMcelQuotation(response.bondQuotationRequestId);
    selectRequest({
      status: "ACCEPTED",
      quotation,
    });
  }, [selectRequest, response]);

  const isGarantee = !(
    quotationRequest.bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE" ||
    quotationRequest.bondType === "JOINT_SURETY"
  );
  const worksDuration = Math.ceil(
    DateTime.fromJSDate(quotationRequest.endDate).diff(
      DateTime.fromJSDate(quotationRequest.startDate),
      "months"
    ).months
  );

  const link = (...chunks: Array<ReactNode>) => (
    <a
      target="_blank"
      href="https://tradescore.allianz-trade.com?utm_source=MCEL&utm_medium=FAE"
      rel="noreferrer">
      <u>{chunks}</u>
    </a>
  );

  const gcLink = (...chunks: Array<ReactNode>) => (
    <a
      href="/pdf/macautionenligne-conditions_generales.pdf"
      target="_blank"
      rel="noreferrer"
      onClick={(event) => event.stopPropagation()}>
      {chunks}
    </a>
  );

  const bondLink = (...chunks: Array<ReactNode>) => (
    <a
      href={getLinkByBondType(quotationRequest.bondType)}
      target="_blank"
      rel="noreferrer"
      onClick={(event) => event.stopPropagation()}>
      {chunks}
    </a>
  );

  return (
    <div data-testid="mcel-price-offer" className="mcel-price-offer">
      <div className="title text-blue mb-3">
        <FormattedMessage id="mcel-price-offer-title" />
      </div>
      {error ? (
        <div data-testid="mcel-bond-quotation-error">
          <Banner
            bannerType="error"
            bannerTitleKey={error}
            bannerTitleValues={{
              // @ts-ignore
              link,
            }}
          />
          <div className="controls">
            <button className="button button-big me-4" onClick={restartStepper}>
              <FormattedMessage id="mcel-price-offer-btn-new-quotation" />
            </button>
          </div>
        </div>
      ) : response ? (
        <div>
          <table className="mcel-price-offer-table text-blue mb-3">
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="mcel-price-offer-table-1" />
                </td>
                <td>
                  <FormattedMessage id="mcel-price-offer-table-2" />
                </td>
                <td>
                  <FormattedMessage id="mcel-price-offer-table-3" />
                </td>
              </tr>
              <tr className="mcel-price-offer-table__offer">
                <td>{localeFormatter.formatCurrency(premiumAmount, "EUR")}</td>
                <td>
                  {localeFormatter.formatCurrency(
                    quotationRequest.bondValue.value || 0,
                    "EUR"
                  )}
                </td>
                <td>{quotationRequest.priceExpireDate.toLocaleDateString()}</td>
              </tr>
            </tbody>
          </table>
          <div className="text-blue mb-3">
            <FormattedMessage
              id={
                isGarantee
                  ? "mcel-price-offer-duration-works-plus-12"
                  : "mcel-price-offer-duration-works"
              }
              values={
                isGarantee
                  ? {
                      worksDuration,
                      garanteeDuration: worksDuration + 12,
                    }
                  : {
                      garanteeDuration: worksDuration,
                    }
              }
            />
          </div>
          <div className="mb-3">
            <FormattedMessage id="mcel-price-offer-disclaimer" />
          </div>
          <div className="mb-3">
            <Checkbox
              labelKey="mcel-price-offer-user-agreement"
              labelValues={{
                gcLink,
                bondLink,
              }}
              changeValue={handleCheckBoxChange}
            />
          </div>
          <div className="controls">
            <button className="button button-big me-4" onClick={restartStepper}>
              <FormattedMessage id="mcel-price-offer-btn-new-quotation" />
            </button>
            <button
              data-testid="subscribe-button"
              className={`button button-big button--${
                isValid ? "red" : "disabled"
              }`}
              disabled={!isValid}
              onClick={goToDraftStep}>
              <FormattedMessage id="mcel-price-offer-btn-subscribe" />
            </button>
          </div>
        </div>
      ) : (
        <Loader isVisible />
      )}
    </div>
  );
};

export default PriceOffer;
