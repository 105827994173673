import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { Stepper, IStep } from "@gsp/gusto-front-common";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";
import { useSelector } from "react-redux";

import IRequest from "../../../models/IRequest";

import BondSummary from "./BondSummary";
import BondPayment from "./BondPayment";
import BondSignature from "./BondSignature";
import BondDraft from "./BondDraft";

import "./style.scss";
import { selectRequest } from "../RequestSlice/RequestSlice";
import { useIntl } from "react-intl";

type StepDataLayer = {
  processStep: string;
  processStepName: string;
};

type BondSubscribeStepContext = IRequest & {
  pushStepDataLayer: (data: StepDataLayer) => void;
};

const steps: IStep<BondSubscribeStepContext>[] = [
  {
    component: BondSummary,
    id: 1,
    labelKey: "mcel-step-bond-summary",
    customControls: true,
  },
  {
    component: BondDraft,
    id: 2,
    labelKey: "mcel-step-bond-draft",
    customControls: true,
  },
  {
    component: BondPayment,
    id: 3,
    labelKey: "mcel-step-bond-payment",
    customControls: true,
  },
  {
    component: BondSignature,
    id: 4,
    labelKey: "mcel-step-bond-signature",
    customControls: true,
    noReturn: true,
  },
];

const BondSubscribe = () => {
  const { pushPageLoadDataLayer } = useAdobeContext();
  const { formatMessage } = useIntl();

  const currentRequest = {
    ...useSelector(selectRequest),
    pushStepDataLayer: (stepDataLayer: StepDataLayer) => {
      const { quotation } = currentRequest;

      pushPageLoadDataLayer({
        ...stepDataLayer,
        pageName: "Bond subscription step " + stepDataLayer.processStep,
        siteSection: "BondSubscribe",
        event: "Virtual page",
        boundAmount: quotation.bondAmount.value,
        bondDuration: `${quotation.bondDuration.quantity} - ${quotation.bondDuration.unit}`,
        premiumAmount: quotation.grossPremiumAmount?.value,
        processDetail: formatMessage({
          id:
            "mcel-request-bond-type-" +
            currentRequest.quotation.bondType.toLowerCase(),
        }),
      });
    },
  };

  if (!currentRequest) {
    return <Navigate to="/quotations" replace />;
  }

  const initialStep =
    currentRequest.status === "PREPAID" ||
    currentRequest.status === "PENDING_SIGNATURE"
      ? 2
      : 0;

  return (
    <Stepper
      dataTestid="mcel-bond-subscribe"
      steps={steps}
      initialStep={initialStep}
      sharedContext={currentRequest}
    />
  );
};

export default BondSubscribe;
export { BondSubscribeStepContext };
