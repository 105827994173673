import React, { useState, useEffect, useContext, useCallback } from "react";
import { MdFolder } from "react-icons/md";
import { AxiosResponse } from "axios";
import { FormattedMessage } from "react-intl";

import { buildClassName, Banner, StepComponent } from "@gsp/gusto-front-common";

import UserAccountCreationContext from "../../../../models/UserAccountCreationContext";
import { verifyKYCUpload } from "../../../../services/userAccount";

const types = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];

const maxSize = 10000000;

const knownErrors = [
  "1100",
  "1101",
  "1102",
  "1103",
  "1104",
  "1105",
  "1200",
  "1201",
  "1202",
  "1203",
  "1204",
  "1205",
  "1207",
  "identity",
  "SIM",
  "sct",
];

const FileUpload = ({
  goToNextStep,
  goToPreviousStep,
  currentIndex,
}: StepComponent) => {
  const { data, pushStepDataLayer } = useContext(UserAccountCreationContext);
  const [file, setFile] = useState<null | File>(null);
  const [error, setError] = useState<null | string>(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    pushStepDataLayer(currentIndex, "Je passe le contrôle");
  }, []);

  const onFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      setFile(evt.target.files[0]);
    }
  };

  const handleError = useCallback(
    (res: AxiosResponse<any>) => {
      setError(
        res.status === 400 &&
          res.data.detail &&
          knownErrors.includes(res.data.detail as string)
          ? `mcel-account-creation-error-${res.data.detail as string}`
          : res.status === 409
          ? "mcel-account-creation-error-identity"
          : "technical-error"
      );
      setFile(null);
      setFileUploadProgress(0);
      setLoading(false);
    },
    [setError, setFile, setFileUploadProgress, setLoading]
  );

  const companyId = data.companyId;
  const uploadFile = useCallback(
    async (selectedFile: File) => {
      setError(null);
      if (companyId && selectedFile) {
        setLoading(true);
        try {
          const res = (await verifyKYCUpload(
            companyId,
            selectedFile,
            (progress: ProgressEvent) => {
              setFileUploadProgress(progress.loaded / progress.total);
            }
          )) as AxiosResponse<any>;
          if (res.status >= 400) {
            console.error(res);
            handleError(res);
          } else {
            goToNextStep();
          }
          setLoading(false);
        } catch (error: any) {
          console.error(error);
          const res = error.response as AxiosResponse;
          handleError(res);
        }
      }
    },
    [companyId, goToNextStep, setFileUploadProgress, handleError]
  );

  useEffect(() => {
    if (!file) {
      return;
    }
    if (!types.includes(file.type)) {
      setFile(null);
      setError("mcel-account-creation-file-upload-error-format");
    } else if (file.size > maxSize) {
      setFile(null);
      setError("mcel-account-creation-file-upload-error-size");
    } else {
      setError(null);
      setFileUploadProgress(0);
      uploadFile(file);
    }
  }, [file, uploadFile]);

  const backButtonClass = buildClassName({
    "button-secondary": true,
    "button--disabled": loading,
  });

  return (
    <>
      <div className="user-account-creation__scan-id">
        <div className="subheading mb-2">
          <FormattedMessage id="mcel-account-creation-file-upload-label" />
        </div>
        <div className="input-field input-field--file">
          <div className="file-name">
            {file ? file.name : ""}
            {error && (
              <FormattedMessage id="mcel-account-creation-file-upload-error-placeholder" />
            )}
          </div>
          <button
            className="button"
            style={{ opacity: file ? 0 : 1 }}
            data-testid="mcel-account-creation-file-upload-button">
            <MdFolder size="2em" />
            <FormattedMessage id="mcel-account-creation-file-upload-button" />
          </button>
          <input
            type="file"
            onChange={onFileChange}
            data-testid="mcel-account-creation-file-upload-input"
            onClick={(event) => {
              (event.target as HTMLInputElement).value = "";
            }}
          />
          <div
            className="input-field__progress-bar"
            style={{ width: `${fileUploadProgress * 100}%` }}
          />
        </div>
        <div className="error">
          {error && (
            <Banner bannerType="error">
              <FormattedMessage id={error} />
            </Banner>
          )}
        </div>
      </div>
      <div className="stepper-footer">
        <button
          className={backButtonClass}
          data-testid="previous-step"
          onClick={() => goToPreviousStep()}
          disabled={loading}>
          <FormattedMessage id="previous-step" />
        </button>
      </div>
    </>
  );
};

export default FileUpload;
