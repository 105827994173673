import { configureStore } from "@reduxjs/toolkit";
import bondListReducer from "../components/Dashboard/BondList/BondListSlice/BondListSlice";
import requestReducer from "../components/Dashboard/RequestSlice/RequestSlice";
import requestListReducer from "../components/Dashboard/RequestList/RequestListSlice/RequestListSlice";

export const store = configureStore({
  reducer: {
    eBondList: bondListReducer,
    request: requestReducer,
    requestList: requestListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
