import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { UserContext } from "@gsp/gusto-front-common";
import { baseApiUrl } from "../../../../services/apiUrls";

const AccountButton = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="mcel-account">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic" as="span">
          {`${user.givenName ?? ""} ${user.familyName ?? ""}`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="span">
            <Link to="/quotations">
              <FormattedMessage id="mcel-header-account-home" />
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            href={`${baseApiUrl}/revoke-logout`}
            title="Click to log out">
            <FormattedMessage id="mcel-header-account-logout" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AccountButton;
