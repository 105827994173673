import React from "react";

import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useLocation, matchRoutes } from "react-router-dom";

import { Breadcrumb } from "@myeh/design-system";

import IRouteData from "../../models/IRouteData";
import { splitPathname } from "../../utils/helpers";

import "./style.scss";

interface IRouteBreadCrumb {
  paths: IRouteData[];
}

const RouteBreadCrumb = ({ paths }: IRouteBreadCrumb) => {
  const { pathname } = useLocation();
  const pathnames = splitPathname(pathname);
  const matchedRoutes = pathnames
    .flatMap((path) => matchRoutes(paths, path))
    .filter(Boolean);

  return (
    <Container className="route-breadcrumb">
      <Breadcrumb style={{ marginLeft: ".75rem", marginRight: ".75rem" }}>
        {matchedRoutes.map((element) => {
          return (
            <Breadcrumb.Item
              key={element!.route.titleKey}
              data-testid={element!.route.titleKey}>
              <FormattedMessage id={element!.route.titleKey} />
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </Container>
  );
};

export default RouteBreadCrumb;
