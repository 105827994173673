import React from "react";
import IAccountCreationData from "./IAccountCreationData";

const UserAccountCreationContext = React.createContext({
  pushStepDataLayer: (processStep: number, processStepName: string) => {},
  data: {} as IAccountCreationData,
  updateData: (data: IAccountCreationData) => {},
});

export default UserAccountCreationContext;
