import React, { useState, useCallback, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Grid } from "@myeh/design-system";

import {
  Banner,
  Dropdown,
  Input,
  Loader,
  Modal,
  StepComponent,
  UserContext,
} from "@gsp/gusto-front-common";

import BondRequestContext from "../../../../models/BondRequestContext";
import { bondDraftRequest } from "../../../../services/bonds";
import {
  formatFrenchPhoneNumber,
  frenchInternationalPrefixes,
  getPrefixFrenchPhoneNumber,
  phoneRegister,
} from "../../../../services/phone";
import { isValidFrenchMobilePhoneNumber } from "../../../../services/validator";

import ModalPhoneConfirmation from "./ModalPhoneConfirmation";

const BondTelephoneConfirmation = ({
  goToPreviousStep,
  context,
}: StepComponent<BondRequestContext>) => {
  const { draft, goToSubscription } = context;
  const [phone, setPhone] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("+33");
  const [errorPhone, setErrorPhone] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [checkPhone, setCheckPhone] = useState(false);
  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer(4, "Confirmation téléphone");
  }, []);

  const { user } = useContext(UserContext);
  useEffect(() => {
    const formatedPhone =
      user.phoneNumber && formatFrenchPhoneNumber(user.phoneNumber);
    const prefix =
      user.phoneNumber && getPrefixFrenchPhoneNumber(user.phoneNumber);
    if (formatedPhone) {
      setPhone(formatedPhone);
    }
    if (prefix) {
      setPhonePrefix(prefix);
    }
  }, [user]);

  const handlePhoneChange = useCallback(
    (value: string) => {
      setErrorPhone(!isValidFrenchMobilePhoneNumber(value));

      const formattedPhone = formatFrenchPhoneNumber(value);

      if (formattedPhone) {
        setPhone(formattedPhone);
      }
    },
    [setPhone]
  );

  const handleBackButtonClick = useCallback(() => {
    goToPreviousStep();
  }, [goToPreviousStep]);

  const createBond = useCallback(
    (verificationCode?: string) => {
      return bondDraftRequest(draft, verificationCode);
    },
    [draft]
  );

  const disabled = submitted || errorPhone || phone === "";

  const handleVerifyPhoneButtonClick = useCallback(async () => {
    try {
      setSubmitted(true);
      setCheckPhone(false);
      const res = (await phoneRegister(phone, phonePrefix)) as number;
      if (res === 200) {
        setLoading(true);
        try {
          await createBond();
          setCheckPhone(true);
          goToSubscription();
        } catch (err) {
          setError(true);
          setLoading(false);
        }
      } else {
        setOpenModal(true);
      }
    } catch (err) {
      setError(true);
    }
  }, [phone, phonePrefix, createBond, goToSubscription, setOpenModal]);

  const closeModal = useCallback(() => setOpenModal(false), [setOpenModal]);

  return (
    <div data-testid="mcel-telephone" className="mcel-bond-request mb-3">
      <div className="subheading text-blue mb-3">
        <FormattedMessage id="mcel-request-bond-phone-confirmation-title" />
      </div>
      <div className="caption text-blue mb-3">
        <FormattedMessage id="mcel-request-bond-phone-confirmation-disclaimer" />
      </div>
      <div>
        {loading ? (
          <Loader isVisible={true} />
        ) : (
          <>
            <div
              style={{
                minWidth: "25rem",
              }}>
              <Grid>
                <Grid.Row className="phone-group">
                  <Grid.Column size="4,3">
                    <Dropdown
                      value={phonePrefix}
                      items={frenchInternationalPrefixes}
                      onChange={setPhonePrefix}
                      labelKey="prefix"
                    />
                  </Grid.Column>
                  <Grid.Column size="8,9">
                    <Input
                      labelKey="mcel-request-bond-phone-confirmation-input-label"
                      value={phone}
                      changeValue={handlePhoneChange}
                      defaultError={
                        errorPhone
                          ? "mcel-request-bond-phone-confirmation-input-error"
                          : ""
                      }
                      isRequired={true}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <button
              data-testid="mcel-verify-phone"
              onClick={handleVerifyPhoneButtonClick}
              className={`button button--center mcel-bond-request__verify-button button-big ${
                disabled ? "button--disabled" : ""
              } mb-3`}
              disabled={disabled}>
              <FormattedMessage id="mcel-request-bond-phone-confirmation-button" />
            </button>
            {checkPhone && (
              <span
                data-testid="check-phone"
                style={{ display: "hidden" }}></span>
            )}
          </>
        )}
        {error && (
          <Banner bannerType="error" bannerTitleKey="technical-error" />
        )}
        <button
          data-testid="back-button"
          onClick={handleBackButtonClick}
          className={
            "button button-big button-secondary button--center mcel-bond-request__back-button "
          }>
          <FormattedMessage id="previous-step" />
        </button>
      </div>
      <Modal
        titleKey=""
        isOpen={openModal}
        setOpenState={setOpenModal}
        onModalClose={() => setSubmitted(false)}
        forceStayOpen={true}>
        <ModalPhoneConfirmation
          phoneNumber={phone}
          callback={createBond}
          goToSubscription={goToSubscription}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};

export default BondTelephoneConfirmation;
