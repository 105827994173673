import React, { useState, useCallback, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";

import {
  Banner,
  Checkbox,
  Input,
  Loader,
  Modal,
  StepComponent,
} from "@gsp/gusto-front-common";

// TODO Use reducer instead
import searchCompany from "../../../../services/searchCompany";
import { isValidSirenNumber } from "../../../../services/validator";
import { getLocalIdentifierFormat } from "../../../../services/localIdentifiers";
import UserAccountCreationContext from "../../../../models/UserAccountCreationContext";
import ICompany from "../../../../models/ICompany";

import companyIcon from "../../../../resources/company-icon.svg";
import { useAdobeContext } from "../../../AdobeAnalytics/AdobeProvider";

const { formatter, parser } = getLocalIdentifierFormat("SIREN");

const CompanySearch = ({ setStepCompleted, currentIndex }: StepComponent) => {
  const { data, updateData, pushStepDataLayer } = useContext(
    UserAccountCreationContext
  );
  const [company, setCompany] = useState<ICompany | null>(data.company || null);
  const [siren, setSiren] = useState(
    data.company?.localIdentifiers.find((item) => item.idTypeCode === "SIREN")
      ?.idValue || ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [disclaimer, setDisclaimer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { pushErrorDataLayer } = useAdobeContext();
  const intl = useIntl();

  useEffect(() => {
    pushStepDataLayer(currentIndex, "J'identifie mon entreprise");
  }, []);

  useEffect(() => {
    if (company) {
      updateData({
        companyId: company.companyId,
        company,
      });
    }
  }, [company, updateData]);

  const handleSirenChange = useCallback(async (value: string) => {
    const formatted = formatter(value);
    setSiren(formatted);
    setCompany(null);
    if (isValidSirenNumber(formatted)) {
      setLoading(true);
      try {
        const res = await searchCompany("FR", "SIREN", parser(formatted));
        setLoading(false);
        setError(null);
        if (res.companyData) {
          setCompany(res.companyData);
        }
      } catch (err: any) {
        pushErrorDataLayer(
          "GET /new-account/search-company?countryCode={countryCode}&idTypeCode={idTypeCode}&idValue={idValue}",
          "technical-error",
          intl.$t({
            id: (err.defaultMessageKey || "technical-error") as string,
          })
        );
        setLoading(false);
        setError((err.defaultMessageKey || "technical-error") as string);
      }
    } else {
      setCompany(null);
    }
  }, []);

  const handleCheckBoxChange = useCallback(() => {
    setDisclaimer((state) => !state);
  }, [setDisclaimer]);

  useEffect(() => {
    setStepCompleted(disclaimer && !!company);
  }, [disclaimer, company, setStepCompleted]);

  return (
    <div className="user-account-creation__company-search">
      <div className="search-input mb-3">
        <FormattedMessage id="mcel-account-creation-company-search-input-label" />
        <Input
          value={siren}
          changeValue={handleSirenChange}
          placeholder="Saisissez un n° de SIREN"
          dataTestid="mcel-user-account-creation-siren"
        />
      </div>
      {loading && <Loader isVisible />}
      {company && (
        <Container
          data-testid="mcel-account-creation-search-result"
          className="search-result text-blue text-center">
          <Row className="company-data">
            <Col sm={2}>
              <img src={companyIcon} alt="company icon" />
            </Col>
            <Col>
              <h4>{company.companyName}</h4>
              <div className="info">
                <FormattedMessage id="mcel-request-details-beneficiary-id-type" />
                :{" "}
                <strong>
                  {
                    company.localIdentifiers.filter(
                      (li) => li.idTypeCode === "SIREN"
                    )[0].idValue
                  }
                </strong>{" "}
                <br />
                {company.address &&
                  [
                    company.address.streetName,
                    company.address.postCode,
                    company.address.town,
                    company.address.countryCode,
                  ]
                    .filter(Boolean)
                    .join(" • ")}
              </div>
            </Col>
          </Row>
          <Row>
            <Checkbox
              labelKey="mcel-account-creation-company-search-disclaimer"
              dataTestid="mcel-account-creation-company-search-disclaimer"
              changeValue={handleCheckBoxChange}
            />
          </Row>
          <Row>
            <span className="label text--error">
              <FormattedMessage id="mcel-account-creation-company-search-bad-company-text" />
              <span
                className="bad-company-link"
                onClick={() => setOpenModal(true)}>
                <FormattedMessage id="mcel-account-creation-company-search-bad-company-link" />
              </span>
            </span>
          </Row>
        </Container>
      )}
      {error && (
        <Banner bannerType="error">
          <FormattedMessage id={error} />
        </Banner>
      )}
      <Modal
        isOpen={openModal}
        setOpenState={setOpenModal}
        titleKey=""
        textKey="mcel-account-creation-company-search-bad-company-modal-text"
        confirmLabelKey="mcel-account-creation-company-search-bad-company-modal-button"
        handleConfirmClick={() => {}}
      />
    </div>
  );
};

export default CompanySearch;
