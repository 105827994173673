import { loadStripe } from "@stripe/stripe-js";

import stripeMocks from "./stripeMocks";

window.IS_MOCKED =
  window.Cypress !== undefined || process.env.REACT_APP_IS_MOCKED === "true";

if (!process.env.REACT_APP_STRIPE_API) {
  console.error("No Stripe API Key found");
}
const stripePromise = window.IS_MOCKED
  ? stripeMocks()
  : loadStripe(process.env.REACT_APP_STRIPE_API ?? "", { locale: "fr" });

export default stripePromise;
