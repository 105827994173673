import {
  IDuration,
  BondType,
  IAmount,
  ErrorDescriptor,
  ManagedError,
  TECHNICAL_ERROR,
} from "@gsp/gusto-front-common";

import IQuotationResponse from "../models/IQuotationResponse";
import apiUrls from "./apiUrls";
import { authenticatedPost } from "./authenticatedFetch";
import { formatDuration } from "./durations";
import { serializeDate } from "./dates";

interface QuotationRequestPayload {
  amount: IAmount;
  beneficiaryCountry: number;
  companyId: string;
  duration: IDuration;
  bondType: BondType;
  brokerIntermediaryCollectingFlag: boolean;
}

interface MCELQuotationPayload extends QuotationRequestPayload {
  startDate: Date;
  endDate: Date;
  principalDebtAmount: IAmount;
  bondRate: number;
  taxIndication: string;
}

const getMCELQuotationErrorDescriptor = (code: string): ErrorDescriptor => ({
  defaultMessageKey: `mcel-quotation-${code.toLocaleLowerCase()}`,
});

const responseOptions = {
  responseHandler: async (_: number, res: Response) => {
    if (!res.ok) {
      throw new ManagedError(TECHNICAL_ERROR);
    }
    const response = (await res.json()) as IQuotationResponse;
    if (response.responseCode === "REJ") {
      throw new ManagedError(
        getMCELQuotationErrorDescriptor(
          response.reasonForResponseCode || "null"
        )
      );
    }
    return response;
  },
};

export const callQuotationRequestAPI = (
  {
    amount,
    beneficiaryCountry,
    bondType,
    duration,
    companyId,
    brokerIntermediaryCollectingFlag,
    startDate,
    endDate,
    principalDebtAmount,
    bondRate,
    taxIndication,
  }: MCELQuotationPayload,
  locale: string
): Promise<IQuotationResponse> => {
  const serializedPayload = {
    amount,
    beneficiaryCountry,
    bondType,
    brokerIntermediaryCollectingFlag,
    companyId,
    duration: formatDuration(duration),
    startDate: serializeDate(startDate),
    endDate: serializeDate(endDate),
    principalDebtAmount,
    bondRate,
    taxIndication,
  };

  return authenticatedPost(apiUrls.quotationRequest(), {
    payload: serializedPayload,
    headerKeyValues: {
      "Accept-Language": locale,
    },
    ...responseOptions,
  }) as Promise<IQuotationResponse>;
};
