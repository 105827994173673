/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stripe } from "@stripe/stripe-js";

/* eslint-disable @typescript-eslint/no-unsafe-return */
const mockElement = () => ({
  mount: () => {},
  destroy: () => {},
  on: () => {},
  update: () => {},
});

const mockElements = () => {
  const elements: any = {};
  return {
    create: (type: any) => {
      elements[type] = mockElement();
      return elements[type];
    },
    update: (type: any) => {
      elements[type] = mockElement();
      return elements[type];
    },
    getElement: (type: any) => {
      return elements[type] || null;
    },
    fetchUpdates: () => {
      return elements || null;
    },
  };
};

const stripeMocks: () => Stripe = () => ({
  elements: () => mockElements(),
  // @ts-ignore
  createToken: () => {},
  // @ts-ignore
  createSource: () => {},
  // @ts-ignore
  createPaymentMethod: () => {},
  // @ts-ignore
  confirmCardPayment: () => {},
  // @ts-ignore
  confirmCardSetup: () => {},
  // @ts-ignore
  paymentRequest: () => {},
  registerAppInfo: () => {},
  _registerWrapper: () => {},
});

export default stripeMocks;
