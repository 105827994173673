import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Container } from "react-bootstrap";

import { buildClassName, Button } from "@gsp/gusto-front-common";

import img from "../../../../resources/video.a90b4902.png";
import location from "../../../../resources/icons/location.svg";
import surety from "../../../../resources/icons/surety.svg";
import ok from "../../../../resources/icons/ok.svg";

const About = () => {
  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = useCallback(() => {
    setCollapse((state) => !state);
  }, [setCollapse]);

  const expertiseClassName = buildClassName({
    "mcel-home__about__expertise": true,
    "mcel-home__about__expertise--collapse": collapse,
  });

  return (
    <section
      data-testid="mcel-home-about"
      className="mcel-home__section mcel-home__about"
      id="about">
      <Container>
        <FormattedMessage id="mcel-home-about-title" tagName="h2" />
        <Row>
          <Col md={8} className="text-left">
            <div className="text-blue">
              <FormattedMessage id="mcel-home-about-name" tagName="h4" />
            </div>
            <FormattedMessage id="mcel-home-about-desc" tagName="h4" />
            <FormattedMessage id="mcel-home-about-text-1" tagName="p" />
            <FormattedMessage id="mcel-home-about-text-2" tagName="p" />
            <FormattedMessage id="mcel-home-about-text-3" tagName="p" />
            <Row className="justify-content-center mt-5">
              <Button
                i18nKey={"mcel-home-about-cta"}
                onClick={() =>
                  window.open(
                    "https://www.allianz-trade.fr/nous-contacter/conseiller/technique.html",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              />
            </Row>
          </Col>
          <Col md={4}>
            <img src={img} alt="video" className="mcel-home__about__img" />
          </Col>
        </Row>
        <div
          data-testid="mcel-home-about-expertise"
          className={expertiseClassName}>
          <hr className="mcel-home__about__divider" />
          <div className="text-blue">
            <FormattedMessage id="mcel-home-about-subtitle" tagName="h2" />
          </div>
          <div className="text-left">
            <FormattedMessage id="mcel-home-about-text-4" tagName="p" />
            <FormattedMessage id="mcel-home-about-text-5" tagName="p" />
            <FormattedMessage id="mcel-home-about-text-6" tagName="p" />
            <FormattedMessage id="mcel-home-about-text-7" tagName="p" />
            <ul className="mcel-home__about__list">
              <li className="mcel-home__about__item">
                <img
                  src={location}
                  alt="location"
                  className="mcel-home__about__item__img"
                />
                <div className="mcel-home__about__item__nb">
                  <FormattedMessage
                    id="mcel-home-about-location-nb"
                    tagName="h3"
                  />
                </div>
                <div className="mcel-home__about__item__text">
                  <FormattedMessage
                    id="mcel-home-about-location-text"
                    tagName="h4"
                  />
                </div>
              </li>
              <li className="mcel-home__about__item">
                <img
                  src={surety}
                  alt="surety"
                  className="mcel-home__about__item__img"
                />
                <div className="mcel-home__about__item__nb">
                  <FormattedMessage
                    id="mcel-home-about-surety-nb"
                    tagName="h3"
                  />
                </div>
                <div className="mcel-home__about__item__text">
                  <FormattedMessage
                    id="mcel-home-about-surety-text"
                    tagName="h4"
                  />
                </div>
              </li>
              <li className="mcel-home__about__item">
                <img
                  src={ok}
                  alt="ok"
                  className="mcel-home__about__item__img"
                />
                <div className="mcel-home__about__item__nb">
                  <FormattedMessage id="mcel-home-about-ok-nb" tagName="h3" />
                </div>
                <div className="mcel-home__about__item__text">
                  <FormattedMessage id="mcel-home-about-ok-text" tagName="h4" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="mcel-home__why__collapse-button">
        <button
          data-testid="mcel-home-about-collapse-btn"
          className="button button-circle"
          onClick={toggleCollapse}>
          {collapse ? "+" : "-"}
        </button>
        <FormattedMessage id={`mcel-home-why-${collapse ? "more" : "less"}`} />
      </div>
    </section>
  );
};

export default About;
