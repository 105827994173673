import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Banner } from "@gsp/gusto-front-common";

import { fetchBondList } from "../../../services/bondList";

import SkeletonCard from "../SkeletonCard";
import BondCard from "./BondCard";

import {
  selectBondList,
  selectCurrentPage,
  selectTotalPage,
  setBondList,
  setCurrentPage,
  setTotalPage,
} from "./BondListSlice/BondListSlice";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";

const BondList = () => {
  const dispatch = useDispatch();
  const bondList = useSelector(selectBondList);
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPage);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { pushPageLoadDataLayer, pushErrorDataLayer } = useAdobeContext();

  const { formatMessage } = useIntl();

  useEffect(() => {
    pushPageLoadDataLayer({
      pageName: "Bond List",
      siteSection: "Bond",
      event: "pageLoad",
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchBondList(currentPage, 10)
      .then((list) => {
        setIsLoading(false);
        dispatch(setBondList([...bondList, ...list.bonds]));
        dispatch(setTotalPage(list.totalPages));
        setError(undefined);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.defaultMessageKey as string);
        pushErrorDataLayer(
          "GET /mcel/bonds?page={page}&pageSize={pageSize}",
          e.defaultMessageKey,
          formatMessage({ id: e.defaultMessageKey })
        );
      });
  }, [currentPage]);

  return (
    <Container>
      <div className="request-list__header">
        <h3>
          <FormattedMessage id="mcel-bond-list-title" />
        </h3>
        <Link to="/new-quotation">
          <button className="button-secondary">
            <FormattedMessage id="mcel-request-list-new-quotation-btn" />
          </button>
        </Link>
      </div>
      {bondList &&
        bondList.map((bond) => {
          return (
            <div key={bond.bondId}>
              <BondCard bond={bond} />
            </div>
          );
        })}
      {!isLoading && currentPage < totalPages && (
        <button
          className="button-secondary button--center mb-3"
          onClick={() => dispatch(setCurrentPage(currentPage + 1))}>
          <FormattedMessage id="mcel-bond-list-load-more" />
        </button>
      )}
      {error && <Banner bannerTitleKey="technical-error" bannerType="error" />}
      {isLoading &&
        new Array(10).fill(0).map((_, idx) => <SkeletonCard key={idx} />)}
    </Container>
  );
};

export default BondList;
