import React from "react";
import { Navigate } from "react-router-dom";

import { baseApiUrl } from "../../../services/apiUrls";

interface ILogin {
  isLoggedIn: boolean;
  isAuthorized: boolean;
}

const Login = ({ isLoggedIn, isAuthorized }: ILogin) => {
  if (!isLoggedIn) {
    window.location.replace(`${baseApiUrl}/idp-login`);
    return null;
  }

  if (!isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return <Navigate to="/quotations" replace />;
};

export default Login;
