/**
 * Rounds a number with a defined number of digits after the decimal point.
 * This round will prefer down round if the value to round is absolute half.
 *
 * @param num The number to round
 * @param digits The digits numbers for the round.
 * @returns The rounded value
 */
export const bondRound = (num: number, digits = 2) => {
  const multiplier = Math.pow(10, digits) * (num < 0 ? 1 : -1);
  const ceilMultiplier = multiplier * 10;
  const ceiled = Math.ceil(num * ceilMultiplier) / ceilMultiplier;
  return Math.round(ceiled * multiplier) / multiplier;
};
