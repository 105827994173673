import { IDuration, IDurationByUnit } from "@gsp/gusto-front-common";

export const formatDuration = (duration: IDuration): IDurationByUnit =>
  duration.days > 0
    ? {
        quantity: duration.days,
        unit: "DD",
      }
    : {
        quantity: duration.months,
        unit: "MM",
      };
