import { createSlice } from "@reduxjs/toolkit";
import IBond from "../../../../models/IBond";

type stateType = {
  eBondList: {
    bondList: IBond[];
    currentPage: number;
    totalPage: number;
  };
};

const initialBondLists: IBond[] = [];

const bondListSlice = createSlice({
  name: "eBondLists",
  initialState: {
    bondList: initialBondLists,
    currentPage: 1,
    totalPage: 0,
  },
  reducers: {
    setBondList(state, action) {
      state.bondList = action.payload;
    },
    setTotalPage(state, action) {
      state.totalPage = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const selectBondList = (state: stateType) => state.eBondList.bondList;
export const selectTotalPage = (state: stateType) => state.eBondList.totalPage;
export const selectCurrentPage = (state: stateType) =>
  state.eBondList.currentPage;

export const { setBondList, setTotalPage, setCurrentPage } =
  bondListSlice.actions;

export default bondListSlice.reducer;
