import { ManagedError, TECHNICAL_ERROR } from "@gsp/gusto-front-common";

import ICompany from "../models/ICompany";

import apiUrls from "./apiUrls";
import { authenticatedGet } from "./authenticatedFetch";
import { ACCOUNT_CREATION_COMPANY_NOT_FOUND } from "./error";

interface CompanySearchResult {
  companyData?: ICompany;
}

const searchCompany = (
  countryCode: string,
  identifierType: string,
  identifier: string
): Promise<CompanySearchResult> =>
  authenticatedGet(
    apiUrls.newAccountSearchCompany(countryCode, identifierType, identifier),
    {
      responseHandler: (status: number, content: Response) => {
        switch (status) {
          case 200:
            return content.json();
          case 204:
            throw new ManagedError(ACCOUNT_CREATION_COMPANY_NOT_FOUND);
          default:
            throw new ManagedError(TECHNICAL_ERROR);
        }
      },
    }
  ).then((value: ICompany) => ({
    companyData: value,
  }));

export default searchCompany;
