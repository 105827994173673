import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { InfoText } from "@gsp/gusto-front-common";

export interface ICardInformation {
  labelMessageId: string;
  value?: string | null;
  infoText?: string;
}

const CardInformation = ({
  labelMessageId,
  value,
  infoText,
}: ICardInformation) => {
  const em = (...chunks: Array<ReactNode>) => <em>{chunks}</em>;

  return (
    <>
      <div className="request-card__details__label caption">
        <FormattedMessage id={labelMessageId} />
      </div>
      <div className="request-card__details__value">
        {value || (
          <FormattedMessage
            id="mcel-request-data-not-available"
            values={{
              em,
            }}
          />
        )}
        {infoText && (
          <span className="mcel-subscribe__group__info">
            <InfoText textKey={infoText} />
          </span>
        )}
      </div>
    </>
  );
};

export default CardInformation;
