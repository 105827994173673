import {
  BondType,
  LocaleFormatter,
  formatReferenceNumber,
} from "@gsp/gusto-front-common";
import { formatDate } from "../../../../utils/helpers";
import IQuotationData from "../../../../models/IQuotationData";
import IRequestTransaction from "../../../../models/IRequestTransaction";
import { IntlShape } from "react-intl";
import { getCompanyAddress } from "../../../../services/strings";
import { getLocalIdentifierFormat } from "../../../../services/localIdentifiers";
import IRequest from "../../../../models/IRequest";
import { BondSummaryField } from "./BondSummaryGroup";
import { TemplateCode } from "../../../../models/TemplateCode";

const checkValue = <T>(value: T) => {
  return value ?? null;
};

const { formatter } = getLocalIdentifierFormat("SIREN");

export const getContactInfo = (
  quotation: IQuotationData,
  localeFormatter: LocaleFormatter,
  transaction?: IRequestTransaction
): BondSummaryField[] => [
  {
    label: "mcel-bond-subscribe-contract-reference",
    value:
      transaction &&
      formatReferenceNumber(
        transaction.referenceNumber,
        transaction.additionalContractClause
      ),
  },
  {
    label: "mcel-bond-subscribe-contract-sign-date",
    value: transaction && formatDate(transaction.transactionDate),
  },
  {
    label: "mcel-bond-subscribe-contract-start-date",
    value: transaction && formatDate(quotation.startDate),
  },
  {
    label: "mcel-bond-subscribe-contract-reception-date",
    value: transaction && formatDate(quotation.endDate),
  },
  {
    label: "mcel-bond-subscribe-contract-market-value",
    value:
      quotation.principalDebtAmount &&
      localeFormatter.formatCurrency(
        quotation.principalDebtAmount.value,
        "EUR"
      ),
  },
  {
    label: "mcel-bond-subscribe-contract-works-address",
    value: [
      transaction?.addressLine,
      transaction?.postalCode,
      transaction?.city,
    ]
      .filter(Boolean)
      .join(" "),
  },
  {
    label: "mcel-bond-subscribe-contract-works-description",
  },
];

export const getPaymentInfo = (
  quotation: IQuotationData,
  localeFormatter: LocaleFormatter,
  intl: IntlShape
): BondSummaryField[] => [
  {
    label: "mcel-bond-payment-type",
    value: intl.formatMessage({
      id: "mcel-bond-payment-type-card",
    }),
  },
  {
    label: "mcel-bond-payment-amount",
    value: localeFormatter.formatCurrency(
      quotation.premiumAmount!.value,
      "EUR"
    ),
    infoText: "mcel-bond-payment-amount-info",
  },
  {
    label: "mcel-bond-payment-expire",
    value: formatDate(quotation.expirationTimestamp),
    infoText: "mcel-bond-payment-expire-info",
  },
];

export const getBondInfo = (
  quotation: IQuotationData,
  localeFormatter: LocaleFormatter,
  transaction?: IRequestTransaction
): BondSummaryField[] => [
  {
    label: "mcel-bond-subscribe-bond-amount",
    value: localeFormatter.formatCurrency(quotation.bondAmount.value, "EUR"),
  },
  {
    label: "mcel-bond-subscribe-bond-start-date",
    value: formatDate(transaction?.transactionStartDate),
  },
  {
    label: "mcel-bond-subscribe-bond-end-date",
    value: formatDate(quotation.bondEndDate),
    infoText: "mcel-bond-subscribe-bond-end-date-info",
  },
];

export const getBeneficiaryInfo = (context: IRequest): BondSummaryField[] => [
  {
    label: "mcel-bond-subscribe-beneficiary-name",
    value: context.beneficiary && checkValue(context.beneficiary.companyName),
  },
  {
    label: "mcel-bond-subscribe-beneficiary-id",
    value: context.beneficiary && formatter(context.beneficiary.sirenNumber),
  },
  {
    label: "mcel-bond-subscribe-beneficiary-address",
    value:
      context.beneficiary && getCompanyAddress(context.beneficiary.address),
  },
];

export const getIntegerAndDecimal = (
  amount: number
): { integer: string; decimal: string } => {
  const amounts = (amount + "").split(".");
  return {
    integer: amounts[0],
    decimal: amounts[1],
  };
};

export const getTemplateCodeFromBondType = (
  bondType: Omit<
    BondType,
    | ""
    | "BID"
    | "PERFORMANCE"
    | "LIS"
    | "LOTTO"
    | "LOTTERY"
    | "TAX_STAMPS"
    | "UNA_TANTUM"
    | "NINE_YEAR"
    | "PERSONAL_DEPOSIT"
  >
): TemplateCode => {
  switch (bondType) {
    case "SUBCONTRACTOR":
    case "MAIN_COMPANY":
      return "MAINTENANCE_PRIVATE";
    case "GUARANTEE_FIRST_REQUEST":
    case "PERSONAL_DEPOSIT":
      return "MAINTENANCE_PUBLIC";
    case "JOINT_SURETY":
      return "PERFORMANCE_MCEL";
    case "SUBCONTRACTOR_PAYMENT_GUARANTEE":
    default:
      return "PAYMENT_SUBCONTRACTORS";
  }
};
