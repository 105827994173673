import React from "react";
import { FormattedMessage } from "react-intl";

const Info = () => {
  return (
    <section className="mcel-home__section mcel-home__public-info">
      <div className="container">
        <FormattedMessage id="mcel-home-public-info" />
      </div>
    </section>
  );
};

export default Info;
