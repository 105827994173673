import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { CookieContext } from "@gsp/gusto-front-common";

const AdobeAnalytics = () => {
  const { preferences } = useContext(CookieContext);

  return preferences.thirdParty ? (
    <Helmet>
      <script src={process.env.REACT_APP_ADOBE_LINK} async />
    </Helmet>
  ) : null;
};

export default AdobeAnalytics;
