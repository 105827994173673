import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Banner, StepComponent, Modal } from "@gsp/gusto-front-common";
import UserAccountCreationContext from "../../../../models/UserAccountCreationContext";

const ModalContent = () => {
  return (
    <div>
      {Array(4)
        .fill(0)
        .map((_, idx) => (
          <Fragment key={idx}>
            <div className="subheading text-blue mb-3">
              <FormattedMessage
                id={`mcel-account-creation-scan-modal-question-${idx + 1}`}
              />
            </div>
            <div className="body1-light mb-3">
              <FormattedMessage
                id={`mcel-account-creation-scan-modal-answer-${idx + 1}`}
              />
            </div>
          </Fragment>
        ))}
    </div>
  );
};

const ScanID = ({ setStepCompleted, currentIndex }: StepComponent) => {
  const [openModal, setOpenModal] = useState(false);

  const { pushStepDataLayer } = useContext(UserAccountCreationContext);

  useEffect(() => {
    pushStepDataLayer(currentIndex, "Je numérise ma pièce d'identité");
  }, []);

  useEffect(() => {
    setStepCompleted(true);
  }, [setStepCompleted]);

  return (
    <div className="user-account-creation__scan-id">
      <div className="title text-blue">
        <FormattedMessage id="mcel-account-creation-scan-title" />
      </div>
      <div className="subheading text-blue mb-3">
        <FormattedMessage id="mcel-account-creation-scan-subheading" />
      </div>
      <div className="caption mb-3">
        <FormattedMessage id="mcel-account-creation-scan-caption" />
      </div>
      <Banner bannerType="info">
        <p>
          <FormattedMessage id="mcel-account-creation-scan-banner-1" />
        </p>
        <p>
          <FormattedMessage id="mcel-account-creation-scan-banner-2" />
          <span
            className="user-account-creation__scan-id__link"
            onClick={() => setOpenModal(true)}>
            <FormattedMessage id="mcel-account-creation-scan-modal-link" />
          </span>
        </p>
      </Banner>
      <Modal
        isOpen={openModal}
        setOpenState={setOpenModal}
        titleKey="Conseil pour la numérisation"
        confirmLabelKey="mcel-account-creation-company-search-bad-company-modal-button"
        handleConfirmClick={() => {}}>
        <ModalContent />
      </Modal>
    </div>
  );
};

export default ScanID;
