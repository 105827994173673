import React, { useState, useEffect, useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FormField } from "@myeh/design-system";

import { Input, StepComponent } from "@gsp/gusto-front-common";

import QuotationContext from "../../../../models/QuotationContext";
import { bondRound } from "../../../../services/bondRound";

import "./style.scss";

const BondValue = ({
  context,
  setStepCompleted,
}: StepComponent<QuotationContext>) => {
  const { quotationRequest, updateQuotationRequest, pushStepDataLayer } =
    context;

  useEffect(() => {
    pushStepDataLayer(3, "Montant de la caution");
  }, []);

  const refValue = useRef(
    bondRound(quotationRequest.marketValue.value * quotationRequest.bondRate)
  ).current;
  const minValue = refValue >= 5 ? refValue - 5 : refValue;
  const maxValue = refValue + 5;
  const [bondValue, setBondValue] = useState(
    quotationRequest.bondValue.value || refValue
  );

  useEffect(() => {
    updateQuotationRequest({
      bondValue: {
        value: bondValue,
        currencyCode: "EUR",
      },
    });
    setStepCompleted(bondValue !== 0);
  }, [bondValue, updateQuotationRequest, setStepCompleted]);

  const increaseBondValue = useCallback(() => {
    if (bondValue < maxValue) {
      setBondValue((value) => value + 5);
    }
  }, [bondValue, maxValue]);

  const decreaseBondValue = useCallback(() => {
    if (bondValue > minValue) {
      setBondValue((value) => value - 5);
    }
  }, [bondValue, minValue]);

  return (
    <div data-testid="mcel-bond-value">
      <div className="title text-blue mb-4">
        <FormattedMessage id="mcel-bond-value-title" />
      </div>
      <div
        data-testid="mcel-bond-value-subtitle"
        className="body1-light text-blue mb-4">
        <FormattedMessage
          id={
            quotationRequest.bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE"
              ? "mcel-bond-value-subtitle-2"
              : "mcel-bond-value-subtitle"
          }
          values={{ percent: quotationRequest.bondRate * 100 }}
        />
      </div>
      <Input
        dataTestid="mcel-bond-value-input"
        disabled={true}
        value={bondValue.toFixed(2) + " €"}
        changeValue={() => {}}
        formFieldProps={{
          style: {
            padding: 0,
            color: "black",
          },
        }}
        suffix={
          quotationRequest.bondType !== "SUBCONTRACTOR_PAYMENT_GUARANTEE" && (
            <FormField.Suffix
              style={{
                margin: 0,
              }}>
              <span className="number-control">
                <button
                  data-testid="mcel-bond-value-inc"
                  className="number-control-button number-control-button--inc"
                  onClick={increaseBondValue}>
                  <MdKeyboardArrowUp />
                </button>
                <button
                  data-testid="mcel-bond-value-dec"
                  className="number-control-button number-control-button--dec"
                  onClick={decreaseBondValue}>
                  <MdKeyboardArrowDown />
                </button>
              </span>
            </FormField.Suffix>
          )
        }
      />
    </div>
  );
};

export default BondValue;
