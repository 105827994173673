import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { differenceInDays, startOfDay } from "date-fns";

import {
  Input,
  AutoCompleteInput,
  AutoCompleteInputItems,
  TextArea,
  DateInput,
  StepComponent,
} from "@gsp/gusto-front-common";

import IRequestTransaction from "../../../../models/IRequestTransaction";
import BondRequestContext from "../../../../models/BondRequestContext";

import { isValidFrenchPostalCode } from "../../../../services/validator";

import { getPostalCodeInfo } from "../../../../services/postalCode";
import { serializeDate } from "../../../../services/dates";

const today = startOfDay(new Date());

const BondWorks = ({
  context,
  setStepCompleted,
}: StepComponent<BondRequestContext>) => {
  const intl = useIntl();
  const { draft, updateDraft, request } = context;
  const [transaction, setTransaction] = useState<IRequestTransaction>(
    draft.transaction
  );
  const [cities, setCities] = useState<AutoCompleteInputItems[] | undefined>();
  const [errorDate, setErrorDate] = useState(false);
  const [errorPostalCode, setErrorPostalCode] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer(3, "Travaux");
  }, []);

  const {
    addressLine,
    postalCode,
    city,
    referenceNumber,
    additionalContractClause,
    transactionDate,
    description,
  } = transaction;

  const updateTransaction = useCallback(
    (value: string, key: string) => {
      setTransaction({
        ...transaction,
        [key]: value,
      });
    },
    [setTransaction, transaction]
  );

  const updatePostalCode = useCallback(
    async (value: string) => {
      const isValid = isValidFrenchPostalCode(value);
      setErrorPostalCode(!isValid);
      setCities(undefined);
      setErrorCity(false);
      if (isValid) {
        try {
          const res = await getPostalCodeInfo(value, "FR");
          if (res.length === 1) {
            setTransaction({
              ...transaction,
              city: res[0].placeName,
              postalCode: value,
            });
          } else {
            setTransaction({
              ...transaction,
              postalCode: value,
            });
            setCities(
              res.map(({ placeName }) => {
                return { key: placeName, value: placeName };
              })
            );
          }
        } catch (err) {
          setErrorCity(true);
          setTransaction({
            ...transaction,
            city: "",
            postalCode: value,
          });
          console.error(err);
        }
      } else {
        setTransaction({
          ...transaction,
          city: "",
          postalCode: value,
        });
      }
    },
    [setErrorPostalCode, setTransaction, transaction, setCities]
  );

  const updateCity = useCallback(
    (value: string) => {
      setTransaction({
        ...transaction,
        city: value,
      });
    },
    [setTransaction, transaction]
  );

  const updateTransactionDate = useCallback(
    (date: Date | null) => {
      setErrorDate(!date || differenceInDays(date, today) > 0);

      setTransaction({
        ...transaction,
        transactionDate: date ? serializeDate(date)! : "",
      });
    },
    [setTransaction, transaction]
  );

  const updateTransactionError = useCallback(
    (error) => {
      setErrorDate(true);
    },
    [setErrorDate]
  );

  useEffect(() => {
    updateDraft({ transaction });
  }, [transaction, updateDraft]);

  const { bondType, startDate, endDate } = request.quotation;

  const showsReference = bondType !== "SUBCONTRACTOR_PAYMENT_GUARANTEE";

  const showsEndorsement =
    bondType !== "SUBCONTRACTOR_PAYMENT_GUARANTEE" &&
    bondType !== "JOINT_SURETY";

  const showsOrderNumber = bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE";

  useEffect(() => {
    const isValid =
      !errorPostalCode &&
      !errorDate &&
      postalCode !== "" &&
      description !== "" &&
      (referenceNumber !== "" || !showsReference) &&
      (additionalContractClause !== "" || !showsOrderNumber);

    setStepCompleted(isValid);
  }, [
    errorPostalCode,
    errorDate,
    postalCode,
    referenceNumber,
    additionalContractClause,
    showsReference,
    showsOrderNumber,
    description,
    setStepCompleted,
  ]);

  return (
    <div data-testid="mcel-works" className="mcel-bond-request mb-3">
      <div className="subheading text-blue mb-3">
        <FormattedMessage id="mcel-request-bond-works-title" />
      </div>
      <div className="caption text-blue">
        <FormattedMessage id="mcel-request-bond-works-start" />{" "}
        <strong>{startDate}</strong>
      </div>
      <div className="caption text-blue mb-3">
        <FormattedMessage id="mcel-request-bond-works-end" />{" "}
        <strong>{endDate}</strong>
      </div>
      <div className="label text-blue mb-3">
        <FormattedMessage id="mcel-request-bond-works-disclaimer" />
      </div>
      <Container>
        <Row>
          <Col sm={6}>
            <Input
              dataTestid="address"
              labelKey="mcel-request-bond-works-address"
              value={addressLine}
              changeValue={(value) => updateTransaction(value, "addressLine")}
              isRequired={true}
            />
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={6}>
                <AutoCompleteInput
                  dataTestid="postal-code"
                  labelKey="mcel-request-bond-works-postal-code"
                  value={postalCode}
                  changeValue={(value) => {
                    if (/^\d{0,5}$/.exec(value)) {
                      updatePostalCode(value);
                    }
                  }}
                  onItemSelect={updateCity}
                  items={cities}
                  defaultError={
                    errorPostalCode
                      ? "mcel-request-bond-works-postal-code-error"
                      : errorCity
                      ? "mcel-request-bond-works-city-error"
                      : ""
                  }
                  isRequired={true}
                />
                {(errorPostalCode || errorCity) && (
                  <span
                    data-testid="check-error"
                    style={{ display: "hidden" }}></span>
                )}
              </Col>
              <Col xs={6}>
                <Input
                  labelKey="mcel-request-bond-works-city"
                  value={city}
                  changeValue={(value) => updateTransaction(value, "city")}
                  readOnly={true}
                />
                {city && (
                  <span
                    data-testid="check-city"
                    style={{ display: "hidden" }}></span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Row>
              {showsReference && (
                <Col sm={6}>
                  <Input
                    dataTestid="reference"
                    labelKey="mcel-request-bond-works-reference"
                    value={referenceNumber || ""}
                    changeValue={(value) =>
                      updateTransaction(value, "referenceNumber")
                    }
                    isRequired={true}
                    maxLength={25}
                    leftCharsKey="mcel-request-bond-works-left-chars"
                  />
                </Col>
              )}
              {showsEndorsement && (
                <Col sm={6}>
                  <Input
                    dataTestid="endorsement"
                    labelKey="mcel-request-bond-works-endorsement"
                    placeholder={intl.formatMessage({
                      id: "mcel-request-bond-works-endorsement-placeholder",
                    })}
                    value={additionalContractClause || ""}
                    changeValue={(value) =>
                      updateTransaction(value, "additionalContractClause")
                    }
                    infoText="mcel-request-bond-works-endorsement-info-text"
                    maxLength={12}
                    leftCharsKey="mcel-request-bond-works-left-chars"
                  />
                </Col>
              )}
              {showsOrderNumber && (
                <Col sm={6}>
                  <Input
                    dataTestid="order-number"
                    labelKey="mcel-request-bond-works-order-number"
                    value={additionalContractClause || ""}
                    changeValue={(value) =>
                      updateTransaction(value, "additionalContractClause")
                    }
                    maxLength={12}
                    leftCharsKey="mcel-request-bond-works-left-chars"
                    isRequired={true}
                  />
                </Col>
              )}
              <Col sm={6} dataTestid="transaction-date">
                <DateInput
                  labelKey="mcel-request-bond-works-sign-date"
                  initialValue={new Date(transactionDate)}
                  onChange={updateTransactionDate}
                  onError={updateTransactionError}
                  isRequired={true}
                  maxDate={today}
                  defaultError={
                    errorDate ? "mcel-request-bond-works-error-sign-date" : ""
                  }
                  locale="fr"
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <TextArea
              dataTestid="description"
              labelKey="mcel-request-bond-works-description"
              value={description}
              changeValue={(value) => updateTransaction(value, "description")}
              isRequired={true}
              maxLength={450}
              leftCharsKey="mcel-request-bond-works-left-chars"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BondWorks;
