import React, { useState, useCallback, useEffect } from "react";

import {
  CookieContext,
  defaultPreferences,
  CookieCategories,
  ICookiePreferences,
  getCookie,
  setCookie,
} from "@gsp/gusto-front-common";

const readPreferences = (cookieValue: string) => {
  const preferences = { ...defaultPreferences };
  cookieValue.split(",").forEach((value) => {
    (preferences as any)[value] = true;
  });
  return preferences;
};

const writePreferences = (preferences: ICookiePreferences) =>
  CookieCategories.filter((category) => preferences[category]).join(",");

const CookieContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [preferences, setPreferences] = useState(defaultPreferences);
  const [registered, setRegistered] = useState(false);
  const updatePreferences = useCallback(
    (updates: Partial<ICookiePreferences>) => {
      const updated = {
        ...preferences,
        ...updates,
      };
      setPreferences(updated);
      setCookie("GDPR_PREFERENCES", writePreferences(updated));
      setRegistered(true);
    },
    [preferences]
  );

  useEffect(() => {
    const cookieValue = getCookie("GDPR_PREFERENCES");
    if (cookieValue) {
      setRegistered(true);
      const registeredPreferences = readPreferences(cookieValue);
      setPreferences(registeredPreferences);
    }
  }, []);

  return (
    <CookieContext.Provider
      value={{
        preferences,
        updatePreferences,
        registered,
      }}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
