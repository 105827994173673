import React from "react";
import { Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import { Notification } from "@myeh/design-system";
import { Button, Logo } from "@gsp/gusto-front-common";

import AccountButton from "./AccountButton";
import LoginButton from "./LoginButton";

import homeIcon from "../../../resources/home.svg";
import "./style.scss";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";

const NavigationItems = ({ isAuthorized }: { isAuthorized: boolean }) => {
  const { pushCtaDataLayer } = useAdobeContext();

  return (
    <>
      {!isAuthorized ? (
        <>
          <Nav.Item>
            <NavLink to="/register">
              <Button
                dataTestid="mcel-create-account-button"
                i18nKey="mcel-create-account-button"
                onClick={() => pushCtaDataLayer("Create account")}
              />
            </NavLink>
          </Nav.Item>
          <Nav.Item className="mcel-login-nav">
            <LoginButton />
          </Nav.Item>
        </>
      ) : (
        <Nav.Item>
          <AccountButton />
        </Nav.Item>
      )}
    </>
  );
};

const AccountSubHeader = () => {
  return (
    <Container className="header-mcel-sub-container">
      <Nav>
        <Nav.Item>
          <NavLink to="/" className="nav-link active">
            <img src={homeIcon} alt="Home button" />
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/quotations"
            className={(navData) =>
              navData.isActive ? "nav-link active" : "nav-link"
            }>
            <FormattedMessage id="mcel-page-requests" />
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/ebonds"
            className={(navData) =>
              navData.isActive ? "nav-link active" : "nav-link"
            }>
            <FormattedMessage id="mcel-page-bonds" />
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <Dropdown className="nav-link">
            <Dropdown.Toggle variant="success" id="dropdown-basic" as="span">
              <FormattedMessage id="mcel-header-download-title" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[
                {
                  id: "mcel-header-download-general-conditions",
                  link: "/pdf/macautionenligne-conditions_generales.pdf",
                },
                {
                  id: "mcel-header-download-private-market",
                  link: "/pdf/macautionenligne-modele_acte_de_caution_retenue_de_garantie_marche_prive.pdf",
                },
                {
                  id: "mcel-header-download-public-market",
                  link: "/pdf/macautionenligne-modele_acte_de_caution_retenue_de_garantie_marche_public.pdf",
                },
                {
                  id: "mcel-header-download-solidarity",
                  link: "/pdf/macautionenligne-modele_acte_de_caution_garantie_de_bonne_fin.pdf",
                },
                {
                  id: "mcel-header-download-outsourcing",
                  link: "/pdf/macautionenligne-modele_acte_de_caution_caution_de_sous-traitance.pdf",
                },
              ].map(({ id, link }) => (
                <Dropdown.Item
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={id}>
                  <FormattedMessage id={id} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
      </Nav>
    </Container>
  );
};

interface IHeader {
  isAuthorized: boolean;
}

const Header = ({ isAuthorized }: IHeader) => {
  return (
    <header className="mcel-header">
      <Container>
        <Navbar expand="lg" data-testid="header" className="header header-mcel">
          <div className="header-main">
            <div className="left-part">
              <Link to="/">
                <Logo />
              </Link>
              <FormattedMessage
                id="mcel-home-masthead-allianz-trade-disclaimer"
                tagName="p"
              />
            </div>
            <div className="right_part">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
          </div>
          <div className="header-controls">
            <Navbar.Collapse id="basic-navbar-nav">
              <NavigationItems isAuthorized={isAuthorized} />
            </Navbar.Collapse>
          </div>
        </Navbar>
      </Container>
      <hr className="header__separator" />
      {isAuthorized && <AccountSubHeader />}
    </header>
  );
};

export default Header;
