import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { Banner, Modal, ErrorDescriptor } from "@gsp/gusto-front-common";

import IBondInvoice from "../../../../../models/IBondInvoice";
import IBond from "../../../../../models/IBond";
import InvoiceLink from "../InvoiceLink/InvoiceLink";

export interface IInvoicesModal {
  invoices?: Array<IBondInvoice>;
  onModalClose?: () => void;
  bond: IBond;
}

const InvoicesModal = ({ invoices, onModalClose, bond }: IInvoicesModal) => {
  const [error, setError] = useState<ErrorDescriptor>();

  const onError = useCallback(
    (error?: ErrorDescriptor) => {
      setError(error);
    },
    [setError]
  );

  return (
    <Modal
      isOpen={!!invoices}
      onModalClose={onModalClose}
      titleKey="mcel-invoices-list-title">
      <Row>
        <Col>
          <ul>
            {invoices?.map((invoice) => (
              <li key={invoice.avalisDocumentId}>
                <InvoiceLink invoice={invoice} onError={onError} bond={bond} />
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      {error && (
        <Row>
          <Banner
            bannerType="error"
            bannerTitleKey="mcel-bond-list-download-invoice-error"
          />
        </Row>
      )}
    </Modal>
  );
};

export default InvoicesModal;
