import { DropdownItem } from "@gsp/gusto-front-common";
import apiUrls from "./apiUrls";
import { authenticatedPost } from "./authenticatedFetch";
import { addSpacesToString } from "./strings";

export const getPrefixFrenchPhoneNumber = (value: string) => {
  return value.startsWith("+33")
    ? "+33"
    : value.startsWith("+262")
    ? "+262"
    : value.startsWith("+689")
    ? "+689"
    : value.startsWith("+594")
    ? "+594"
    : value.startsWith("+590")
    ? "+590"
    : undefined;
};

export const formatFrenchPhoneNumber = (value: string) => {
  const numbers = value.startsWith("+33")
    ? value.slice(3).replace(/\D/g, "")
    : value.replace(/\D/g, "");
  const nbs = numbers.startsWith("0") ? numbers : `0${numbers}`;
  if (nbs.length > 10) {
    return;
  }
  return addSpacesToString(nbs, [2, 4, 6, 8]);
};

export const frenchInternationalPrefixes: DropdownItem[] = [
  { code: "+33", labelKey: "+33", dontTranslate: true },
  { code: "+262", labelKey: "+262", dontTranslate: true },
  { code: "+590", labelKey: "+590", dontTranslate: true },
  { code: "+594", labelKey: "+594", dontTranslate: true },
  { code: "+689", labelKey: "+689", dontTranslate: true },
];

export const sanitizeFrenchNumber = (phone: string, prefix: string) => {
  const nb = phone.replace(/\D/g, "");
  return `${prefix}${nb.startsWith("0") ? nb.slice(1) : nb}`;
};

export const phoneRegister = (phoneNumber: string, prefix: string) => {
  return authenticatedPost(`${apiUrls.phoneRegister()}`, {
    headerKeyValues: {
      "Accept-Language": "fr",
    },
    // eslint-disable-next-line id-blacklist
    payload: { number: sanitizeFrenchNumber(phoneNumber, prefix) },
    responseHandler: (res: any) => Promise.resolve(res),
  });
};
