import React, { useEffect } from "react";

import { Modal } from "@gsp/gusto-front-common";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";

import { baseApiUrl } from "../../../services/apiUrls";

import Info from "./Info";
import Menu from "./Menu";
import MastHead from "./MastHead";
import Why from "./Why";
import How from "./How";
import Video from "./Video";
import About from "./About";

import "./style.scss";

interface IHome {
  isLoggedIn: boolean;
  isAuthorized: boolean;
}

const Home = ({ isLoggedIn, isAuthorized }: IHome) => {
  const { pushPageLoadDataLayer } = useAdobeContext();

  useEffect(() => {
    pushPageLoadDataLayer({
      pageName: "Home",
      siteSection: "Home",
      event: "Page",
    });
  }, []);

  return (
    <div data-testid="mcel-home">
      <Info />
      <Menu />
      <MastHead isAuthorized={isAuthorized} />
      <Why />
      <How />
      <Video />
      <About />
      <Modal
        titleKey="connected-with-non-authorized-account-title"
        textKey="connected-with-non-authorized-account-description"
        isOpen={isLoggedIn && !isAuthorized}
        handleConfirmClick={() => {
          window.location.replace(`${baseApiUrl}/revoke-logout`);
        }}
      />
    </div>
  );
};

export default Home;
