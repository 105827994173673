import React, { useState, useCallback, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectRequest, setRequest } from "../RequestSlice/RequestSlice";
import { Stepper, IStep } from "@gsp/gusto-front-common";

import BondRequestContext from "../../../models/BondRequestContext";
import BondDraft from "../../../models/BondDraft";

import { serializeDate } from "../../../services/dates";
import { fetchRequest } from "../../../services/requests";

import BondLiability from "./BondLiability";
import BondBeneficiary from "./BondBeneficiary";
import BondWorks from "./BondWorks";
import BondTelephoneConfirmation from "./BondTelephoneConfirmation";
import BondModifiedConfirmation from "./BondModifiedConfirmation";

import "./style.scss";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";
import { useIntl } from "react-intl";

const commonSteps: IStep<BondRequestContext>[] = [
  {
    component: BondLiability,
    id: 1,
    labelKey: "mcel-step-bond-liability",
  },
  {
    component: BondBeneficiary,
    id: 2,
    labelKey: "mcel-step-bond-beneficiary",
  },
  {
    component: BondWorks,
    id: 3,
    labelKey: "mcel-step-bond-works",
  },
];

const creationSteps = [
  ...commonSteps,
  {
    component: BondTelephoneConfirmation,
    id: 4,
    labelKey: "mcel-step-bond-phone-confirmation",
    customControls: true,
  },
];

const modificationSteps = [
  ...commonSteps,
  {
    component: BondModifiedConfirmation,
    id: 4,
    labelKey: "mcel-step-bond-modified-confirmation",
    customControls: true,
  },
];

interface IBondRequest {
  editMode?: boolean;
}

const BondRequest = ({ editMode = false }: IBondRequest) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentRequest = useSelector(selectRequest);
  const { pushPageLoadDataLayer } = useAdobeContext();
  const { formatMessage } = useIntl();

  const pushStepDataLayer = (
    processStep: string | number,
    processStepName: string
  ) => {
    pushPageLoadDataLayer({
      processStep: String(processStep),
      processStepName,
      bondAmount: currentRequest.quotation.bondAmount.value,
      premiumAmount: currentRequest.quotation.premiumAmount?.value,
      bondDuration: `${currentRequest.quotation.bondDuration.quantity} ${currentRequest.quotation.bondDuration.unit}`,
      pageName: "Bond request step " + processStep,
      siteSection: "Quote request",
      event: "Virtual page",
      processName: "Bond request",
      processDetail: formatMessage({
        id:
          "mcel-request-bond-type-" +
          currentRequest.quotation.bondType.toLowerCase(),
      }),
    });
  };

  const initDraft: BondDraft = {
    bondQuotationRequestId: currentRequest?.quotation.id ?? "",
    beneficiaryCompanyId: currentRequest?.beneficiary?.companyId ?? "",
    transaction: currentRequest?.transaction ?? {
      description: "",
      transactionDate: serializeDate(new Date())!,
      addressLine: "",
      postalCode: "",
      city: "",
      referenceNumber: "",
      additionalContractClause: "",
      transactionStartDate: "",
    },
  };
  const [draft, setDraft] = useState<BondDraft>(initDraft);

  const updateDraft = useCallback(
    (update: Partial<BondDraft>) => {
      setDraft((state) => ({
        ...state,
        ...update,
      }));
    },
    [setDraft]
  );

  const goToSubscription = useCallback(async () => {
    if (!currentRequest) {
      return;
    }

    // Let the BFF do the painful mapping
    const fullRequest = await fetchRequest(currentRequest.quotation.id);
    dispatch(setRequest(fullRequest));
    const nextRoute = fullRequest.beneficiary ? "/subscribe" : "/new-request";
    navigate(nextRoute);
  }, [currentRequest]);

  const effectiveSteps = editMode ? modificationSteps : creationSteps;

  if (!currentRequest) {
    return <Navigate to="/quotations" replace />;
  }

  return (
    <Stepper
      dataTestid="mcel-bond-request"
      steps={effectiveSteps}
      initialStep={0}
      sharedContext={{
        pushStepDataLayer,
        draft,
        updateDraft,
        request: currentRequest,
        goToSubscription,
      }}
    />
  );
};

export default BondRequest;
