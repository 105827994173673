import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@myeh/design-system";
import { Elements } from "@stripe/react-stripe-js";

import {
  Modal,
  IUserData,
  UserContext,
  UserType,
  isIE11,
} from "@gsp/gusto-front-common";

import GDPRCookieBanner from "./components/GDPRCookieBanner";
import Dashboard from "./components/Dashboard";

import stripePromise from "./utils/stripePromise";

import CookieContextProvider from "./components/context/CookieContextProvider";
import LanguageProvider from "./components/context/LanguageProvider";

import apiUrls, { baseApiUrl } from "./services/apiUrls";

import "../node_modules/@myeh/design-system/dist/style.css";
import "../node_modules/@myeh/design-system/dist/fonts.css";
import "../node_modules/@myeh/design-system/dist/icons/style.css";

import "./App.scss";
import AdobeAnalytics from "./components/AdobeAnalytics/AdobeAnalytics";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AdobeProvider from "./components/AdobeAnalytics/AdobeProvider";

export const connectionHandler = {
  setUserHasBeenDisconnected: (value: boolean) => {
    /*
      This function is defined to store its homonym from App Component,
      and it is used whenever a request returns a 401 HTTP code
    */
  },
};

export default function App() {
  const [userData, setUserData] = useState<IUserData>({
    authenticated: false,
    isIntermediary: true,
    familyName: "",
    givenName: "",
    id: "",
    login: "",
  });
  const [isReady, setReadiness] = useState<boolean>(false);
  const [userHasBeenDisconnected, setUserHasBeenDisconnected] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const rawResponse = await fetch(apiUrls.account(), {
        credentials: "include",
      });
      const response = await rawResponse.json();

      setUserData({
        authenticated: Boolean(response.name),
        userType:
          response.userType === "INTERMEDIARY"
            ? UserType.ITALIAN_INTERMEDIARY
            : UserType.MCEL_CLIENT,
        givenName: response.name ? (response.name.givenName as string) : "",
        familyName: response.name ? response.name.familyName : "",
        id: response.id as string,
        isIntermediary: false,
        login: response.login as string,
        phoneNumber: (response.phoneNumber as string) ?? "",
        parentCompanyId: (response.parentCompanyId as string) ?? "",
      });
      setReadiness(true);
    };

    const login = async () => {
      const rawResponse = await fetch(apiUrls.authenticate(), {
        credentials: "include",
      });
      const response = await rawResponse.text();
      if (response === "true") {
        fetchUserData();
      } else {
        setReadiness(true);
      }
    };

    login();
  }, [setUserData, setReadiness]);

  useEffect(() => {
    connectionHandler.setUserHasBeenDisconnected = setUserHasBeenDisconnected;
  }, [setUserHasBeenDisconnected]);

  return (
    <ThemeProvider>
      <CookieContextProvider>
        <LanguageProvider>
          <Elements stripe={stripePromise}>
            <Provider store={store}>
              <UserContext.Provider
                value={{ user: userData, setUser: setUserData }}>
                <AdobeProvider appName={"MCEL"}>
                  <BrowserRouter>
                    <AdobeAnalytics />
                    <div className={`App ${isIE11() ? "ie" : ""}`}>
                      <GDPRCookieBanner />
                      {isReady && <Dashboard />}
                      <Modal
                        isOpen={userHasBeenDisconnected}
                        titleKey="fetch-disconnected-modal-title"
                        textKey="fetch-disconnected-modal-text"
                        confirmLabelKey="fetch-disconnected-modal-confirm-label"
                        data-testid="disconnected-modal"
                        handleConfirmClick={() => {
                          window.location.replace(`${baseApiUrl}/idp-login`);
                        }}
                      />
                    </div>
                  </BrowserRouter>
                </AdobeProvider>
              </UserContext.Provider>
            </Provider>
          </Elements>
        </LanguageProvider>
      </CookieContextProvider>
    </ThemeProvider>
  );
}
