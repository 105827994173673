import React, { FC, ReactNode } from "react";

import { Wizard } from "@gsp/gusto-front-common";

const wizardSteps = [
  {
    key: "mcel-wizard-bond-quotation",
  },
  {
    key: "mcel-wizard-bond-request",
  },
  {
    key: "mcel-wizard-subscribe",
  },
];

interface IWizardWrapper {
  wizardStage: number;
  children: ReactNode;
}

const WizardWrapper: FC<IWizardWrapper> = ({ wizardStage, children }) => (
  <>
    <Wizard wizardStage={wizardStage} wizardSteps={wizardSteps} />
    {children}
  </>
);

export default WizardWrapper;
