import React, { useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col } from "react-bootstrap";
import { MdExpandMore } from "react-icons/md";
import { Notification, Popover } from "@myeh/design-system";

import { Banner } from "@gsp/gusto-front-common";

import IBond from "../../../../models/IBond";

import apiUrls from "../../../../services/apiUrls";
import { authenticatedDocumentFetch } from "../../../../services/authenticatedFetch";
import {
  fetchBondEbondList,
  fetchBondInvoicesList,
} from "../../../../services/bondList";
import { getLocalIdentifierFormat } from "../../../../services/localIdentifiers";
import { formatReferenceNumber } from "../../../../services/strings";

import CardGroup from "../../CardGroup";
import { ICardInformation } from "../../CardGroup/CardInformation";

import {
  formatBeneficiaryDataAddress,
  formatEUR,
  formatDate,
} from "../../../../utils/helpers";

import InvoicesModal from "./InvoicesModal";

import "./style.scss";
import IBondDocument from "../../../../models/IBondInvoice";

const { formatter } = getLocalIdentifierFormat("SIREN");

const Separator = () => <span> / </span>;

interface IBondCard {
  bond: IBond;
}

const BondCard = ({ bond }: IBondCard) => {
  const intl = useIntl();
  const [hidden, setHidden] = useState(true);

  const [ebondLoading, setEbondLoading] = useState(false);
  const [ebondError, setEbondError] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceError, setInvoiceError] = useState(false);
  const [documentsList, setDocumentsList] = useState<Array<IBondDocument>>();

  const loadInvoices = async () => {
    setInvoiceLoading(true);
    try {
      const invoices = await fetchBondInvoicesList(
        bond.bondNumber,
        bond.contractNumber
      );
      if (invoices.length === 0) {
        setInvoiceError(true);
      } else if (invoices.length === 1) {
        const [invoice] = invoices;
        const res = await authenticatedDocumentFetch(
          apiUrls.invoice(
            bond.bondNumber,
            bond.contractNumber,
            invoice.avalisDocumentId
          )
        );
        if (res.status >= 300) {
          setInvoiceError(true);
        } else {
          const blob = await res.blob();
          const file = window.URL.createObjectURL(blob);
          window.open(file, "_blank");
        }
      } else {
        setDocumentsList(invoices);
      }
    } catch (err) {
      setInvoiceError(true);
      console.error(err);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const loadEbonds = async () => {
    setEbondLoading(true);
    const ebonds = await fetchBondEbondList(
      bond.bondNumber,
      bond.contractNumber
    );
    if (ebonds.length === 0) {
      setEbondError(true);
      return;
    }
    const res = await authenticatedDocumentFetch(
      apiUrls.ebond(
        bond.bondNumber,
        bond.contractNumber,
        ebonds[0].avalisDocumentId
      )
    );

    if (res.status >= 300) {
      setInvoiceError(true);
    } else {
      const blob = await res.blob();
      const file = window.URL.createObjectURL(blob);
      window.open(file, "_blank");
    }
    setEbondLoading(false);
  };

  const handleCollapseButtonClick = useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);

  const beneficiaryInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-beneficiary-name",
      value: bond.beneficiaryData.companyName,
    },
    {
      labelMessageId: "mcel-request-details-beneficiary-id-type",
      value:
        bond.beneficiaryData.externalId &&
        formatter(bond.beneficiaryData.externalId),
    },
    {
      labelMessageId: "mcel-request-details-beneficiary-address",
      value: formatBeneficiaryDataAddress(bond.beneficiaryData),
    },
  ];

  const bondInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-bond-amount",
      value: formatEUR(bond.bondAmount.value),
    },
    {
      labelMessageId: "mcel-request-details-bond-start-date",
      value: formatDate(bond.startDate),
    },
    {
      labelMessageId: "mcel-request-details-bond-end-date",
      value: formatDate(bond.expiryDate?.toString()),
    },
  ];

  const paymentInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-price-payment-options",
      value: intl.formatMessage({
        id: "mcel-request-details-price-credit-card",
      }),
    },
    {
      labelMessageId: "mcel-request-details-price-amount",
      value: formatEUR(bond.bondAmount.value),
    },
  ];

  const contractInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-contract-reference",
      value: formatReferenceNumber(
        bond.projectData.referenceNumber,
        bond.projectData.additionalContractClause
      ),
    },
    {
      labelMessageId: "mcel-request-details-contract-start-date",
      value: formatDate(bond.projectData.date!.toString()),
    },
    {
      labelMessageId: "mcel-request-details-works-start-date",
      value: formatDate(bond.projectData.startDate?.toString()),
    },
    {
      labelMessageId: "mcel-request-details-works-end-date",
      value: formatDate(bond.projectData.endDate?.toString()),
    },
    {
      labelMessageId: "mcel-request-details-market-amount",
      value: formatEUR(bond.projectData.amount!.value),
    },
    {
      labelMessageId: "mcel-request-details-works-address",
      value: bond.projectData.tenderLocation,
    },
    {
      labelMessageId: "mcel-request-details-works-description",
      value: bond.projectData.description,
    },
  ];

  return (
    <div
      data-testid="mcel-request-card"
      className="request-card mcel-request-status-completed">
      <Row className="request-card-header">
        <Col>
          <Row>
            <Col xs={8}>
              <h3>
                {bond.beneficiaryData.companyName} - {bond.bondNumber}
              </h3>
            </Col>
            <Col>
              <button
                data-testid="mcel-request-card-collapse"
                className={`request-card__collapse text-blue ${
                  !hidden ? "request-card__collapse--rotate" : ""
                }`}
                onClick={handleCollapseButtonClick}
                aria-label="Bond details">
                <MdExpandMore size="2em" />
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <div>
                <FormattedMessage
                  id={`mcel-request-bond-type-${bond.bondType.toLowerCase()}`}
                />
              </div>
              <p>
                <FormattedMessage id="mcel-request-market" /> :{" "}
                <strong>{formatEUR(bond.projectData.amount!.value)}</strong>
                <Separator />
                <FormattedMessage id="mcel-request-bond" /> :{" "}
                <strong>{formatEUR(bond.bondAmount.value)}</strong>
              </p>
            </Col>
            <Col>
              <Row className="pt-2">
                <Col xs={6}>
                  <Popover
                    trigger={
                      bond.documents?.invoiceAvailable ? "manual" : "hover"
                    }
                    direction="top">
                    <Popover.Toggle>
                      <button
                        className={`button button-big button--red text-center h-100 ${
                          invoiceLoading || !bond.documents?.invoiceAvailable
                            ? "button--disabled"
                            : ""
                        }`}
                        onClick={loadInvoices}
                        disabled={
                          invoiceLoading || !bond.documents?.invoiceAvailable
                        }
                        aria-label="Download invoice">
                        <FormattedMessage id="invoice-download" />
                      </button>
                    </Popover.Toggle>
                    <Popover.Content css={{}}>
                      <FormattedMessage id="invoice-unavailable" />
                    </Popover.Content>
                  </Popover>
                </Col>
                <Col>
                  <Popover
                    trigger={
                      bond.documents?.ebondAvailable ? "manual" : "hover"
                    }
                    direction="top">
                    <Popover.Toggle>
                      <button
                        className={`button button-big button--red text-center ml-auto h-100 ${
                          ebondLoading || !bond.documents?.ebondAvailable
                            ? "button--disabled"
                            : ""
                        }`}
                        onClick={loadEbonds}
                        disabled={
                          ebondLoading || !bond.documents?.ebondAvailable
                        }
                        aria-label="Download ebond">
                        <FormattedMessage id="mcel-bond-list-download-ebond-button" />
                      </button>
                    </Popover.Toggle>
                    <Popover.Content css={{}}>
                      <FormattedMessage id="ebond-unavailable" />
                    </Popover.Content>
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {!bond.documents?.ebondAvailable ? (
        <div className="request-card-issue-banner">
          <Notification context="warning">
            <FormattedMessage id="mcel-issue-ebond-not-generated" />
          </Notification>
        </div>
      ) : null}
      {ebondError && (
        <Row>
          <Banner
            bannerType="error"
            bannerTitleKey="mcel-bond-list-download-ebond-error"
          />
        </Row>
      )}
      {invoiceError && (
        <Row>
          <Banner
            bannerType="error"
            bannerTitleKey="mcel-bond-list-download-invoice-error"
          />
        </Row>
      )}
      {bond.documents?.invoiceAvailable && (
        <InvoicesModal
          onModalClose={() => setDocumentsList(undefined)}
          invoices={documentsList}
          bond={bond}
        />
      )}
      <div
        data-testid="mcel-request-card-details"
        className={`request-card__details ${
          hidden ? "request-card__details--hidden" : ""
        }`}>
        <Row>
          <Col lg={6}>
            <CardGroup
              headingMessageId="mcel-request-details-beneficiary-title"
              fields={beneficiaryInfo}
            />
            <CardGroup
              headingMessageId="mcel-request-details-bond-title"
              fields={bondInfo}
            />
            <CardGroup
              headingMessageId="mcel-bond-details-price-title"
              fields={paymentInfo}
            />
          </Col>
          <Col lg={6}>
            <CardGroup
              headingMessageId="mcel-request-details-contract-title"
              fields={contractInfo}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BondCard;
