import React, { useState, useCallback, ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "date-fns";
import { MdExpandMore } from "react-icons/md";
import { Row, Col } from "react-bootstrap";

import IRequest from "../../../../models/IRequest";
import {
  formatReferenceNumber,
  getCompanyAddress,
} from "../../../../services/strings";
import { getLocalIdentifierFormat } from "../../../../services/localIdentifiers";
import CardGroup from "../../CardGroup";
import { ICardInformation } from "../../CardGroup/CardInformation";
import { formatEUR, formatDate } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setRequest } from "../../RequestSlice/RequestSlice";

import "./style.scss";
import { useNavigate } from "react-router-dom";

const Separator = () => <span> / </span>;

const formatCardHeaderTitle = (
  companyName: string,
  referenceNumber?: string
) => {
  return referenceNumber ? companyName + " - " + referenceNumber : companyName;
};

const { formatter } = getLocalIdentifierFormat("SIREN");

const selectRequestMessageIds: { [key: string]: string } = {
  ACCEPTED: "mcel-request-subscribe-button",
  FILLED: "mcel-request-payment-button",
  PREPAID: "mcel-request-signature-button",
  PENDING_SIGNATURE: "mcel-request-signature-button",
};

interface IRequestCard {
  request: IRequest;
}

const RequestCard = ({ request }: IRequestCard) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [hidden, setHidden] = useState(true);
  const quotation = request.quotation;
  const { bondType, bondAmount, startDate, principalDebtAmount, bondEndDate } =
    quotation;

  const expireDate = quotation.expirationTimestamp
    ? new Date(quotation.expirationTimestamp)
    : null;

  const expireDateFormated = expireDate
    ? format(expireDate, "dd/MM/yyyy")
    : null;

  const handleCollapseButtonClick = useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);

  const beneficiaryInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-beneficiary-name",
      value: request.beneficiary?.companyName,
    },
    {
      labelMessageId: "mcel-request-details-beneficiary-id-type",
      value: request.beneficiary && formatter(request.beneficiary.sirenNumber),
    },
    {
      labelMessageId: "mcel-request-details-beneficiary-address",
      value: getCompanyAddress(request.beneficiary?.address),
    },
  ];

  const bondInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-bond-amount",
      value: formatEUR(bondAmount.value),
    },
    {
      labelMessageId: "mcel-request-details-bond-start-date",
      value: startDate ? formatDate(startDate) : null,
    },
    {
      labelMessageId: "mcel-request-details-bond-end-date",
      value: bondEndDate ? formatDate(bondEndDate) : null,
      infoText: "mcel-bond-subscribe-bond-end-date-info",
    },
  ];

  const paymentInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-bond-payment-type",
      value: intl.formatMessage({
        id: "mcel-bond-payment-type-card",
      }),
    },
    {
      labelMessageId: "mcel-bond-payment-amount",
      value:
        quotation.premiumAmount && formatEUR(quotation.premiumAmount.value),
      infoText: "mcel-bond-payment-amount-info",
    },
    {
      labelMessageId: "mcel-bond-payment-expire",
      value: formatDate(quotation.expirationTimestamp),
      infoText: "mcel-bond-payment-expire-info",
    },
  ];

  const contractInfo: ICardInformation[] = [
    {
      labelMessageId: "mcel-request-details-contract-reference",
      value: request.transaction
        ? formatReferenceNumber(
            request.transaction.referenceNumber,
            request.transaction.additionalContractClause
          )
        : undefined,
    },
    {
      labelMessageId: "mcel-request-details-contract-start-date",
      value: formatDate(request.transaction?.transactionDate),
    },
    {
      labelMessageId: "mcel-request-details-works-start-date",
      value: formatDate(request.quotation.startDate),
    },
    {
      labelMessageId: "mcel-request-details-works-end-date",
      value: formatDate(request.quotation.endDate),
    },
    {
      labelMessageId: "mcel-request-details-market-amount",
      value: principalDebtAmount ? formatEUR(principalDebtAmount.value) : null,
    },
    {
      labelMessageId: "mcel-request-details-works-address",
      value: [
        request.transaction?.addressLine,
        request.transaction?.postalCode,
        request.transaction?.city,
      ]
        .filter(Boolean)
        .join(" "),
    },
    {
      labelMessageId: "mcel-request-details-works-description",
      value: request.transaction?.description,
    },
  ];

  const statusClass = `mcel-request-status-${request.status.toLowerCase()}`;
  const selectActionId = selectRequestMessageIds[request.status];

  const selectRequest = useCallback(() => {
    dispatch(setRequest(request));
    const nextRoute = request.beneficiary ? "/subscribe" : "/new-request";
    navigate(nextRoute);
  }, []);

  const em = (...chunks: Array<ReactNode>) => <em>{chunks}</em>;

  return (
    <div
      data-testid="mcel-request-card"
      className={`request-card ${statusClass}`}>
      <Row className="request-card-header">
        <Col xs={10} sm={8}>
          <h3>
            {request.beneficiary ? (
              formatCardHeaderTitle(
                request.beneficiary.companyName,
                request.transaction?.referenceNumber
              )
            ) : (
              <FormattedMessage
                id={
                  request.status === "EXPIRED"
                    ? "mcel-request-expired"
                    : request.status === "REJECTED"
                    ? "mcel-request-rejected"
                    : "mcel-request-empty"
                }
              />
            )}
          </h3>
          <div>
            <FormattedMessage
              id={`mcel-request-bond-type-${bondType.toLowerCase()}`}
            />
            <Separator />
            <span className="mcel-request-status-label">
              <FormattedMessage
                id={`mcel-request-status-${request.status.toLowerCase()}`}
              />
            </span>
          </div>
          <p className="mcel-request-amounts">
            <FormattedMessage id="mcel-request-market" /> :{" "}
            {principalDebtAmount ? (
              <strong>{formatEUR(principalDebtAmount.value)}</strong>
            ) : (
              <FormattedMessage
                id="mcel-request-data-not-available"
                values={{
                  em,
                }}
              />
            )}
            <Separator />
            <FormattedMessage id="mcel-request-bond" /> :{" "}
            <strong>{formatEUR(bondAmount.value)}</strong>
            {quotation.premiumAmount && (
              <>
                <Separator />
                <FormattedMessage id="mcel-request-premium" /> :{" "}
                <strong>{formatEUR(quotation.premiumAmount.value)}</strong>
              </>
            )}
          </p>
        </Col>
        <Col>
          <button
            data-testid="mcel-request-card-collapse"
            className={`request-card__collapse text-blue ${
              !hidden ? "request-card__collapse--rotate" : ""
            }`}
            onClick={handleCollapseButtonClick}>
            <MdExpandMore size="2em" />
          </button>
          {selectActionId && (
            <div className="pt-2">
              <button
                className="button button-big button--red text-center"
                data-testid="mcel-request-card-select-request-button"
                onClick={selectRequest}>
                <FormattedMessage
                  id={selectActionId}
                  values={{
                    expireDate: expireDateFormated,
                  }}
                />
              </button>
            </div>
          )}
        </Col>
      </Row>
      <div
        data-testid="mcel-request-card-details"
        className={`request-card__details ${
          hidden ? "request-card__details--hidden" : ""
        }`}>
        <Row>
          <Col lg={6}>
            <CardGroup
              fields={beneficiaryInfo}
              headingMessageId="mcel-request-details-beneficiary-title"
            />
            <CardGroup
              fields={bondInfo}
              headingMessageId="mcel-request-details-bond-title"
            />
            {quotation.premiumAmount && (
              <CardGroup
                fields={paymentInfo}
                headingMessageId="mcel-request-details-payment-title"
              />
            )}
          </Col>
          <Col lg={6}>
            <CardGroup
              fields={contractInfo}
              headingMessageId="mcel-request-details-contract-title"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RequestCard;
