import React, { useState, useEffect, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Loader, StepComponent } from "@gsp/gusto-front-common";

import BondRequestContext from "../../../../models/BondRequestContext";
import { bondDraftRequest } from "../../../../services/bonds";

const BondModifiedConfirmation = ({
  context,
}: StepComponent<BondRequestContext>) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<any>();
  const { draft, request, goToSubscription } = context;
  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer(4, "Confirmation de la modification");
  }, []);

  useEffect(() => {
    let didCancel = false;
    let timer: NodeJS.Timeout;
    bondDraftRequest(draft, "")
      .then((resp) => {
        if (!didCancel) {
          setLoading(false);
          setResponse(resp);
          timer = setTimeout(goToSubscription, 1000);
        }
      })
      .catch((error) => {
        if (!didCancel) {
          setLoading(false);
          console.error(error);
        }
      });
    return () => {
      didCancel = true;
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const messageKey = response
    ? "mcel-request-modify-success"
    : "mcel-request-modify-error";

  const messageClasses = response ? "text-blue mb-3" : "text--error mb-3";

  const b = (...chunks: ReactNode[]) => <b>{chunks}</b>;

  return (
    <div
      data-testid="mcel-modified-confirmation"
      className="mcel-bond-request compact">
      {loading ? (
        <Loader isVisible />
      ) : (
        <div
          data-testid="mcel-modified-confirmation-response"
          className={messageClasses}>
          <div className="mb-3">
            <FormattedMessage
              id={messageKey}
              values={{
                bondRequestId: request.quotation.bondRequestId,
                b,
              }}
            />
          </div>
          {response && (
            <button
              className="button button-big modal__button"
              onClick={goToSubscription}
              data-testid="mcel-bond-modified-next-step">
              <FormattedMessage id="next-step" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BondModifiedConfirmation;
