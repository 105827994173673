import apiUrls from "./apiUrls";
import { authenticatedGet } from "./authenticatedFetch";

interface PostalCodeInfoResponseItem {
  placeName: string;
  postalCode: string;
}

export const getPostalCodeInfo = (
  postalCode: string,
  countryCode: string
): Promise<PostalCodeInfoResponseItem[]> => {
  const url = apiUrls.postalCodeLookUp(postalCode, countryCode);
  return authenticatedGet(url) as Promise<PostalCodeInfoResponseItem[]>;
};
