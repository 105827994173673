export const baseApiUrl = process.env.REACT_APP_API_URL || "/api";
console.log("baseApiUrl", baseApiUrl, process.env.REACT_APP_DOCUMENTS_API_URL);

const apiUrls = {
  account: () => `${baseApiUrl}/account`,
  attachUserAffiliation: (affiliationLink: string) =>
    `${baseApiUrl}/mcel/affiliation/${affiliationLink}`,
  authenticate: () => `${baseApiUrl}/authenticate`,
  companySearch: (countryCode: string, idTypeCode: string, idValue: string) =>
    `${baseApiUrl}/company/search?countryCode=${countryCode}&idTypeCode=${idTypeCode}&idValue=${idValue}`,
  postalCodeLookUp: (postalCode: string, countryCode: string) =>
    `${baseApiUrl}/referential/postal-code-lookup?postalCode=${postalCode}&countryCode=${countryCode}`,
  phoneRegister: () => `${baseApiUrl}/phone-register`,
  bondDraftRequest: () => `${baseApiUrl}/mcel/draft`,
  mcelRequestList: (page: number, pageSize: number) =>
    `${baseApiUrl}/mcel/requests?page=${page}&pageSize=${pageSize}`,
  newAccountSearchCompany: (
    countryCode: string,
    idTypeCode: string,
    idValue: string
  ) =>
    `${baseApiUrl}/new-account/search-company?countryCode=${countryCode}&idTypeCode=${idTypeCode}&idValue=${idValue}`,
  newAccountVerify: () => `${baseApiUrl}/new-account/verify`,
  newAccountFileUpload: () => `${baseApiUrl}/new-account/verify-identity`,
  quotationRequest: () => `${baseApiUrl}/bond/mcel/quotation`,
  getQuotationRequest: (bondQuotationRequestId: string) =>
    `${baseApiUrl}/mcel/quotation/${bondQuotationRequestId}`,
  getRequest: (bondQuotationRequestId: string) =>
    `${baseApiUrl}/mcel/request/${bondQuotationRequestId}`,
  bondInitPaymentIntent: () => `${baseApiUrl}/mcel/init-payment-intent`,
  bondValidatePaymentIntent: () => `${baseApiUrl}/mcel/validate-payment-intent`,
  initSignature: () => `${baseApiUrl}/mcel/signature/init`,
  getSignature: (bondQuotationRequestId: string) =>
    `${baseApiUrl}/mcel/signature/${bondQuotationRequestId}`,
  signatureListen: (bondQuotationRequestId: string) =>
    `${baseApiUrl}/mcel/signature/listen/${bondQuotationRequestId}`,
  bondList: (page: number, pageSize: number) =>
    `${baseApiUrl}/mcel/bonds?page=${page}&pageSize=${pageSize}`,
  bondInvoices: (bondNumber: string, contractNumber: string) =>
    `${baseApiUrl}/mcel/documents/invoices/?bondNumber=${bondNumber}&contractNumber=${contractNumber}`,
  bondEBonds: (bondNumber: string, contractNumber: string) =>
    `${baseApiUrl}/mcel/documents/ebond?bondNumber=${bondNumber}&contractNumber=${contractNumber}`,
  invoice: (
    bondNumber: string,
    contractNumber: string,
    avalisDocumentId: string
  ) =>
    `${baseApiUrl}/mcel/documents/download/${avalisDocumentId}?bondNumber=${bondNumber}&contractNumber=${contractNumber}&attachmentTypeCode=INVOICE`,
  ebond: (
    bondNumber: string,
    contractNumber: string,
    avalisDocumentId: string
  ) =>
    `${baseApiUrl}/mcel/documents/download/${avalisDocumentId}?bondNumber=${bondNumber}&contractNumber=${contractNumber}&attachmentTypeCode=EBOND`,
  generateDraft: (templateCode: string) =>
    `${baseApiUrl}/mcel/draft/${templateCode}/generate`,
  retrieveDraft: (businessObjectId: string) =>
    `${baseApiUrl}/mcel/document/${businessObjectId}/draft`,
};

export default apiUrls;
