import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Banner, UserContext } from "@gsp/gusto-front-common";

import IRequest from "../../../models/IRequest";

import apiUrls from "../../../services/apiUrls";
import { authenticatedGet } from "../../../services/authenticatedFetch";

import RequestCard from "./RequestCard/RequestCard";
import SkeletonCard from "../SkeletonCard";
import {
  selectCurrentPage,
  selectRequestList,
  selectTotalPage,
  setCurrentPage,
  setRequestList,
  setTotalPage,
} from "./RequestListSlice/RequestListSlice";

import "./style.scss";
import { attachAffiliationToUser } from "../../../services/affiliation";

interface IFetchResponse {
  requests: IRequest[];
  totalPages: number;
  totalItems: number;
}

const RequestList = () => {
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const requestList = useSelector(selectRequestList);
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPage);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchRequestList: (
    page: number,
    pageSize: number
  ) => Promise<IFetchResponse> = (page, pageSize) => {
    return authenticatedGet(
      apiUrls.mcelRequestList(page, pageSize)
    ) as Promise<IFetchResponse>;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchRequestList(currentPage, 10)
      .then((list) => {
        setIsLoading(false);
        dispatch(setRequestList([...list.requests]));
        if (user.parentCompanyId && user.id && list.requests?.length === 0) {
          attachAffiliationToUser({
            companyId: user.parentCompanyId,
            contactId: user.id,
          });
        }
        dispatch(setTotalPage(list.totalPages));
        setError(undefined);
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.defaultMessageKey as string);
      });
  }, [currentPage]);

  return (
    <Container>
      <div className="request-list__header">
        <h3>
          <FormattedMessage id="mcel-request-list-title" />
        </h3>
        <Link data-testid="new-quotation-button" to="/new-quotation">
          <button className="button-secondary">
            <FormattedMessage id="mcel-request-list-new-quotation-btn" />
          </button>
        </Link>
      </div>
      {requestList &&
        requestList.map((request) => {
          return <RequestCard key={request.quotation.id} request={request} />;
        })}
      {!isLoading && currentPage < totalPages && (
        <button
          className="button-secondary button--center mb-3"
          onClick={() => dispatch(setCurrentPage(currentPage + 1))}>
          <FormattedMessage id="mcel-request-list-load-more" />
        </button>
      )}
      {error && <Banner bannerTitleKey="technical-error" bannerType="error" />}
      {isLoading &&
        new Array(10).fill(0).map((_, idx) => <SkeletonCard key={idx} />)}
    </Container>
  );
};

export default RequestList;
