import { addSpacesToString } from "./strings";

type IdTypeCode = "SIREN";

export interface LocalIdentifierFormat {
  parser: (formatted: string) => string;
  formatter: (value: string) => string;
}

const formats: {
  [key: string]: LocalIdentifierFormat;
} = {
  SIREN: {
    parser: (formatted: string) => {
      return formatted.replace(/\s/g, "");
    },
    formatter: (value: string) => {
      const numbers = value.replace(/\D/g, "").substring(0, 9);
      return addSpacesToString(numbers, [3, 6]);
    },
  },
};

export const getLocalIdentifierFormat = (idTypeCode: IdTypeCode) => {
  return formats[idTypeCode];
};
