import { useState } from "react";
import { ErrorDescriptor, TECHNICAL_ERROR } from "@gsp/gusto-front-common";

import { authenticatedDocumentFetch } from "../services/authenticatedFetch";

export const FILE_NOT_FOUND: ErrorDescriptor = {
  defaultMessageKey: "file-not-found",
};

const useGetFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorDescriptor>();

  const getFile = async (url: string) => {
    setLoading(true);
    setError(undefined);

    try {
      const res = await authenticatedDocumentFetch(url);
      if (res.status === 404) {
        setError(FILE_NOT_FOUND);
      } else if (res.status >= 300) {
        setError(TECHNICAL_ERROR);
      } else {
        const blob = await res.blob();
        const file = window.URL.createObjectURL(blob);
        window.open(file, "_blank");
      }
    } catch (e) {
      console.error(`Error fetching FILE: ${url}`, e);
      setError(TECHNICAL_ERROR);
    }
    setLoading(false);
  };

  return [{ loading, error }, getFile] as const;
};

export default useGetFile;
