import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  isBefore,
  isAfter,
  differenceInMonths,
  differenceInDays,
  startOfDay,
} from "date-fns";

import {
  BondType,
  Banner,
  DateInput,
  StepComponent,
} from "@gsp/gusto-front-common";

import QuotationContext from "../../../../models/QuotationContext";

import "./style.scss";

const getDuration = (init: Date, final: Date) => {
  const startDate = startOfDay(init);
  const endDate = startOfDay(final);
  return differenceInDays(endDate, startDate);
};

const validate = (bondType: BondType, startDate: Date, endDate: Date) => {
  const today = startOfDay(new Date());
  switch (bondType) {
    case "SUBCONTRACTOR_PAYMENT_GUARANTEE":
      return differenceInMonths(endDate, startDate) < 1
        ? { key: "mcel-bond-duration-error-spg-duration-min" }
        : differenceInMonths(endDate, startDate) >= 24
        ? { key: "mcel-bond-duration-error-duration-max-24" }
        : isBefore(startDate, today)
        ? { key: "mcel-bond-duration-error-spg-start-date-min" }
        : differenceInMonths(startDate, today) > 0
        ? { key: "mcel-bond-duration-error-spg-start-date-max" }
        : isBefore(endDate, startDate)
        ? { key: "mcel-bond-duration-error-negative-duration" }
        : undefined;
    case "JOINT_SURETY":
      return isAfter(startDate, today)
        ? { key: "mcel-bond-duration-error-default-start-date-max" }
        : differenceInMonths(endDate, startDate) >= 24
        ? { key: "mcel-bond-duration-error-duration-max-24" }
        : differenceInMonths(today, startDate) > 0
        ? { key: "mcel-bond-duration-error-js-start-date-min" }
        : isBefore(endDate, startDate)
        ? { key: "mcel-bond-duration-error-negative-duration" }
        : undefined;
    default:
      return isBefore(endDate, startDate)
        ? { key: "mcel-bond-duration-error-negative-duration" }
        : !isAfter(endDate, today)
        ? { key: "mcel-bond-duration-error-default-end-date-min" }
        : isAfter(startDate, today)
        ? { key: "mcel-bond-duration-error-default-start-date-max" }
        : differenceInMonths(endDate, startDate) >= 24
        ? { key: "mcel-bond-duration-error-duration-max-24" }
        : undefined;
  }
};

const MCELBondDuration = ({
  context,
  setStepCompleted,
}: StepComponent<QuotationContext>) => {
  const { quotationRequest, updateQuotationRequest, pushStepDataLayer } =
    context;

  useEffect(() => {
    pushStepDataLayer(4, "Durée");
  }, []);

  const [startDate, setStartDate] = useState(quotationRequest.startDate);
  const [endDate, setEndDate] = useState(quotationRequest.endDate);

  const [error, setError] = useState<{ key: string }>();

  useEffect(() => {
    updateQuotationRequest({
      startDate,
    });
  }, [startDate, updateQuotationRequest]);

  useEffect(() => {
    updateQuotationRequest({
      endDate,
    });
  }, [endDate, updateQuotationRequest]);

  useEffect(() => {
    const duration = getDuration(startDate, endDate);
    updateQuotationRequest({
      bondDuration: {
        quantity: duration,
        unit: "DD",
      },
    });
    const err = validate(quotationRequest.bondType, startDate, endDate);
    setError(err);

    setStepCompleted(err === undefined);
  }, [
    startDate,
    endDate,
    updateQuotationRequest,
    setStepCompleted,
    quotationRequest.bondType,
  ]);

  const changeStartDate = useCallback(
    (value: Date | null) => value && setStartDate(value),
    []
  );
  const changeEndDate = useCallback(
    (value: Date | null) => value && setEndDate(value),
    []
  );

  const subtitleKey = useMemo(() => {
    return quotationRequest.bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE"
      ? "mcel-bond-duration-subtitle-subcontractor-payment-guarantee"
      : quotationRequest.bondType === "JOINT_SURETY"
      ? "mcel-bond-duration-subtitle-joint-surety"
      : "mcel-bond-duration-subtitle-default";
  }, [quotationRequest.bondType]);

  const br = () => <br />;

  return (
    <div data-testid="mcel-bond-duration">
      <div className="title text-blue mb-4">
        <FormattedMessage id="mcel-bond-duration-title" />
      </div>
      <div className="body1-light text-blue mb-4">
        <FormattedMessage
          id={subtitleKey}
          values={{
            br,
          }}
        />
      </div>
      <div className="duration-group">
        <div className="duration-group__item">
          <DateInput
            labelKey="mcel-bond-duration-init"
            initialValue={startDate}
            onChange={changeStartDate}
            locale="fr"
            id="mcel-bond-duration-init"
            dataTestid="mcel-bond-duration-init"
          />
        </div>
        <div className="duration-group__item">
          <DateInput
            labelKey="mcel-bond-duration-end"
            initialValue={endDate}
            onChange={changeEndDate}
            locale="fr"
            id="mcel-bond-duration-end"
            dataTestid="mcel-bond-duration-end"
          />
        </div>
      </div>
      {error && (
        <div data-testid="mcel-market-value-error">
          <Banner bannerType="error" bannerTitleKey={error.key} />
        </div>
      )}
    </div>
  );
};

export default MCELBondDuration;
