import apiUrls from "./apiUrls";
import { authenticatedGet, authenticatedPost } from "./authenticatedFetch";

interface InitSignaturePayload {
  bondQuotationRequestId: string;
}

interface InitSignatureResponse {
  url: string;
}

export const initSignatureRequest = (
  payload: InitSignaturePayload
): Promise<InitSignatureResponse> => {
  return authenticatedPost(apiUrls.initSignature(), {
    payload,
  }) as Promise<InitSignatureResponse>;
};

export const getSignatureRequest = (
  payload: InitSignaturePayload
): Promise<InitSignatureResponse> => {
  return authenticatedGet(
    apiUrls.getSignature(payload.bondQuotationRequestId)
  ) as Promise<InitSignatureResponse>;
};
