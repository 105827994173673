import React, { useEffect, useState, useContext, useCallback } from "react";

import { CookieContext, ICookiePreferences } from "@gsp/gusto-front-common";
import GDPRCookieModal from "../GDPRCookieModal";

import "./style.scss";

const GDPRCookieBanner = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showGDPRCookieBanner, setShowGDPRCookieBanner] = useState(false);
  const { preferences, registered, updatePreferences } =
    useContext(CookieContext);
  const [selection, setSelection] = useState<ICookiePreferences>(preferences);

  const onAcceptCookies = useCallback(
    (preferences?: ICookiePreferences) => {
      updatePreferences(preferences || selection);
    },
    [updatePreferences, selection]
  );

  useEffect(() => {
    if (!registered) {
      setTimeout(() => {
        setShowGDPRCookieBanner(true);
      }, 500);
    }
  }, [registered]);

  if (registered || !showGDPRCookieBanner) {
    return null;
  }

  return (
    <GDPRCookieModal
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      onAcceptCookies={onAcceptCookies}
      setShowGDPRCookieBanner={setShowGDPRCookieBanner}
      selection={selection}
      setSelection={setSelection}
    />
  );
};

export default GDPRCookieBanner;
