import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { LanguageContext, UserContext } from "@gsp/gusto-front-common";

interface PageLoadDataLayer {
  [key: string]: string | boolean | number | undefined;

  pageName?: string;
  siteSection: string;
  event?: string;
  processName?: string;
  processStep?: string;
  processStepName?: string;
  processDetail?: string;
  bondDuration?: string;
}

interface EventDataLayer {
  [key: string]: string | boolean | number | undefined;

  eventType: string;
  eventName: string;
  eventDetail?: string;
}

interface AdobeContextType {
  setPreviousPathName: (previousPathName: string) => void;
  pushPageLoadDataLayer: (pageLoadDataLayer: PageLoadDataLayer) => void;
  pushEventDataLayer: (eventDataLayer: EventDataLayer) => void;
  pushCtaDataLayer: (ctaName: string) => void;
  pushErrorDataLayer: (
    errorType: string,
    errorCode: string,
    errorMessage: string
  ) => void;
}

const AdobeContext = createContext<AdobeContextType>({
  setPreviousPathName: (previousPathName: string) => null,
  pushPageLoadDataLayer: (pageLoadDataLayer: PageLoadDataLayer) => null,
  pushEventDataLayer: (eventDataLayer: EventDataLayer) => null,
  pushCtaDataLayer: (ctaName: string) => null,
  pushErrorDataLayer: (
    errorType: string,
    errorCode: string,
    errorMessage: string
  ) => null,
});

const useAdobeContext = () => {
  const context = useContext(AdobeContext);
  if (!context) {
    throw new Error("No AdobeContext provided.");
  }
  return context;
};

interface AdobeProviderProps extends PropsWithChildren {
  appName: string;
}

declare global {
  interface Window {
    adobeDataLayer: any[] | undefined;
  }
}

const AdobeProvider: FC<AdobeProviderProps> = ({ children, appName }) => {
  const { localeCode } = useContext(LanguageContext);
  const { user } = useContext(UserContext);

  const [previousPathName, setPreviousPathName] = useState("");

  const context = {
    setPreviousPathName,
    pushPageLoadDataLayer: (pageLoadDataLayer: PageLoadDataLayer) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      window.adobeDataLayer?.push({
        event: "Virtual page",
        appName,
        pageName: pageLoadDataLayer.pageName || document.title,
        pageLanguage: localeCode,
        previousPageName: previousPathName,
        previousPageUrl:
          previousPathName === ""
            ? ""
            : window.location.host + previousPathName,
        loginStatus: Boolean(user?.authenticated),
        lastLoginDate: "",
        userID: user?.login,
        ...pageLoadDataLayer,
      });
    },
    pushEventDataLayer: (eventDataLayer: EventDataLayer) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      window.adobeDataLayer?.push({
        ...eventDataLayer,
        appName,
      });
    },
    pushCtaDataLayer: (ctaName: string) => {
      window.adobeDataLayer = window.adobeDataLayer || [];

      window.adobeDataLayer?.push({
        appName,
        event: "click",
        eventType: "click CTA",
        eventName: ctaName,
      });
    },
    pushErrorDataLayer: (
      errorType: string,
      errorCode: string,
      errorMessage: string
    ) => {
      window.adobeDataLayer?.push({
        event: "error",
        errorType,
        errorCode,
        errorMessage,
      });
    },
  };

  return (
    <AdobeContext.Provider value={context}>{children}</AdobeContext.Provider>
  );
};

export default AdobeProvider;
export { AdobeContext, useAdobeContext };
export type { PageLoadDataLayer, AdobeProviderProps };
