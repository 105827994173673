import React, { ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Icon, Switcher } from "@myeh/design-system";

import {
  CookieCategories,
  ICookiePreferences,
  defaultPreferences,
  Button,
} from "@gsp/gusto-front-common";

import "./style.scss";

interface GDPRCookieModalProps {
  showDetails: boolean;
  setShowDetails: (showDetails: boolean) => void;
  setShowGDPRCookieBanner: (showGDPRCookieBanner: boolean) => void;
  onAcceptCookies: (preferences?: ICookiePreferences) => void;
  selection: ICookiePreferences;
  setSelection: (selection: ICookiePreferences) => void;
}

interface GDPRCookieCategoryProps {
  alwaysActive?: boolean;
  active?: boolean;
  category: string;
  onChange: () => void;
}

const GDPRCookieCategory = ({
  alwaysActive,
  active,
  category,
  onChange,
}: GDPRCookieCategoryProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    console.info("test");
    setOpen(!open);
  };

  return (
    <div className="MCELCookieBanner__settings_preferences_accordion">
      <button
        className="MCELCookieBanner__settings_preferences_accordion_button"
        onClick={handleClick}>
        <span>
          <Icon name={open ? "minus" : "plus"} />
          <FormattedMessage id={`cookies-category-title-${category}`} />
        </span>
        <span className="MCELCookieBanner__settings_preferences_accordion_checkbox">
          {alwaysActive ? (
            <FormattedMessage id="cookies-privacy-preferences-always-enabled" />
          ) : (
            <span onClick={(event) => event.stopPropagation()}>
              <Switcher
                id={category}
                checked={active}
                checkedChange={onChange}
                className="MCELCookieBanner__settings_preferences_list_item_checkbox_component"
              />
            </span>
          )}
        </span>
      </button>
      {open && (
        <div className="MCELCookieBanner__settings_preferences_accordion_panel">
          <p>
            <FormattedMessage id={`cookies-category-description-${category}`} />
          </p>
        </div>
      )}
    </div>
  );
};

const GDPRCookieModal = ({
  showDetails,
  setShowDetails,
  setShowGDPRCookieBanner,
  onAcceptCookies,
  selection,
  setSelection,
}: GDPRCookieModalProps) => {
  const intl = useIntl();

  const privacyNoticeLink = (...chunks: Array<ReactNode>) => (
    <a
      href={intl.formatMessage({
        id: "cookies-privacy-notice-link",
      })}>
      {chunks}
    </a>
  );

  const setAllCookies = (enabled: boolean) => {
    const result = { ...defaultPreferences };
    for (const cookieCategory of CookieCategories) {
      result[cookieCategory] = cookieCategory === "session" || enabled;
    }
    setSelection(result);
    return result;
  };

  return (
    <>
      <div className="MCELCookieBanner__backdrop">
        <div
          className={`MCELCookieBanner__container ${
            showDetails ? "MCELCookieBanner__container_details" : ""
          }`}>
          {showDetails ? (
            <>
              <div className="MCELCookieBanner__settings_container">
                <div className="MCELCookieBanner__header">
                  <FormattedMessage id="cookies-privacy-preferences-title" />
                </div>
                <div className="MCELCookieBanner__body">
                  <div className="MCELCookieBanner__details">
                    <FormattedMessage id="cookies-privacy-preferences-explanation" />
                    <br />
                    <a
                      href={intl.formatMessage({
                        id: "cookies-privacy-preferences-link",
                      })}>
                      <FormattedMessage id="cookies-privacy-preferences-link-text" />
                    </a>
                  </div>
                </div>
                <div className="MCELCookieBanner__settings_accept_all">
                  <Button
                    onClick={() => {
                      setShowGDPRCookieBanner(false);
                      onAcceptCookies(setAllCookies(true));
                    }}
                    type="big"
                    i18nKey="cookies-privacy-preferences-accept-all"
                  />
                </div>
                <div className="MCELCookieBanner__settings_preferences_title">
                  <FormattedMessage id="cookies-privacy-preferences-subtitle" />
                </div>
                <div className="MCELCookieBanner__settings_preferences_list">
                  {CookieCategories.map((cookieCategory) => {
                    const checked = selection[cookieCategory];
                    const onChange = () =>
                      setSelection({
                        ...selection,
                        [cookieCategory]: !checked,
                      });
                    return (
                      <GDPRCookieCategory
                        key={cookieCategory}
                        category={cookieCategory}
                        onChange={onChange}
                        active={checked}
                        alwaysActive={cookieCategory === "session"}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="MCELCookieBanner__actions">
                <div className="MCELCookieBanner__continue">
                  <Button
                    onClick={() => {
                      setShowGDPRCookieBanner(false);
                      onAcceptCookies();
                    }}
                    type="big"
                    i18nKey="cookies-cta-continue"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="MCELCookieBanner__header">
                <FormattedMessage id="cookies-title" />
              </div>
              <div className="MCELCookieBanner__body">
                <div className="MCELCookieBanner__explanations">
                  <FormattedMessage
                    id="cookies-description"
                    values={{
                      privacyNoticeLink,
                    }}
                  />
                </div>
              </div>
              <div className="MCELCookieBanner__actions">
                <div className="MCELCookieBanner__cta">
                  <Button
                    onClick={() => {
                      setShowGDPRCookieBanner(false);
                      onAcceptCookies(setAllCookies(true));
                    }}
                    type="big"
                    i18nKey="cookies-cta"
                    dataTestid="accept-cookies-button"
                  />
                </div>
                <div className="MCELCookieBanner__secondary">
                  <Button
                    onClick={() => {
                      setAllCookies(false);
                      setShowDetails(true);
                    }}
                    type="big"
                    i18nKey="cookies-settings"
                  />
                </div>
              </div>
              <div className="MCELCookieBanner__decline">
                <Button
                  i18nKey="cookies-decline"
                  onClick={() => {
                    onAcceptCookies(setAllCookies(false));
                    setShowGDPRCookieBanner(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GDPRCookieModal;
