import { StepComponent } from "@gsp/gusto-front-common";
import React, { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.scss";
import { retrieveDraft } from "../../../../services/documents";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BondSubscribeStepContext } from "../BondSubscribe";

const BondDraft: FC<StepComponent<BondSubscribeStepContext>> = ({
  goToNextStep,
  context,
}) => {
  const { quotation, pushStepDataLayer } = context;
  const [isLoading, setIsLoading] = useState(true);
  const [signatureUrl, setSignedUrl] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    pushStepDataLayer({
      processStep: "2",
      processStepName: "Confirmation",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const location = await retrieveDraft(quotation.id);
      setSignedUrl(location);
      setIsLoading(false);
    })();
  }, []);

  const handleModifyButton = useCallback(() => {
    navigate("/edit-request");
  }, []);

  if (isLoading) return <Spinner aria-label="spinner" />;

  return (
    <div className="mcel-bond-draft" data-testid="bond-draft">
      <div>
        <FormattedMessage id="mcel-bond-draft-title" tagName="h3" />
      </div>
      <div className="document-iframe-container">
        <iframe src={signatureUrl} frameBorder="0" title="draft-frame" />
      </div>
      <div className="mcel-bond-draft__button-container">
        <button
          onClick={handleModifyButton}
          className="button button-big button-secondary mcel-subscribe__cta"
          data-testid="mcel-bond-subscribe-btn-modify">
          <FormattedMessage id="mcel-bond-subscribe-btn-modify" />
        </button>
        <button
          onClick={goToNextStep}
          className="button button-big button--red mcel-subscribe__cta"
          data-testid="mcel-bond-subscribe-btn-confirm">
          <FormattedMessage id="mcel-bond-subscribe-btn-confirm-draft" />
        </button>
      </div>
    </div>
  );
};

export default BondDraft;
