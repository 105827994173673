import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import {
  Checkbox,
  Modal,
  StepComponent,
  buildClassName,
} from "@gsp/gusto-front-common";

import ModalBondPayment from "./ModalBondPayment";
import { BondSubscribeStepContext } from "../BondSubscribe";

const BondPayment = ({
  context,
  goToNextStep,
}: StepComponent<BondSubscribeStepContext>) => {
  const [agreement, setAgreement] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer({
      processStep: "3",
      processStepName: "Paiement",
    });
  }, []);

  const handleAgreementChange = useCallback(() => {
    setAgreement((state) => !state);
  }, [setAgreement]);

  const handlePaymentButtonClick = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const handlePaymentSuccess = useCallback(() => {
    setOpenModal(false);
    goToNextStep();
  }, [setOpenModal, goToNextStep]);

  const buttonClass = buildClassName({
    button: true,
    "button-big": true,
    "button--red": true,
    "mcel-subscribe__cta": true,
    "mcel-subscribe__cta--center": true,
    "button--disabled": !agreement,
  });

  return (
    <div data-testid="mcel-bond-payment">
      <div className="title text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-payment-title" />
      </div>
      <div className="mcel-subscribe__payment__text text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-payment-text" />
      </div>
      <div className="text-blue mb-3">
        <Checkbox
          dataTestid="mcel-payment-agreement"
          labelKey="mcel-bond-subscribe-payment-agreement"
          changeValue={handleAgreementChange}
        />
      </div>
      <div className="text-center">
        <button
          data-testid="mcel-payment-button"
          className={buttonClass}
          onClick={handlePaymentButtonClick}
          disabled={!agreement}>
          <FormattedMessage id="mcel-bond-subscribe-payment-button" />
        </button>
      </div>
      <Modal
        isOpen={openModal}
        setOpenState={setOpenModal}
        titleKey=""
        forceStayOpen={true}
        style={{ maxWidth: "25rem" }}>
        <ModalBondPayment
          titleKey="mcel-bond-subscribe-payment-modal-title"
          buttonTextKey="mcel-bond-subscribe-payment-modal-button"
          quotationId={context.quotation.id}
          bondRequestId={context.quotation.bondRequestId}
          bondValue={context.quotation.premiumAmount!.value.toString()}
          companyName={context.quotation.companyName}
          requestorUserId={context.quotation.requestorUserId as string}
          onSuccess={handlePaymentSuccess}
        />
      </Modal>
    </div>
  );
};

export default BondPayment;
