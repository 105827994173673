import { TemplateCode } from "../models/TemplateCode";
import apiUrls from "./apiUrls";
import { authenticatedGet, authenticatedPost } from "./authenticatedFetch";

export type AdditionalPropertiesDraftParameter = {
  clientName: string;
  clientStreetName: string;
  clientStreetNumber: string;
  clientPostCode: string;
  clientTown: string;
  beneficiaryName: string;
  beneficiaryStreet: string;
  beneficiaryStreetNumber: string;
  beneficiaryPostCode: string;
  beneficiaryTown: string;
  bondRequestId: string;
  bondAmount: number;
  bondAmountInteger: string;
  bondAmountDecimal: string;
  bondAmountCurrency: string;
  projectStartDate: string;
  projectEndDate: string;
  grossPremium: number;
  grossPremiumInteger: string;
  grossPremiumDecimal: string;
  grossPremiumCurrency: string;
  bondCreationDate: string;
};

export const generateDraft = async ({
  templateCode,
  additionalProperties,
  businessObjectId,
}: {
  templateCode: TemplateCode;
  additionalProperties: AdditionalPropertiesDraftParameter;
  businessObjectId: string;
}) => {
  try {
    const { data } = await authenticatedPost(
      apiUrls.generateDraft(templateCode),
      {
        payload: {
          languageCode: "fr",
          businessObjectId,
          attachmentTypeCode: "DRAFT",
          additionalProperties,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const retrieveDraft = async (businessObjectId: string) => {
  try {
    const data = await authenticatedGet(
      apiUrls.retrieveDraft(businessObjectId)
    );
    return data[0].location;
  } catch (error) {
    console.log(error);
  }
};
