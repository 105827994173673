import { ErrorDescriptor } from "@gsp/gusto-front-common";

export const COMPANY_NOT_FOUND: ErrorDescriptor = {
  defaultMessageKey: "company-search-notfound",
};

export const ACCOUNT_CREATION_COMPANY_NOT_FOUND: ErrorDescriptor = {
  defaultMessageKey: "mcel-account-creation-company-search-error-not-found",
};

export const BAD_CODE: ErrorDescriptor = {
  defaultMessageKey: "bad-code",
};
