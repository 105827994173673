import UserAffiliation from "../models/Affiliation";
import { authenticatedPost } from "./authenticatedFetch";
import apiUrls from "./apiUrls";

const AFFILIATION_STORE_ID = "affiliation";

export const storeAffiliationLink = (affiliationLink: string) => {
  localStorage.setItem(AFFILIATION_STORE_ID, affiliationLink);
};

export const getAffiliationLink = () => {
  return localStorage.getItem(AFFILIATION_STORE_ID);
};

export const deleteAffiliationLinkFromStore = () => {
  localStorage.removeItem(AFFILIATION_STORE_ID);
};

export const attachAffiliationToUser = async (
  userAffiliation: UserAffiliation
) => {
  const affiliationLink = getAffiliationLink();

  if (!affiliationLink) {
    return null;
  }

  try {
    await authenticatedPost(apiUrls.attachUserAffiliation(affiliationLink), {
      payload: userAffiliation,
    });
    deleteAffiliationLinkFromStore();
  } catch (error) {
    console.error(error);
  }
};
