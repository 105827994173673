import React from "react";
import { FormattedMessage } from "react-intl";
import CardInformation, { ICardInformation } from "./CardInformation";

interface ICardGroup {
  headingMessageId: string;
  fields: ICardInformation[];
}

const CardGroup = ({ headingMessageId, fields }: ICardGroup) => (
  <div className="request-card__details__group">
    <div className="request-card__details__title subheading">
      <FormattedMessage id={headingMessageId} />
    </div>
    {fields.map((item, idx) => (
      <CardInformation {...item} key={idx} />
    ))}
  </div>
);

export default CardGroup;
