import React, { FC, useContext, useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col, Spinner } from "react-bootstrap";

import {
  TextArea,
  StepComponent,
  LanguageContext,
  getLocaleFormatter,
} from "@gsp/gusto-front-common";
import {
  getBeneficiaryInfo,
  getBondInfo,
  getContactInfo,
  getIntegerAndDecimal,
  getPaymentInfo,
  getTemplateCodeFromBondType,
} from "./BondSummary.utils";

import "./style.scss";
import { MCELBondSummaryGroup } from "./BondSummaryGroup";
import { generateDraft } from "../../../../services/documents";
import { BondSubscribeStepContext } from "../BondSubscribe";

const BondSummary: FC<StepComponent<BondSubscribeStepContext>> = ({
  goToNextStep,
  context,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { localeCode } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const { quotation, transaction, beneficiary, pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer({
      processStep: "1",
      processStepName: "Vérification",
    });
  }, []);

  const localeFormatter = getLocaleFormatter(localeCode);

  const beneficiaryInfo = getBeneficiaryInfo(context);

  const bondInfo = getBondInfo(quotation, localeFormatter, transaction);

  const paymentInfo = getPaymentInfo(quotation, localeFormatter, intl);

  const contractInfo = getContactInfo(quotation, localeFormatter, transaction);

  const handleModifyButton = useCallback(() => {
    navigate("/edit-request");
  }, []);

  useEffect(() => {
    console.log("quotation", quotation);
  }, [quotation]);

  const verifyQuotation = useCallback(async () => {
    setIsLoading(true);
    const {
      companyName,
      companyAddressLine1,
      companyAddressLine2,
      companyStreetNumber,
      companyPostalCode,
      town,
      bondType,
      bondAmount: { currencyCode },
      startDate,
      endDate,
      bondRequestId,
    } = quotation;

    const bondAmount = quotation.bondAmount.value;
    const grossPremium = quotation?.grossPremiumAmount?.value ?? 0;
    const grossPremiumCurrency =
      quotation?.grossPremiumAmount?.currencyCode ?? "";

    const templateCode = getTemplateCodeFromBondType(bondType);
    const { decimal: bondAmountDecimal, integer: bondAmountInteger } =
      getIntegerAndDecimal(bondAmount);
    const { decimal: grossPremiumDecimal, integer: grossPremiumInteger } =
      getIntegerAndDecimal(grossPremium);

    await generateDraft({
      templateCode,
      businessObjectId: quotation.id,
      additionalProperties: {
        clientName: companyName,
        clientStreetName: companyAddressLine2
          ? `${companyAddressLine1} ${companyAddressLine2}`
          : companyAddressLine1,
        clientStreetNumber: companyStreetNumber,
        clientPostCode: companyPostalCode,
        clientTown: town,
        beneficiaryName: beneficiary?.companyName ?? "",
        beneficiaryStreet: beneficiary?.address.streetName ?? "",
        beneficiaryStreetNumber: beneficiary?.address.streetNumber ?? "",
        beneficiaryPostCode: beneficiary?.address.postCode ?? "",
        beneficiaryTown: beneficiary?.address.town ?? "",
        bondRequestId,
        bondAmount,
        bondAmountInteger,
        bondAmountDecimal,
        bondAmountCurrency: currencyCode,
        projectStartDate: startDate ?? "",
        projectEndDate: endDate ?? "",
        grossPremium: quotation?.grossPremiumAmount?.value ?? 0,
        grossPremiumInteger,
        grossPremiumDecimal,
        grossPremiumCurrency,
        bondCreationDate: format(new Date(), "dd/MM/yyyy"),
      },
    });
    goToNextStep();
    setIsLoading(false);
  }, []);

  return (
    <div className="mcel-bond-summary" data-testid="mcel-bond-summary">
      <div className="title text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-title" />
      </div>
      <Row>
        <Col sm={6} xs={12}>
          <MCELBondSummaryGroup
            title="mcel-bond-subscribe-beneficiary"
            items={beneficiaryInfo}
            dataTestid="mcel-bond-subscribe-beneficiary"
          />
          <MCELBondSummaryGroup
            title="mcel-bond-subscribe-bond"
            items={bondInfo}
            dataTestid="mcel-bond-subscribe-bond"
          />
          <MCELBondSummaryGroup
            title="mcel-bond-payment-title"
            items={paymentInfo}
            dataTestid="mcel-bond-subscribe-payment"
          />
        </Col>
        <Col sm={6} xs={12}>
          <MCELBondSummaryGroup
            title="mcel-bond-subscribe-contract"
            items={contractInfo}
            dataTestid="mcel-bond-subscribe-contract"
          />
          <TextArea
            value={transaction ? transaction.description : ""}
            changeValue={() => {}}
            disabled={true}
            dataTestid="mcel-bond-subscribe-description"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={12} className="mcel-subscribe__modify">
          <button
            onClick={handleModifyButton}
            className="button button-big button-secondary mcel-subscribe__cta">
            <FormattedMessage id="mcel-bond-subscribe-btn-modify" />
          </button>
        </Col>
        <Col sm={6} xs={12} className="text-left">
          <button
            onClick={verifyQuotation}
            className={`button button-big button--red mcel-subscribe__cta ${
              isLoading ? "mcel-subscribe__cta--disabled" : ""
            }`}
            disabled={isLoading}>
            {isLoading ? (
              <Spinner aria-label="spinner" size="sm" />
            ) : (
              <FormattedMessage id="mcel-bond-subscribe-btn-confirm" />
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default BondSummary;
