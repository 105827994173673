import { Address } from "@gsp/gusto-front-common";

export const addSpacesToString = (str: string, spaces: number[]) => {
  return [0, ...spaces, str.length]
    .reduce((acc, cur, idx, arr) => {
      return acc + str.slice(cur, arr[idx + 1]) + " ";
    }, "")
    .trim();
};

export const getCompanyAddress = (address?: Address) => {
  return address
    ? [address.streetNumber, address.streetName, address.postCode, address.town]
        .filter((item) => !!item)
        .join(" ")
    : null;
};

export const formatReferenceNumber = (
  referenceNumber?: string,
  additionalContractClause?: string
) => {
  return referenceNumber && additionalContractClause
    ? referenceNumber + " / " + additionalContractClause
    : referenceNumber || additionalContractClause;
};
