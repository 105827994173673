import React from "react";
import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-scroll";
import { useAdobeContext } from "../../../AdobeAnalytics/AdobeProvider";

const Menu = () => {
  const { pushCtaDataLayer } = useAdobeContext();
  return (
    <div className="mcel-home__menu">
      <Nav className="container">
        {["why", "how", "about"].map((item) => (
          <div className="nav-item" key={item}>
            <Link
              to={item}
              spy={true}
              smooth={true}
              duration={200}
              className="nav-link"
              onClick={() => pushCtaDataLayer(item)}>
              <FormattedMessage id={`mcel-home-menu-${item}`} />
            </Link>
          </div>
        ))}
      </Nav>
    </div>
  );
};

export default Menu;
