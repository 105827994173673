import IBondInvoice from "../../../../../models/IBondInvoice";
import { ErrorDescriptor } from "@gsp/gusto-front-common";
import IBond from "../../../../../models/IBond";
import useGetFile from "../../../../../hooks/useGetFile";
import React, { useEffect } from "react";
import { Link } from "@myeh/design-system";
import apiUrls from "../../../../../services/apiUrls";
import { FormattedMessage } from "react-intl";
import { formatDate } from "../../../../../utils/helpers";

interface IInvoiceLink {
  invoice: IBondInvoice;
  onError: (error: ErrorDescriptor) => void;
  bond: IBond;
}

const InvoiceLink = ({ invoice, onError, bond }: IInvoiceLink) => {
  const [{ error, loading }, getFile] = useGetFile();

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  return (
    <Link
      onClick={() =>
        getFile(
          apiUrls.invoice(
            bond.bondNumber,
            bond.contractNumber,
            invoice.avalisDocumentId
          )
        )
      }
      disabled={loading}
      icon="pdf"
      css={{}}
      className="invoice-link">
      <FormattedMessage
        id="mcel-invoice"
        values={{
          documentId: invoice.avalisDocumentId,
          documentDate: formatDate(invoice.attachmentCreationDateTime),
        }}
      />
    </Link>
  );
};

export default InvoiceLink;
