import {
  FetchOptions,
  safeFetch,
  safeGet,
  safePost,
  ManagedError,
  UNAUTHORIZED,
  safeUpload,
} from "@gsp/gusto-front-common";
import { connectionHandler } from "../App";

const authenticatedUpload = async (
  url: string,
  data: FormData,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  try {
    const response = await safeUpload(url, data, onUploadProgress);
    if (response?.status === 401) {
      connectionHandler.setUserHasBeenDisconnected(true);
      return Promise.reject(UNAUTHORIZED);
    }
    return response;
  } catch (error) {
    if (
      error === UNAUTHORIZED ||
      (error instanceof ManagedError && error.descriptor === UNAUTHORIZED)
    ) {
      connectionHandler.setUserHasBeenDisconnected(true);
    }
    return Promise.reject(error);
  }
};

const authenticatedDocumentFetch = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  try {
    const response = await safeFetch(input, {
      ...init,
      credentials: "include",
    });
    if (response?.status === 401) {
      connectionHandler.setUserHasBeenDisconnected(true);
      return Promise.reject(UNAUTHORIZED);
    }
    return response;
  } catch (error) {
    if (
      error === UNAUTHORIZED ||
      (error instanceof ManagedError && error.descriptor === UNAUTHORIZED)
    ) {
      connectionHandler.setUserHasBeenDisconnected(true);
    }
    return Promise.reject(error);
  }
};

const authenticatedFetch = async (
  method: string,
  url: string,
  options?: FetchOptions | undefined
): Promise<any> => {
  try {
    let response: Response;
    switch (method) {
      case "POST":
        response = await safePost(url, options);
        break;
      case "GET":
        response = await safeGet(url, options);
        break;
      default:
        return Promise.reject("Method type not implemented");
    }
    if (response?.status === 401) {
      connectionHandler.setUserHasBeenDisconnected(true);
      return Promise.reject(UNAUTHORIZED);
    }
    return response;
  } catch (error) {
    if (error === UNAUTHORIZED) {
      connectionHandler.setUserHasBeenDisconnected(true);
    }
    return Promise.reject(error);
  }
};

const authenticatedPost = (url: string, options?: FetchOptions | undefined) =>
  authenticatedFetch("POST", url, options);

const authenticatedGet = (url: string, options?: FetchOptions | undefined) =>
  authenticatedFetch("GET", url, options);

export {
  authenticatedPost,
  authenticatedGet,
  authenticatedDocumentFetch,
  authenticatedUpload,
};
