import React, { useState, useCallback, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  Outlet,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import { UserContext, UserType } from "@gsp/gusto-front-common";

import IRouteData from "../../models/IRouteData";
import RouteBreadCrumb from "../RouteBreadCrumb";
import UserAccountCreation from "./UserAccountCreation";
import RequestList from "./RequestList/RequestList";
import Header from "./Header";

import BondRequest from "./BondRequest/BondRequest";
import BondQuotation from "./BondQuotation/BondQuotation";
import BondSubscribe from "./BondSubscribe/BondSubscribe";
import WizardWrapper from "./WizardWrapper";
import Home from "./Home";
import Login from "./Login";
import BondList from "./BondList/BondList";

import "./style.scss";
import {
  attachAffiliationToUser,
  storeAffiliationLink,
} from "../../services/affiliation";
import { useAdobeContext } from "../AdobeAnalytics/AdobeProvider";

export const mcelPaths: IRouteData[] = [
  {
    path: "/",
    titleKey: "mcel-page-home",
  },
  {
    path: "/new-quotation",
    titleKey: "mcel-page-quotation-request",
  },
  {
    path: "/edit-quotation",
    titleKey: "mcel-page-quotation-request",
  },
  {
    path: "/subscribe",
    titleKey: "mcel-page-quotation-request",
  },
  {
    path: "/new-request",
    titleKey: "mcel-page-quotation-request",
  },
  {
    path: "/edit-request",
    titleKey: "mcel-page-quotation-request",
  },
  {
    path: "/quotations",
    titleKey: "mcel-page-requests",
  },
  {
    path: "/ebonds",
    titleKey: "mcel-page-bonds",
  },
  {
    path: "/register",
    titleKey: "mcel-page-requests",
  },
];

const ContainerLayout = () => (
  <Container className="mcel-section">
    <Outlet />
  </Container>
);

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const authorized =
    user.authenticated && user.userType === UserType.MCEL_CLIENT;
  const { setPreviousPathName } = useAdobeContext();

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    setPreviousPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    if (authorized) {
      navigate("/quotations");
    }
    const affiliationLink = searchParams.get("affiliationLink");
    if (affiliationLink) {
      storeAffiliationLink(affiliationLink);
      setSearchParams({});
    }
  }, [authorized]);

  return (
    <div className={"mcel-page" + (authorized ? " logged-in" : "")}>
      <Header isAuthorized={authorized} />
      {authorized ? <RouteBreadCrumb paths={mcelPaths} /> : null}
      <Routes>
        {authorized ? (
          <Route element={<ContainerLayout />}>
            <Route path="/quotations" element={<RequestList />} />
            <Route path="/ebonds" element={<BondList />} />
            <Route
              path="/new-quotation"
              element={
                <WizardWrapper wizardStage={0}>
                  <BondQuotation />
                </WizardWrapper>
              }
            />
            <Route
              path="/new-request"
              element={
                <WizardWrapper wizardStage={1}>
                  <BondRequest />
                </WizardWrapper>
              }
            />
            <Route
              path="/edit-request"
              element={
                <WizardWrapper wizardStage={1}>
                  <BondRequest editMode />
                </WizardWrapper>
              }
            />
            <Route
              path="/subscribe"
              element={
                <WizardWrapper wizardStage={2}>
                  <BondSubscribe />
                </WizardWrapper>
              }
            />
            <Route path="*" element={<Navigate to="/quotations" replace />} />
          </Route>
        ) : (
          <Route element={<ContainerLayout />}>
            <Route path="/register" element={<UserAccountCreation />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        )}
        <Route
          path="/"
          element={
            <Home isLoggedIn={user.authenticated} isAuthorized={authorized} />
          }
        />
        <Route
          path="/login"
          element={
            <Login isLoggedIn={user.authenticated} isAuthorized={authorized} />
          }
        />
      </Routes>
    </div>
  );
};

export default Dashboard;
