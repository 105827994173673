import React, { useState, useCallback, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import {
  UserContext,
  Banner,
  Input,
  Loader,
  StepComponent,
} from "@gsp/gusto-front-common";

import { getLocalIdentifierFormat } from "../../../../services/localIdentifiers";
import { isValidSirenNumber } from "../../../../services/validator";
import { fetchCompany } from "../../../../services/company";

import BondRequestContext from "../../../../models/BondRequestContext";
import ICompany from "../../../../models/ICompany";

import companyIcon from "../../../../resources/company-icon.svg";

const { formatter, parser } = getLocalIdentifierFormat("SIREN");

const BondBeneficiary = ({
  context,
  setStepCompleted,
}: StepComponent<BondRequestContext>) => {
  const { user } = useContext(UserContext);
  const { updateDraft } = context;
  const [siren, setSiren] = useState("");
  const [company, setCompany] = useState<ICompany | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer(2, "Bénéficiaire");
  }, []);

  const handleSirenChange = useCallback(
    async (value: string) => {
      const formatted = formatter(value);
      setSiren(formatted);
      setCompany(null);
      if (isValidSirenNumber(formatted)) {
        setLoading(true);
        try {
          const res = await fetchCompany("FR", "SIREN", parser(formatted));
          setLoading(false);
          setError(null);
          if (res) {
            if (res.companyId !== user.parentCompanyId) {
              setCompany(res);
            } else {
              setError("mcel-request-bond-beneficiary-same-company");
            }
          }
        } catch (err: any) {
          setLoading(false);
          setError((err.defaultMessageKey || "technical-error") as string);
        }
      } else {
        setCompany(null);
      }
    },
    [setSiren, setCompany, setLoading, setError, user.parentCompanyId]
  );

  useEffect(() => {
    updateDraft({ beneficiaryCompanyId: company?.companyId });
    setStepCompleted(!!company);
  }, [company, updateDraft, setStepCompleted]);

  return (
    <div
      data-testid="mcel-beneficiary"
      className="mcel-bond-request  mcel-bond-request--compact mb-3">
      <div className="field-container mb-3">
        <Input
          value={siren}
          changeValue={handleSirenChange}
          placeholder="Saisissez un n° de SIREN"
          labelKey="mcel-request-bond-beneficiary-label"
        />
      </div>
      {loading && <Loader isVisible />}
      {company && (
        <Container
          data-testid="mcel-beneficiary-company"
          className="search-result text-blue text-center">
          <Row className="company-data">
            <Col sm={2}>
              <img src={companyIcon} alt="company icon" />
            </Col>
            <Col>
              <h4>{company.companyName}</h4>
              <div className="info">
                <FormattedMessage id="mcel-request-bond-beneficiary-siren" />{" "}
                <strong>
                  {company.localIdentifiers &&
                    formatter(
                      company.localIdentifiers.filter(
                        (li) => li.idTypeCode === "SIREN"
                      )[0].idValue
                    )}
                </strong>{" "}
                <br />
                {company.address &&
                  [
                    company.address.streetName,
                    company.address.postCode,
                    company.address.town,
                    company.address.countryCode,
                  ]
                    .filter(Boolean)
                    .join(" • ")}
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {error && (
        <Banner bannerType="error">
          <FormattedMessage id={error} />
        </Banner>
      )}
    </div>
  );
};

export default BondBeneficiary;
