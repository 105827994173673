import {
  IUserData,
  ManagedError,
  TECHNICAL_ERROR,
} from "@gsp/gusto-front-common";

import BondDraft from "../models/BondDraft";

import apiUrls from "./apiUrls";
import { authenticatedPost } from "./authenticatedFetch";
import { BAD_CODE } from "./error";

const bondDraftRequestResponseHandler = async (
  statusCode: number,
  response: Response
) => {
  if (statusCode >= 400 && statusCode < 500) {
    throw new ManagedError(BAD_CODE);
  }
  if (!response.ok) {
    throw new ManagedError(TECHNICAL_ERROR);
  }
  return response.json();
};

export const bondDraftRequest = async (
  payload: BondDraft,
  verificationToken?: string
) => {
  const options = {
    headerKeyValues: verificationToken
      ? { "Verification-Token": verificationToken }
      : undefined,
    responseHandler: bondDraftRequestResponseHandler,
    payload,
  };
  return authenticatedPost(apiUrls.bondDraftRequest(), options);
};
