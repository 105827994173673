import frMessages from "./fr_FR.json";
import { Language } from "@gsp/gusto-front-common";
interface WorkflowLocale {
  acceptedLanguages: Language[];
  defaultLanguage: Language;
}

export const getUserLang = (detectedLang: string) => {
  const currentWorkflowLanguages: WorkflowLocale = {
    acceptedLanguages: ["fr"],
    defaultLanguage: "fr",
  };
  const userLang: Language =
    currentWorkflowLanguages.acceptedLanguages.includes(
      detectedLang as Language
    )
      ? (detectedLang as Language)
      : currentWorkflowLanguages.defaultLanguage;

  return userLang;
};

export function getLocaleCode(language: Language) {
  return "fr-FR";
}

export function getMessages(language: Language) {
  return frMessages;
}
