import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";

import {
  Banner,
  Checkbox,
  Dropdown,
  Input,
  RadioGroup,
  StepComponent,
  isValidName,
  isValidEmail,
  isValidFrenchPhoneNumber,
  ErrorDescriptor,
} from "@gsp/gusto-front-common";

import UserAccountCreationContext from "../../../../models/UserAccountCreationContext";
import NewUserType from "../../../../models/NewUserType";
import {
  formatFrenchPhoneNumber,
  frenchInternationalPrefixes,
  getPrefixFrenchPhoneNumber,
  sanitizeFrenchNumber,
} from "../../../../services/phone";
import { verifyNewAccount } from "../../../../services/userAccount";

// TODO: use a reducer to do this action

interface RecaptchaRenderParameters {
  sitekey: string;
  callback?: (token: string) => void;
  theme?: string;
  size?: "compact" | "normal";
  tabindex?: number;
}

declare global {
  interface Window {
    Cypress: any | undefined;
    grecaptcha: {
      render: (
        containerId: string | HTMLElement,
        parameters: RecaptchaRenderParameters
      ) => void;
      reset: () => void;
    };
  }
}

const genderOptions = [
  {
    key: "mcel-account-creation-user-gender-women",
    value: "MS",
  },
  {
    key: "mcel-account-creation-user-gender-men",
    value: "MR",
  },
];

const Identification = ({ goToNextStep, currentIndex }: StepComponent) => {
  const intl = useIntl();
  const { data, updateData, pushStepDataLayer } = useContext(
    UserAccountCreationContext
  );
  const phoneNumber = data.userData?.phoneNumber
    ? formatFrenchPhoneNumber(data.userData?.phoneNumber)
    : "";
  const [gender, setGender] = useState<string>(
    data.userData?.salutation || "MS"
  );
  const [firstName, setFirstName] = useState<string>(
    data.userData?.firstName || ""
  );
  const [lastName, setLastName] = useState<string>(
    data.userData?.lastName || ""
  );
  const [phone, setPhone] = useState(phoneNumber || "");
  const [phonePrefix, setPhonePrefix] = useState(
    (phoneNumber && getPrefixFrenchPhoneNumber(phoneNumber)) || "+33"
  );
  const [email, setEmail] = useState<string>(data.userData?.emailAddress || "");
  const [agreement, setAgreement] = useState<boolean>(false);
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorKey, setErrorKey] = useState<string>();
  const [captchaResponse, setCaptchaResponse] = useState("");

  useEffect(() => {
    pushStepDataLayer(currentIndex, "Je m'identifie");
  }, []);

  useEffect(() => {
    // We mock recaptcha for cypress integration test in pipeline
    /* istanbul ignore next */
    if (window.Cypress) {
      window.grecaptcha = {
        render: (token, { callback }) => callback?.("12345"),
        reset: () => {},
      };
    }
  }, []);

  useEffect(() => {
    if (recaptchaRef.current) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey: window.Cypress
          ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          : "6Le7eOMZAAAAAOflzE2v-GdURrXMvBJjqNcnF7T6",
        callback: (token: string) => {
          setCaptchaResponse(token);
        },
      });
    }
  }, []);

  const handleUserFirstNameBlur = useCallback(() => {
    if (firstName !== "") {
      setErrorFirstName(!isValidName(firstName));
    }
  }, [firstName]);

  const handleUserLastNameBlur = useCallback(() => {
    if (lastName !== "") {
      setErrorLastName(!isValidName(lastName));
    }
  }, [lastName]);

  const handleUserPhoneChange = useCallback(
    (value: string) => {
      const formatedPhone = formatFrenchPhoneNumber(value);
      if (formatedPhone) {
        setPhone(formatedPhone);
      }
    },
    [setPhone]
  );

  const handleUserPhoneBlur = useCallback(() => {
    if (phone !== "") {
      setErrorPhone(!isValidFrenchPhoneNumber(phone));
    }
  }, [phone]);

  const handleUserEmailBlur = useCallback(() => {
    if (email !== "") {
      setErrorEmail(!isValidEmail(email));
    }
  }, [email]);

  const handleCheckBoxChange = useCallback(() => {
    setAgreement(!agreement);
  }, [agreement]);

  const [submitting, setSubmitting] = useState(false);

  const handleGoToNextStepClick = useCallback(async () => {
    try {
      setSubmitting(true);
      const userData = {
        salutation: gender as "MS" | "MR",
        firstName,
        lastName,
        phoneNumber: sanitizeFrenchNumber(phone, phonePrefix),
        emailAddress: email,
        // TODO
        userType: NewUserType.SFAC_CLIENT,
        preferredLanguageCode: "FR",
      };
      const isNewAccount = await verifyNewAccount(
        userData,
        captchaResponse || ""
      );
      if (isNewAccount) {
        updateData({
          userData,
        });
        goToNextStep();
      } else {
        setErrorKey("mcel-account-creation-user-error-new-user");
        window.grecaptcha.reset();
        setSubmitting(true);
      }
    } catch (e: any) {
      setErrorKey((e as ErrorDescriptor).defaultMessageKey);
      window.grecaptcha.reset();
      setSubmitting(true);
    }
  }, [
    email,
    goToNextStep,
    setErrorKey,
    captchaResponse,
    firstName,
    gender,
    lastName,
    phone,
    phonePrefix,
    updateData,
  ]);

  const isValid =
    agreement &&
    firstName !== "" &&
    !errorFirstName &&
    lastName !== "" &&
    !errorLastName &&
    phone !== "" &&
    !errorPhone &&
    email !== "" &&
    !errorEmail &&
    (!!window.Cypress || captchaResponse !== "");

  const privacyLink = (...chunks: Array<ReactNode>) => (
    <a
      target="_blank"
      href={intl.formatMessage({
        id: "mcel-account-creation-user-privacy-url",
      })}
      rel="noreferrer"
      onClick={(event) => event.stopPropagation()}>
      {chunks}
    </a>
  );

  return (
    <div>
      <Container>
        <Row>
          <Col xs="12">
            <div className="body1-light text-blue mb-2">
              <FormattedMessage id="mcel-account-creation-user-title" />
            </div>
            <div className="caption">
              <FormattedMessage id="mcel-account-creation-user-caption" />
            </div>
            <RadioGroup
              options={genderOptions}
              name="gender"
              align="start"
              selectedValue={gender}
              onChange={setGender}
              disabled={false}
              labelSize="small"
              dataTestid="mcel-account-creation-user-gender"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <Input
              labelKey="mcel-account-creation-user-first-name"
              value={firstName}
              changeValue={setFirstName}
              inputBlur={handleUserFirstNameBlur}
              isRequired={true}
              placeholder="Jean *"
              dataTestid="mcel-account-creation-user-first-name"
              defaultError={
                errorFirstName ? "mcel-account-creation-user-error-name" : ""
              }
            />
          </Col>
          <Col xs="12" sm="6">
            <Input
              labelKey="mcel-account-creation-user-last-name"
              value={lastName}
              changeValue={setLastName}
              inputBlur={handleUserLastNameBlur}
              isRequired={true}
              placeholder="DUPONT *"
              dataTestid="mcel-account-creation-user-last-name"
              defaultError={
                errorLastName ? "mcel-account-creation-user-error-name" : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <Row className="phone-group">
              <Col xs="3" sm="5" md="4" xl="3">
                <Dropdown
                  value={phonePrefix}
                  items={frenchInternationalPrefixes}
                  onChange={setPhonePrefix}
                  labelKey="prefix"
                  dataTestid="mcel-account-creation-user-phone-prefix"
                />
              </Col>
              <Col xs="9" sm="7" md="8" xl="9">
                <Input
                  labelKey="mcel-account-creation-user-phone"
                  value={phone}
                  changeValue={handleUserPhoneChange}
                  inputBlur={handleUserPhoneBlur}
                  isRequired={true}
                  placeholder="06 __ __ __ __"
                  dataTestid="mcel-account-creation-user-phone"
                  defaultError={
                    errorPhone ? "mcel-account-creation-user-error-phone" : ""
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="6">
            <Input
              labelKey="mcel-account-creation-user-email"
              value={email}
              changeValue={setEmail}
              inputBlur={handleUserEmailBlur}
              isRequired={true}
              placeholder="email@professionnel.fr *"
              dataTestid="mcel-account-creation-user-email"
              defaultError={
                errorEmail ? "mcel-account-creation-user-error-email" : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="text-blue">
              <Checkbox
                labelKey="mcel-account-creation-user-privacy"
                labelValues={{
                  privacyLink,
                }}
                isChecked={agreement}
                changeValue={handleCheckBoxChange}
                textCenter={true}
                dataTestid="mcel-account-creation-user-privacy"
              />
            </div>
          </Col>
        </Row>
        {errorKey && (
          <Row>
            <Col xs="12">
              <Banner bannerType="error">
                <FormattedMessage id={errorKey} />
              </Banner>
            </Col>
          </Row>
        )}
        <Row className="row-controls-identification">
          <Col xs="12" sm="6">
            <div
              id="recaptcha-container"
              ref={recaptchaRef}
              data-testid="recaptcha-container"
            />
          </Col>
          <Col xs="12" sm="6">
            <button
              className={`button ${
                isValid && !submitting ? "" : "button--disabled"
              }`}
              onClick={handleGoToNextStepClick}
              disabled={!isValid && submitting}
              data-testid="mcel-account-creation-user-identification-next-btn">
              <FormattedMessage id="mcel-account-creation-user-identification-next-btn" />
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Identification;
