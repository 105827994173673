import { BondType } from "@gsp/gusto-front-common";

export const calculateBondRate = (
  bondType: BondType,
  defaultBondRate: number
) => {
  switch (bondType) {
    case "SUBCONTRACTOR_PAYMENT_GUARANTEE":
      return 1;
    case "MAIN_COMPANY":
    case "SUBCONTRACTOR":
    case "GUARANTEE_FIRST_REQUEST":
    case "PERSONAL_DEPOSIT":
      return 0.05;
    case "JOINT_SURETY":
      return 0.01;
    default:
      return defaultBondRate;
  }
};
