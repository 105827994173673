import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { StepComponent } from "@gsp/gusto-front-common";
import UserAccountCreationContext from "../../../../models/UserAccountCreationContext";
import { useAdobeContext } from "../../../AdobeAnalytics/AdobeProvider";

const ActionEmail = ({ currentIndex }: StepComponent) => {
  const { pushStepDataLayer, data } = useContext(UserAccountCreationContext);
  const { pushEventDataLayer } = useAdobeContext();

  useEffect(() => {
    pushStepDataLayer(currentIndex, "J'active mon compte");
    pushEventDataLayer({
      eventType: "account",
      eventName: "account creation",
      event: "accountCreation",
      userID: data.userData?.emailAddress,
    });
  }, []);

  return (
    <div className="user-account-creation__validation">
      <div className="title text-blue mb-3">
        <FormattedMessage id="mcel-account-creation-validation-title" />
      </div>
      <div className="body2-regular mb-2">
        <FormattedMessage id="mcel-account-creation-validation-text-1" />
      </div>
      <div className="body2-regular">
        <FormattedMessage id="mcel-account-creation-validation-text-2" />
      </div>
    </div>
  );
};

export default ActionEmail;
