import React from "react";

const Video = () => {
  return (
    <section className="mcel-home__section mcel-home__video">
      <div className="mcel-home__video__wrapper">
        <div className="mcel-home__video__frame">
          <iframe
            width="100%"
            height="523"
            src="https://www.youtube.com/embed/3uA63EnJk7U"
            frameBorder="0"
            allow="autoplay; encrypted-media;"
            allowFullScreen
            title="video presentation"
          />
        </div>
      </div>
    </section>
  );
};

export default Video;
