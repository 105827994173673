import apiUrls from "./apiUrls";
import { authenticatedPost } from "./authenticatedFetch";

interface PaymentIntentRequestPayload {
  quotationRequestId: string;
}

export const paymentIntentRequest = (payload: PaymentIntentRequestPayload) => {
  return authenticatedPost(apiUrls.bondInitPaymentIntent(), {
    payload,
  });
};

interface PaymentIntentValidationRequestPayload {
  paymentIntentId: string;
  bondRequestId: string;
}

export const paymentIntentValidationRequest = (
  payload: PaymentIntentValidationRequestPayload
) => {
  return authenticatedPost(apiUrls.bondValidatePaymentIntent(), {
    payload,
    responseHandler: async (statusCode: number) => {
      if (statusCode === 204) {
        return Promise.resolve("ok");
      }
    },
  });
};
