/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect, FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  Banner,
  Loader,
  StepComponent,
  ErrorDescriptor,
} from "@gsp/gusto-front-common";

import apiUrls from "../../../../services/apiUrls";

import {
  initSignatureRequest,
  getSignatureRequest,
} from "../../../../services/signature";

import "./style.scss";
import { BondSubscribeStepContext } from "../BondSubscribe";
import { useAdobeContext } from "../../../AdobeAnalytics/AdobeProvider";

const BondSignature: FC<StepComponent<BondSubscribeStepContext>> = ({
  context,
}) => {
  const [signatureUrl, setSignatureUrl] = useState("");
  const [errorDescriptor, setErrorDescriptor] = useState<ErrorDescriptor>();
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { formatMessage } = useIntl();

  const { pushEventDataLayer, pushErrorDataLayer } = useAdobeContext();

  const { pushStepDataLayer } = context;

  pushStepDataLayer({
    processStep: "4",
    processStepName: "Signature",
  });

  const onSignatureSuccess = (signatureUrl: string) => {
    pushEventDataLayer({
      eventType: "Bond subscription",
      eventName: "Contract signed",
      event: "contractSigned",
    });
    setSignatureUrl(signatureUrl);
  };

  useEffect(() => {
    if (context.status === "PENDING_SIGNATURE") {
      getSignatureRequest({
        bondQuotationRequestId: context.quotation.id,
      })
        .then((res) => onSignatureSuccess(res.url))
        .catch((reason) => {
          setErrorDescriptor(reason);
          pushErrorDataLayer(
            "GET /mcel/signature/{bondQuotationRequestId}",
            reason,
            formatMessage({ id: reason })
          );
        });
    } else {
      initSignatureRequest({
        bondQuotationRequestId: context.quotation.id,
      })
        .then((res) => onSignatureSuccess(res.url))
        .catch((reason) => {
          setErrorDescriptor(reason);
          pushErrorDataLayer(
            "POST /mcel/signature/init",
            reason,
            formatMessage({ id: reason })
          );
        });
    }
  }, [setSignatureUrl, context]);

  useEffect(() => {
    const evtSource = new EventSource(
      apiUrls.signatureListen(context.quotation.id)
    );

    evtSource.onmessage = (e) => {
      if (e.data.includes("PROCESSING")) {
        setIsProcessing(true);
      }
      if (e.data.includes("COMPLETED")) {
        setIsProcessing(false);
        setSubscribeSuccess(true);
        pushEventDataLayer({
          eventType: "Bond subscription",
          eventName: "Bond subscribed",
          event: "bondSubscribed",
        });
      }
      if (e.data.includes("FAILED") || e.data.includes("TERMINATED")) {
        setIsProcessing(false);
        setErrorDescriptor({
          defaultMessageKey: "technical-error",
        });
        pushErrorDataLayer(
          "EVENT /mcel/signature/listen/{bondQuotationRequestId}",
          "technical-error",
          formatMessage({
            id: "technical-error",
          })
        );
        evtSource.close();
      }
    };

    evtSource.onerror = (e) => {
      console.error("EventSource failed.", e);
    };

    return () => {
      evtSource.close();
    };
  }, [setSubscribeSuccess, context]);

  if (subscribeSuccess) {
    return <SignatureCompletedBlock />;
  }

  return (
    <div data-testid="mcel-bond-signature">
      <div className="title text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-signature-title" />
      </div>
      <div className="text-blue mb-1">
        <FormattedMessage id="mcel-bond-subscribe-signature-text-1" />
      </div>
      <div className="text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-signature-text-2" />
      </div>
      <div className="text-blue mb-3">
        <FormattedMessage id="mcel-bond-subscribe-signature-text-3" />
      </div>
      {errorDescriptor ? (
        <ErrorBlock />
      ) : isProcessing ? (
        <ProcessingBlock />
      ) : (
        <SigningFrame signatureUrl={signatureUrl} />
      )}
    </div>
  );
};

const ErrorBlock = () => (
  <div className="mcel-signature-error" data-testid="mcel-signature-error">
    <Banner bannerType="error" bannerTitleKey="technical-error" />
  </div>
);

const ProcessingBlock = () => (
  <div className="mcel-signature-processing">
    <div className="title text-blue mb-3">
      <FormattedMessage id="mcel-bond-subscribe-signature-processing" />
    </div>
    <Loader isVisible={true} />
  </div>
);

interface SigningFrameProps {
  signatureUrl?: string;
}

const SigningFrame = ({ signatureUrl }: SigningFrameProps) =>
  signatureUrl !== "" ? (
    <div className="document-iframe-container" data-testid="signature-frame">
      <iframe src={signatureUrl} frameBorder="0" title="signature-frame" />
    </div>
  ) : (
    <Loader isVisible={true} />
  );

const SignatureCompletedBlock = () => (
  <div className="text-center">
    <div className="title text-blue mb-3">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-title" />
    </div>
    <div className="mb-1">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-text-1" />
    </div>
    <div className="mb-1">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-text-2" />
    </div>
    <div className="mb-1">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-text-3" />
    </div>
    <div className="mb-3">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-text-4" />
    </div>
    <Link to="/ebonds" className="button button-big button--center">
      <FormattedMessage id="mcel-bond-subscribe-signature-confirmation-button" />
    </Link>
  </div>
);

export default BondSignature;
