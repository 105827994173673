import React, { useState, useCallback } from "react";
import { startOfDay, addDays } from "date-fns";

import { Stepper, IStep, BondType } from "@gsp/gusto-front-common";
import { useDispatch } from "react-redux";
import { setRequest } from "../RequestSlice/RequestSlice";

import QuotationRequest from "../../../models/QuotationRequest";
import QuotationContext from "../../../models/QuotationContext";

import QuotationType from "./QuotationType";
import MarketValue from "./MarketValue";
import BondValue from "./BondValue";
import BondDuration from "./BondDuration";
import PriceOffer from "./PriceOffer";
import IRequest from "../../../models/IRequest";
import { useNavigate } from "react-router-dom";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";
import { useIntl } from "react-intl";

export const initializeRequest: () => QuotationRequest = () => {
  const today = startOfDay(new Date());
  const tomorrow = addDays(today, 1);
  const expireDate = addDays(today, 2);

  return {
    bondType: "",
    bondValue: {
      value: 0,
      currencyCode: "EUR",
    },
    bondRate: 0.03,
    marketValue: {
      value: 0,
      currencyCode: "EUR",
    },
    taxIndication: "TTC",
    startDate: today,
    endDate: tomorrow,
    priceExpireDate: expireDate,
  };
};

const steps: IStep<QuotationContext>[] = [
  {
    component: QuotationType,
    id: 1,
    labelKey: "mcel-step-bond-type",
    customControls: true,
  },
  {
    component: MarketValue,
    id: 2,
    labelKey: "mcel-step-market-value",
  },
  {
    component: BondValue,
    id: 3,
    labelKey: "mcel-step-bond-value",
  },
  {
    component: BondDuration,
    id: 4,
    labelKey: "mcel-step-bond-duration",
  },
  {
    component: PriceOffer,
    id: 5,
    labelKey: "mcel-step-price-offer",
    noReturn: true,
  },
];

const BondQuotation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pushPageLoadDataLayer } = useAdobeContext();

  const [quotationRequest, setQuotationRequest] = useState<QuotationRequest>(
    initializeRequest()
  );

  const { formatMessage } = useIntl();

  const pushStepDataLayer = (
    processStep: number | string,
    processStepName: string
  ) => {
    pushPageLoadDataLayer({
      processStep: String(processStep),
      processStepName,
      bondAmount: quotationRequest.marketValue.value,
      premiumAmount: "",
      bondDuration:
        quotationRequest.bondDuration &&
        `${quotationRequest.bondDuration.quantity} ${quotationRequest.bondDuration.unit}`,
      pageName: "Bond quotation step " + processStep,
      siteSection: "Bond quotation",
      event: "Virtual page",
      processName: "Bond quotation",
      processDetail:
        quotationRequest.bondType &&
        formatMessage({
          id:
            "mcel-request-bond-type-" + quotationRequest.bondType.toLowerCase(),
        }),
    });
  };

  const updateQuotationRequest = useCallback(
    (update: Partial<QuotationRequest>) => {
      setQuotationRequest((r) => ({
        ...r,
        ...update,
      }));
    },
    []
  );

  const selectRequest = useCallback((request: IRequest) => {
    dispatch(setRequest(request));
    const nextRoute = request.beneficiary ? "/subscribe" : "/new-request";
    navigate(nextRoute);
  }, []);

  const resetRequest = useCallback(
    () => setQuotationRequest(initializeRequest()),
    []
  );

  return (
    <Stepper
      dataTestid="mcel-bond-quotation"
      steps={steps}
      initialStep={0}
      onRestart={resetRequest}
      sharedContext={{
        pushStepDataLayer,
        quotationRequest,
        updateQuotationRequest,
        selectRequest: (request) => selectRequest(request),
      }}
    />
  );
};

export default BondQuotation;
