import INewUser from "../models/INewUser";

import apiUrls from "./apiUrls";
import { authenticatedPost, authenticatedUpload } from "./authenticatedFetch";

export const verifyNewAccount = (
  data: INewUser,
  captchaResponse: string
): Promise<boolean> => {
  const url = apiUrls.newAccountVerify();
  return authenticatedPost(url, {
    payload: {
      data,
      captchaResponse,
    },
  }) as Promise<boolean>;
};

export const verifyKYCUpload = (
  companyId: string,
  file: File,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("companyId", companyId);
  return authenticatedUpload(
    apiUrls.newAccountFileUpload(),
    data,
    onUploadProgress
  );
};
