import React from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface IItem {
  titleId: string;
  descId: string;
}

const items: IItem[] = Array(4)
  .fill(0)
  .map((item, idx) => ({
    titleId: `mcel-home-how-item-${idx + 1}-title`,
    descId: `mcel-home-how-item-${idx + 1}-desc`,
  }));

const How = () => {
  return (
    <section className="mcel-home__section mcel-home__how" id="how">
      <Container>
        <div className="mcel-home__how__title">
          <FormattedMessage id="mcel-home-how-title" tagName="h2" />
        </div>
        <ul className="mcel-home__how__items">
          {items.map((item, idx) => {
            return (
              <li key={idx}>
                <div className="mcel-home__how__items__number">
                  <span>{idx + 1}</span>
                </div>
                <FormattedMessage
                  key={item.titleId}
                  id={item.titleId}
                  tagName="p"
                />
                <FormattedMessage
                  key={item.descId}
                  id={item.descId}
                  tagName="p"
                />
              </li>
            );
          })}
        </ul>
      </Container>
    </section>
  );
};

export default How;
