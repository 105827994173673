import { format, parseISO } from "date-fns";
import { getLocaleFormatter, Beneficiary } from "@gsp/gusto-front-common";

export const localeFormatter = getLocaleFormatter("fr-FR");
export const formatEUR = (amount: number) =>
  localeFormatter.formatCurrency(amount, "EUR");

export const formatDate = (date: string | null | undefined) =>
  date ? format(parseISO(date), "dd/MM/yyyy") : null;

export const formatBeneficiaryDataAddress = (data: Beneficiary) => {
  return data
    ? [data.streetNumber, data.street, data.postalCode, data.cityName]
        .filter((item) => !!item)
        .join(" ")
    : null;
};

export const splitPathname = (pathname: string) => {
  return ["/"].concat(
    pathname
      .split("/")
      .filter((element) => (element !== "" ? element : null))
      .map((element) => `/${element}`)
  );
};
