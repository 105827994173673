import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import loginIcon from "../../../../resources/padlock.svg";

const LoginButton = () => {
  return (
    <Link to="/login">
      <img
        data-testid="login-icon"
        className="mcel-login-icon"
        src={loginIcon}
        alt="Login icon"
      />
      <FormattedMessage id={"mcel-login-button"} />
    </Link>
  );
};

export default LoginButton;
