import IBond from "../models/IBond";
import IBondInvoice from "../models/IBondInvoice";

import apiUrls from "./apiUrls";
import { authenticatedGet } from "./authenticatedFetch";

export interface BondListResponse {
  bonds: IBond[];
  totalPages: number;
  totalItems: number;
}

export const fetchBondList: (
  page: number,
  pageSize: number
) => Promise<BondListResponse> = (page, pageSize) => {
  return authenticatedGet(
    apiUrls.bondList(page, pageSize)
  ) as Promise<BondListResponse>;
};

export const fetchBondInvoicesList: (
  bondNumber: string,
  contractNumber: string
) => Promise<Array<IBondInvoice>> = (bondNumber, contractNumber) => {
  return authenticatedGet(
    apiUrls.bondInvoices(bondNumber, contractNumber)
  ) as Promise<Array<IBondInvoice>>;
};

export const fetchBondEbondList: (
  bondNumber: string,
  contractNumber: string
) => Promise<Array<IBondInvoice>> = (bondNumber, contractNumber) => {
  return authenticatedGet(
    apiUrls.bondEBonds(bondNumber, contractNumber)
  ) as Promise<Array<IBondInvoice>>;
};
