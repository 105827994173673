import React from "react";
import { Row, Col } from "react-bootstrap";

import "../RequestList/RequestCard/style.scss";
import "./style.scss";

const SkeletonCard = () => {
  return (
    <div
      data-testid="mcel-request-card"
      className="request-card mcel-request-status-skeleton">
      <Row className="request-card-header">
        <Col xs={10} sm={8}>
          <div className="skeleton" style={{ width: "60%", height: "2rem" }} />
          <div>
            <div
              className="skeleton"
              style={{ width: "80%", height: "1.5rem" }}
            />
          </div>
          <div className="mcel-request-amounts">
            <div
              className="skeleton"
              style={{ width: "50%", height: "1.5rem" }}
            />
          </div>
        </Col>
        <Col />
      </Row>
    </div>
  );
};

export default SkeletonCard;
