import { createSlice } from "@reduxjs/toolkit";
import IRequest from "../../../models/IRequest";

type stateType = {
  request: {
    selectedRequest: IRequest;
  };
};

const initialRequest: Partial<IRequest> = {
  quotation: undefined,
  status: undefined,
  beneficiary: undefined,
  transaction: undefined,
};

const requestSlice = createSlice({
  name: "request",
  initialState: {
    selectedRequest: initialRequest,
  },
  reducers: {
    setRequest(state, action) {
      state.selectedRequest = action.payload;
    },
  },
});

export const { setRequest } = requestSlice.actions;

export const selectRequest = (state: stateType) =>
  state.request.selectedRequest;

export default requestSlice.reducer;
