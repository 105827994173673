import React, { FC, useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MdOutlineErrorOutline } from "react-icons/md";

import { Banner, Loader, buildClassName } from "@gsp/gusto-front-common";
import {
  paymentIntentRequest,
  paymentIntentValidationRequest,
} from "../../../../../services/payment";

import "./style.scss";

interface IModalBondPayment {
  titleKey: string;
  buttonTextKey: string;
  quotationId: string;
  bondRequestId: string;
  bondValue: string;
  companyName: string;
  requestorUserId: string;
  onSuccess: () => void;
}

const ModalBondPayment: FC<IModalBondPayment> = ({
  titleKey,
  buttonTextKey,
  quotationId,
  bondRequestId,
  bondValue,
  onSuccess,
  companyName,
  requestorUserId,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripeError, setStripeError] = useState("");
  const [error, setError] = useState(false);
  const [isValidCard, setIsValidCard] = useState(false);
  const [isRequestingPayment, setIsRequestingPayment] = useState(false);

  const handleStripeInputChange = useCallback(
    (evt) => {
      setIsValidCard(evt.complete as boolean);
      if (evt.error) {
        setStripeError(evt.error.message as string);
      } else {
        setStripeError("");
      }
    },
    [setStripeError, setIsValidCard]
  );

  const handleStripeSubmit = useCallback(async () => {
    // For integration tests purposes, Stripe is mocked to success transactions
    if (window.IS_MOCKED) {
      const validationResp = (await paymentIntentValidationRequest({
        bondRequestId,
        paymentIntentId: "",
      })) as string;
      setIsRequestingPayment(false);
      if (validationResp === "ok") {
        onSuccess();
      }

      return;
    }
    if (!stripe || !elements || isRequestingPayment) {
      return;
    }
    setIsRequestingPayment(true);
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const res = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: companyName,
          },
        },
      });

      if (res.error) {
        console.error(res.error);
        setIsRequestingPayment(false);
        setError(true);
        return;
      }

      if (res.paymentIntent && res.paymentIntent.id) {
        try {
          const validationResp = (await paymentIntentValidationRequest({
            bondRequestId,
            paymentIntentId: res.paymentIntent.id,
          })) as string;
          setIsRequestingPayment(false);
          if (validationResp === "ok") {
            onSuccess();
          }
        } catch (err) {
          console.error(err);
          setIsRequestingPayment(false);
          setError(true);
        }
      }
    }
  }, [
    bondRequestId,
    isRequestingPayment,
    onSuccess,
    stripe,
    elements,
    stripeClientSecret,
    companyName,
  ]);

  useEffect(() => {
    const getStripeClientSecret = async () => {
      setError(false);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const res = await paymentIntentRequest({
          quotationRequestId: quotationId,
        });
        setStripeClientSecret(res.clientSecret as string);
      } catch (e) {
        console.error(e);
        setError(true);
        return;
      }
    };
    getStripeClientSecret();
  }, [quotationId, setStripeClientSecret]);

  const buttonClass = buildClassName({
    button: true,
    "button--center": true,
    "button--disabled": !window.IS_MOCKED && !isValidCard,
    "button-loader": isRequestingPayment,
  });

  return (
    <div className="modal-payment-container" data-testid="modal-payment">
      <MdOutlineErrorOutline style={{ fontSize: 80, color: "#0065AE" }} />
      <FormattedMessage id={titleKey}>
        {(txt) => <div className="title bold mb-3">{txt}</div>}
      </FormattedMessage>
      <div className="caption">{companyName}</div>
      <div className="caption mb-3">{requestorUserId}</div>
      {stripeError && (
        <div className="label text--error text-left">({stripeError})</div>
      )}
      <div className="card-details">
        {stripeClientSecret ? (
          <>
            <CardElement
              className="input-field mb-3"
              options={{ hidePostalCode: true }}
              onChange={handleStripeInputChange}
            />
            <FormattedMessage
              id={buttonTextKey}
              values={{ price: bondValue + " €" }}>
              {(txt) => (
                <button
                  className={buttonClass}
                  style={{ width: "100%", maxWidth: "100%" }}
                  onClick={handleStripeSubmit}
                  disabled={
                    !window.IS_MOCKED && (!isValidCard || isRequestingPayment)
                  }
                  data-testid="mcel-stripe-payment-button">
                  {isRequestingPayment && (
                    <span className="button-loader__bar"></span>
                  )}
                  {txt}
                </button>
              )}
            </FormattedMessage>
            {error && (
              <Banner bannerType="error" bannerTitleKey="technical-error" />
            )}
          </>
        ) : error ? (
          <Banner
            bannerType="error"
            bannerTitleKey="technical-error"
            data-testid={"fetch-error"}
          />
        ) : (
          <Loader isVisible={true} />
        )}
      </div>
    </div>
  );
};

export default ModalBondPayment;
