import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export interface MastHeadProps {
  isAuthorized: boolean;
}

const MastHead = ({ isAuthorized }: MastHeadProps) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    if (isAuthorized) {
      navigate("/new-quotation");
    } else {
      navigate("/register");
    }
  };

  return (
    <section
      data-testid="mcel-home-mast-head"
      className="mcel-home__section mcel-home__masthead">
      <div className="mcel-home__masthead__title">
        <FormattedMessage id="mcel-home-masthead-title" tagName="h1" />
      </div>
      <FormattedMessage id="mcel-home-masthead-subtitle" tagName="h2" />
      <div className="mcel-home__masthead__btn">
        <button
          data-testid="mcel-home-mast-head-btn"
          className="button button--red button-big"
          onClick={handleButtonClick}>
          <FormattedMessage id="mcel-home-masthead-btn" />
        </button>
      </div>
    </section>
  );
};

export default MastHead;
