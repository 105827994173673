import IRequest from "../models/IRequest";

import apiUrls from "./apiUrls";
import { authenticatedGet } from "./authenticatedFetch";

export const fetchRequest: (
  bondQuotationRequestId: string
) => Promise<IRequest> = (bondQuotationRequestId) => {
  return authenticatedGet(
    apiUrls.getRequest(bondQuotationRequestId)
  ) as Promise<IRequest>;
};
