import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { Stepper, IStep } from "@gsp/gusto-front-common";
import { useAdobeContext } from "../../AdobeAnalytics/AdobeProvider";

import UserAccountCreationContext from "../../../models/UserAccountCreationContext";
import IAccountCreationData from "../../../models/IAccountCreationData";

import Identification from "./Identification";
import CompanySearch from "./CompanySearch";
import ScanID from "./ScanID";
import FileUpload from "./FileUpload";
import ActionEmail from "./ActionEmail";

import "./style.scss";
import GoogleTagManager from "../../GoogleTagManager/GoogleTagManager";

const steps: IStep[] = [
  {
    component: Identification,
    id: 1,
    labelKey: "mcel-account-creation-step-1",
    customControls: true,
    noReturn: true,
  },
  {
    component: CompanySearch,
    id: 2,
    labelKey: "mcel-account-creation-step-2",
  },
  {
    component: ScanID,
    id: 3,
    labelKey: "mcel-account-creation-step-3",
  },
  {
    component: FileUpload,
    id: 4,
    labelKey: "mcel-account-creation-step-4",
    customControls: true,
  },
  {
    component: ActionEmail,
    id: 5,
    labelKey: "mcel-account-creation-step-5",
    customControls: true,
    noReturn: true,
  },
];

const UserAccountCreation = () => {
  const [data, setData] = useState<IAccountCreationData>({});

  const { pushPageLoadDataLayer } = useAdobeContext();

  const pushStepDataLayer = (processStep: number, processStepName: string) => {
    pushPageLoadDataLayer({
      pageName: "Account Creation step " + String(processStep + 1),
      siteSection: "Account Creation",
      event: "Virtual page",
      processName: "Account Creation",
      processStep: String(processStep + 1),
      processStepName,
    });
  };

  const updateData = useCallback((update: IAccountCreationData) => {
    setData((d) => ({
      ...d,
      ...update,
    }));
  }, []);

  const shouldRenderGoogleTagManager = () => {
    return process.env.REACT_APP_ENVIRONMENT === "prod";
  };

  return (
    <UserAccountCreationContext.Provider
      value={{
        pushStepDataLayer,
        data,
        updateData,
      }}>
      {shouldRenderGoogleTagManager() && <GoogleTagManager />}
      <div className="user-account-creation">
        <h3>
          <FormattedMessage id="mcel-account-creation-title" />
        </h3>
        <Stepper steps={steps} initialStep={0} sharedContext={{}} />
      </div>
    </UserAccountCreationContext.Provider>
  );
};

export default UserAccountCreation;
