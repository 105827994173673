import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { addMonths, addDays, startOfDay } from "date-fns";

import { BondType, StepComponent } from "@gsp/gusto-front-common";
import QuotationContext from "../../../../models/QuotationContext";
import "./style.scss";
import { calculateBondRate } from "../../../../utils/calculateBondRate";

const today = startOfDay(new Date());
const tomorrow = addDays(today, 1);
const nextMonth = addMonths(today, 1);

const QuotationType = ({
  setStepCompleted,
  goToNextStep,
  context,
}: StepComponent<QuotationContext>) => {
  const [showPublicMarketGroup, setShowPublicMarketGroup] = useState(false);
  const [showPrivateMarketGroup, setShowPrivateMarketGroup] = useState(false);

  const showOutsourcingOption =
    process.env.REACT_APP_SHOW_SUBCONTRACTOR_GUARANTEE_OPTION === "true";

  const { quotationRequest, updateQuotationRequest, pushStepDataLayer } =
    context;

  useEffect(() => {
    pushStepDataLayer(1, "Type de caution");
  }, []);

  const handlePublicMarketButtonClick = useCallback(() => {
    setShowPublicMarketGroup(true);
  }, []);

  const handlePrivateMarketButtonClick = useCallback(() => {
    setShowPrivateMarketGroup(true);
  }, []);

  const setBondType = useCallback(
    (bondType: BondType) => {
      const bondRate = calculateBondRate(bondType, quotationRequest.bondRate);

      updateQuotationRequest({
        bondType,
        bondRate,
        endDate:
          bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE" ||
          bondType === "JOINT_SURETY"
            ? nextMonth
            : tomorrow,
        // Reset the bond value if the rate changed
        ...(bondRate !== quotationRequest.bondRate
          ? {
              bondValue: {
                value: 0,
                currencyCode: "EUR",
              },
            }
          : null),
      });
      setStepCompleted(true);
      if (goToNextStep) {
        goToNextStep();
      }
    },
    [quotationRequest, updateQuotationRequest, setStepCompleted, goToNextStep]
  );

  const handleSetBondButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, bond: BondType) => {
      setBondType(bond);
    },
    [setBondType]
  );

  return (
    <div data-testid="mcel-bond-type">
      <div className="title text-blue mb-4">
        <FormattedMessage id="mcel-bond-type-title" />
      </div>
      {!showPublicMarketGroup && !showPrivateMarketGroup && (
        <div className="mcel-btn-group">
          <div className="mcel-btn-group__item">
            <button
              data-testid="puclic-market-button"
              className="button-secondary mcel-btn-group__button"
              onClick={handlePublicMarketButtonClick}>
              <FormattedMessage id="mcel-bond-type-btn-public-market" />
            </button>
            <span className="caption">
              <FormattedMessage id="mcel-bond-type-desc-public-market" />
            </span>
          </div>
          <div className="mcel-btn-group__item">
            <button
              data-testid="private-market-button"
              className="button-secondary mcel-btn-group__button"
              onClick={handlePrivateMarketButtonClick}>
              <FormattedMessage id="mcel-bond-type-btn-private-market" />
            </button>
            <span className="caption">
              <FormattedMessage id="mcel-bond-type-desc-private-market" />
            </span>
          </div>
          {showOutsourcingOption && (
            <div className="mcel-btn-group__item">
              <button
                data-testid="sub-contractor-payment-guarantee-button"
                className="button-secondary mcel-btn-group__button"
                onClick={(e) =>
                  handleSetBondButtonClick(e, "SUBCONTRACTOR_PAYMENT_GUARANTEE")
                }>
                <FormattedMessage id="mcel-bond-type-btn-outsourcing" />
              </button>
              <span className="caption">
                <FormattedMessage id="mcel-bond-type-desc-outsourcing" />
              </span>
            </div>
          )}
          <div className="mcel-btn-group__item">
            <button
              data-testid="joint-surety-button"
              className="button-secondary mcel-btn-group__button"
              onClick={(e) => handleSetBondButtonClick(e, "JOINT_SURETY")}>
              <FormattedMessage id="mcel-bond-type-btn-solidarity" />
            </button>
            <span className="caption">
              <FormattedMessage id="mcel-bond-type-desc-solidarity" />
            </span>
          </div>
        </div>
      )}
      {showPublicMarketGroup && (
        <div>
          <div className="body1-light text-blue">
            <FormattedMessage id="mcel-bond-type-subtitle-public-market" />
          </div>
          <div className="mcel-btn-group">
            <div className="mcel-btn-group__item">
              <button
                data-testid="personal-deposit-button"
                className={`button-secondary mcel-btn-group__button
                    ${
                      quotationRequest.bondType === "PERSONAL_DEPOSIT"
                        ? "button--selected"
                        : ""
                    }`}
                onClick={(e) =>
                  handleSetBondButtonClick(e, "PERSONAL_DEPOSIT")
                }>
                <FormattedMessage id="mcel-bond-type-btn-personal-depoisit" />
              </button>
            </div>
            <div className="mcel-btn-group__item">
              <button
                data-testid="guarantee-first-request-button"
                className={`button-secondary mcel-btn-group__button
                    ${
                      quotationRequest.bondType === "GUARANTEE_FIRST_REQUEST"
                        ? "button--selected"
                        : ""
                    }`}
                onClick={(e) =>
                  handleSetBondButtonClick(e, "GUARANTEE_FIRST_REQUEST")
                }>
                <FormattedMessage id="mcel-bond-type-btn-first-request" />
              </button>
            </div>
          </div>
        </div>
      )}
      {showPrivateMarketGroup && (
        <div>
          <div className="body1-light text-blue">
            <FormattedMessage id="mcel-bond-type-subtitle-public-market" />
          </div>
          <div className="mcel-btn-group">
            <div className="mcel-btn-group__item">
              <button
                data-testid="main-company-button"
                className={`button-secondary mcel-btn-group__button
                    ${
                      quotationRequest.bondType === "MAIN_COMPANY"
                        ? "button--selected"
                        : ""
                    }`}
                onClick={(e) => handleSetBondButtonClick(e, "MAIN_COMPANY")}>
                <FormattedMessage id="mcel-bond-type-btn-main-company" />
              </button>
            </div>
            <div className="mcel-btn-group__item">
              <button
                data-testid="sub-contractor-button"
                className={`button-secondary mcel-btn-group__button
                    ${
                      quotationRequest.bondType === "SUBCONTRACTOR"
                        ? "button--selected"
                        : ""
                    }`}
                onClick={(e) => handleSetBondButtonClick(e, "SUBCONTRACTOR")}>
                <FormattedMessage id="mcel-bond-type-btn-subcontractor" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuotationType;
