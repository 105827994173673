import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";

import { buildClassName } from "@gsp/gusto-front-common";

import confident from "../../../../resources/icons/confident.svg";
import easy from "../../../../resources/icons/easy.svg";
import immediate from "../../../../resources/icons/immediate.svg";
import security from "../../../../resources/icons/security.svg";

interface WhyText {
  id: string;
  tagName: keyof JSX.IntrinsicElements;
}

interface WhyImage {
  src: string;
  alt: string;
}

interface HeroItem {
  img: WhyImage;
  texts: WhyText[];
}

const heroItems: HeroItem[] = [
  {
    img: {
      src: immediate,
      alt: "immédiate",
    },
    texts: [
      {
        id: "mcel-home-why-item-1-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-1-desc",
        tagName: "p",
      },
    ],
  },
  {
    img: {
      src: easy,
      alt: "simplicité",
    },
    texts: [
      {
        id: "mcel-home-why-item-2-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-2-desc",
        tagName: "p",
      },
    ],
  },
  {
    img: {
      src: confident,
      alt: "solidité",
    },
    texts: [
      {
        id: "mcel-home-why-item-3-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-3-desc",
        tagName: "p",
      },
    ],
  },
  {
    img: {
      src: security,
      alt: "sécurité",
    },
    texts: [
      {
        id: "mcel-home-why-item-4-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-4-desc",
        tagName: "p",
      },
    ],
  },
];

interface HiddenItem {
  texts: WhyText[];
}

const hiddenItems: HiddenItem[] = [
  {
    texts: [
      {
        id: "mcel-home-why-item-5-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-5-desc",
        tagName: "p",
      },
    ],
  },
  {
    texts: [
      {
        id: "mcel-home-why-item-6-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-6-desc",
        tagName: "p",
      },
    ],
  },
  {
    texts: [
      {
        id: "mcel-home-why-item-7-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-7-desc",
        tagName: "p",
      },
      {
        id: "mcel-home-why-item-7-desc-bis",
        tagName: "p",
      },
    ],
  },
  {
    texts: [
      {
        id: "mcel-home-why-item-8-title",
        tagName: "h3",
      },
      {
        id: "mcel-home-why-item-8-desc",
        tagName: "p",
      },
    ],
  },
];

const Why = () => {
  const [collapse, setCollapse] = useState(true);

  const toggleCollapse = useCallback(() => {
    setCollapse((state) => !state);
  }, [setCollapse]);

  const hiddenItemsClassName = buildClassName({
    "mcel-home__why__hidden-items": true,
    "mcel-home__why__hidden-items--collapse": collapse,
  });

  return (
    <section
      data-testid="mcel-home-why"
      className="mcel-home__section mcel-home__why"
      id="why">
      <Container>
        <div className="mcel-home__why__title">
          <FormattedMessage id="mcel-home-why-title" tagName="h2" />
        </div>
        <ul className="mcel-home__why__hero-items">
          {heroItems.map((item, idx) => {
            return (
              <li key={idx}>
                <img src={item.img.src} alt={item.img.alt} />
                {item.texts.map((t, i) => (
                  <FormattedMessage key={i} id={t.id} tagName={t.tagName} />
                ))}
              </li>
            );
          })}
        </ul>
        <div className="mcel-home__why__subtitle">
          <FormattedMessage id="mcel-home-why-subtitle" tagName="h3" />
        </div>
        <ul
          data-testid="mcel-home-why-collapse-zone"
          className={hiddenItemsClassName}>
          {hiddenItems.map((item, idx) => {
            return (
              <li key={idx}>
                {item.texts.map((t, i) => (
                  <FormattedMessage key={i} id={t.id} tagName={t.tagName} />
                ))}
              </li>
            );
          })}
        </ul>
        <div className="mcel-home__why__collapse-button">
          <button
            data-testid="mcel-home-why-collapse-btn"
            className="button button-circle"
            onClick={toggleCollapse}>
            {collapse ? "+" : "-"}
          </button>
          <FormattedMessage
            id={`mcel-home-why-${collapse ? "more" : "less"}`}
          />
        </div>
      </Container>
    </section>
  );
};

export default Why;
