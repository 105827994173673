import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Checkbox, StepComponent } from "@gsp/gusto-front-common";

import BondRequestContext from "../../../../models/BondRequestContext";

const BondLiability = ({
  context,
  setStepCompleted,
}: StepComponent<BondRequestContext>) => {
  const [agreement, setAgreement] = useState(false);
  const { pushStepDataLayer } = context;

  useEffect(() => {
    pushStepDataLayer(1, "Résponsabilité");
  }, []);

  const handleAgreementChange = useCallback(() => {
    setAgreement((state) => !state);
  }, [setAgreement]);

  useEffect(() => {
    setStepCompleted(agreement);
  }, [agreement, setStepCompleted]);

  return (
    <div data-testid="mcel-liability" className="mcel-bond-request compact">
      <div className="text-blue mb-3">
        <div>
          <FormattedMessage id="mcel-request-bond-liability-info-1" />
        </div>
        <div>
          <FormattedMessage id="mcel-request-bond-liability-info-2" />
        </div>
      </div>
      <div className="mb-3">
        <Checkbox
          dataTestid="mcel-liability-agreement"
          labelKey="mcel-request-bond-liability-agreement"
          changeValue={handleAgreementChange}
          isChecked={agreement}
          center={true}
        />
      </div>
    </div>
  );
};

export default BondLiability;
