import { InfoText } from "@gsp/gusto-front-common";
import React, { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

export interface BondSummaryField {
  label: string;
  value?: string | ReactNode;
  infoText?: string;
}

const BondSummaryRow: FC<BondSummaryField> = ({ label, value, infoText }) => {
  return (
    <tr data-testid={label} className="mb-2">
      <td
        data-testid={`${label}-label`}
        className="mcel-subscribe__group__label">
        <FormattedMessage id={label} />
      </td>
      {value ? (
        <td
          data-testid={`${label}-value`}
          className="mcel-subscribe__group__value text-blue bold">
          {value}
          {infoText && (
            <span
              data-testid={`${label}-info-text`}
              className="mcel-subscribe__group__info">
              <InfoText textKey={infoText} />
            </span>
          )}
        </td>
      ) : null}
    </tr>
  );
};

interface IBondSummaryGroup {
  title: string;
  items: BondSummaryField[];
  dataTestid?: string;
}

export const MCELBondSummaryGroup: FC<IBondSummaryGroup> = ({
  title,
  items,
  dataTestid,
}) => {
  return (
    <div data-testid={dataTestid || ""} className="mcel-subscribe__group">
      <div
        data-testid={dataTestid ? `${dataTestid}-title` : ""}
        className="mcel-subscribe__group__title">
        <FormattedMessage id={title} />
      </div>
      <table
        data-testid={dataTestid ? `${dataTestid}-table` : ""}
        className="mcel-subscribe__group__table text-left">
        <tbody>
          {items.map((item, idx) => (
            <BondSummaryRow key={idx} {...item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
