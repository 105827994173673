import React, { useState, useCallback, useContext } from "react";
import { IntlProvider } from "react-intl";

import {
  getCookie,
  setCookie,
  CookieContext,
  LanguageContext,
  Language,
} from "@gsp/gusto-front-common";

import { getUserLang, getMessages, getLocaleCode } from "../../i18n";

const initialLanguage = getUserLang(
  getCookie("userLang") || navigator.language.slice(0, 2)
);

const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { preferences } = useContext(CookieContext);
  const [userLang, setUserLang] = useState(initialLanguage);
  const [localeCode, setLocaleCode] = useState(getLocaleCode(initialLanguage));
  const [messages, setMessages] = useState(getMessages(initialLanguage));
  const refreshLanguage = useCallback(
    (language: Language) => {
      if (preferences.permanent) {
        setCookie("userLang", language);
      }
      setUserLang(language);
      setLocaleCode(getLocaleCode(language));
      setMessages(getMessages(language));
    },
    [preferences.permanent]
  );

  return (
    <LanguageContext.Provider
      value={{
        userLang,
        localeCode,
        messages,
        refreshLang: refreshLanguage,
      }}>
      <IntlProvider locale={userLang} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
