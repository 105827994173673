import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FormattedMessage } from "react-intl";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { Grid } from "@myeh/design-system";

import {
  getLocaleFormatter,
  Banner,
  Input,
  Dropdown,
  RadioGroup,
  StepComponent,
} from "@gsp/gusto-front-common";

import QuotationContext from "../../../../models/QuotationContext";

import "./style.scss";

const BOND_BASE_MIN_AMOUNT = 100;
const BOND_BASE_MAX_AMOUNT = 200000;

enum CheckResult {
  TOO_LOW,
  TOO_HIGH,
  OK,
}

const formatNumbers = (nb: number) => {
  let result = getLocaleFormatter("fr-FR")
    .formatAmount(nb)
    .toString()
    .replace(/0{3}\s0{3}$/, "M")
    .replace(/0{3}$/, "K");

  if (/\d$/.exec(result)) {
    result = `${result} `;
  }
  return result;
};

const type = [
  { code: "TTC", labelKey: "mcel-market-value-TTC" },
  { code: "HTC", labelKey: "mcel-market-value-HT" },
];

const radioOptions = [
  { key: "3", label: "3%", value: "0.03" },
  { key: "5", label: "5%", value: "0.05" },
];

const percentSelectOptions = new Array(30).fill(0, 0, 30).map((_, idx) => {
  return {
    code: ((idx + 1) / 100).toFixed(2),
    labelKey: `${idx + 1}%`,
    dontTranslate: true,
  };
});

export const getRangeForBondType = (bondRate: number) => {
  const min = Math.ceil(BOND_BASE_MIN_AMOUNT / bondRate);
  const max = Math.floor(BOND_BASE_MAX_AMOUNT / bondRate);
  return { min, max };
};

const MarketValue = ({
  context,
  setStepCompleted,
}: StepComponent<QuotationContext>) => {
  const numberFormat = new Intl.NumberFormat("fr-FR");

  const { quotationRequest, updateQuotationRequest, pushStepDataLayer } =
    context;

  useEffect(() => {
    pushStepDataLayer(2, "Montant du marché");
  }, []);

  const savedData = useRef(quotationRequest).current;
  const [marketValue, setMarketValue] = useState(
    quotationRequest.marketValue.value
  );
  const [bondRateString, setBondRateString] = useState(
    quotationRequest.bondRate.toString()
  );
  const [taxIndication, setTaxIndication] = useState(
    quotationRequest.taxIndication
  );
  const [doCheck, setDoCheck] = useState(true);
  const [firstBlur, setFirstBlur] = useState(false);
  const [checkResult, setCheckResult] = useState<CheckResult>(CheckResult.OK);
  const { min: minValue, max: maxValue } = useMemo(
    () => getRangeForBondType(quotationRequest.bondRate),
    [quotationRequest.bondRate]
  );

  const showBondRateRadioGroup =
    quotationRequest.bondType === "PERSONAL_DEPOSIT" ||
    quotationRequest.bondType === "GUARANTEE_FIRST_REQUEST";
  const showBondRateList = quotationRequest.bondType === "JOINT_SURETY";
  const showTaxIndication =
    quotationRequest.bondType !== "SUBCONTRACTOR" &&
    quotationRequest.bondType !== "SUBCONTRACTOR_PAYMENT_GUARANTEE";
  const showTVAdisclaimer =
    quotationRequest.bondType === "SUBCONTRACTOR_PAYMENT_GUARANTEE";

  useEffect(() => {
    const bondRate = Number(bondRateString);
    const isDirty =
      marketValue !== savedData.marketValue.value ||
      bondRate !== savedData.bondRate;

    updateQuotationRequest({
      marketValue: {
        value: marketValue,
        currencyCode: "EUR",
      },
      bondRate,
      taxIndication,
      /* If the market value or rate changes, reset the bond value to
        force recomputation in the next step (useful if the user goes
        back and forth) */
      bondValue: isDirty
        ? {
            value: 0,
            currencyCode: "EUR",
          }
        : savedData.bondValue,
    });
  }, [
    marketValue,
    bondRateString,
    updateQuotationRequest,
    taxIndication,
    savedData.bondRate,
    savedData.marketValue.value,
    savedData.bondValue,
  ]);

  const handleMarketValueChange = useCallback((values: NumberFormatValues) => {
    const { floatValue } = values;
    setMarketValue(floatValue ?? 0);
    setDoCheck(true);
  }, []);

  const handleMarketValueBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDoCheck(true);
      setFirstBlur(true);
    },
    []
  );

  useEffect(() => {
    setDoCheck(true);
  }, [minValue, maxValue]);

  useEffect(() => {
    if (doCheck) {
      if (marketValue < minValue) {
        setStepCompleted(false);
        setCheckResult(CheckResult.TOO_LOW);
      } else if (marketValue > maxValue) {
        setStepCompleted(false);
        setCheckResult(CheckResult.TOO_HIGH);
      } else {
        setCheckResult(CheckResult.OK);
        setStepCompleted(true);
      }
      setDoCheck(false);
    }
  }, [marketValue, doCheck, setStepCompleted, minValue, maxValue]);

  return (
    <div data-testid="mcel-market-value">
      <div className="title text-blue mb-4">
        <FormattedMessage id="mcel-market-value-title" />
      </div>
      <div className="body1-light text-blue">
        <FormattedMessage id="mcel-market-value-subtitle" />
      </div>
      <Grid>
        <Grid.Row className="input-price-group">
          <Grid.Column size={showTaxIndication ? "8" : "12"}>
            <NumericFormat
              data-testid="market-value"
              value={marketValue === 0 ? "" : marketValue}
              onValueChange={handleMarketValueChange}
              onBlur={handleMarketValueBlur}
              placeholder={`ex: ${numberFormat.format(minValue)} €`}
              decimalSeparator=","
              decimalScale={2}
              thousandSeparator=" "
              allowNegative={false}
              allowLeadingZeros={false}
              suffix=" €"
              customInput={Input}
              hasError={checkResult !== CheckResult.OK}
            />
          </Grid.Column>
          {showTaxIndication && (
            <Grid.Column size="4">
              <Dropdown
                dataTestid="tax-indication"
                items={type}
                value={taxIndication}
                onChange={setTaxIndication}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {showTVAdisclaimer && (
        <div className="caption text-blue mb-3">
          <FormattedMessage id="mcel-market-value-disclaimer" />
        </div>
      )}
      {showBondRateRadioGroup && (
        <div className="body1-light text-blue">
          <FormattedMessage id="mcel-market-value-bond-percentage" />
        </div>
      )}
      {showBondRateRadioGroup && (
        <RadioGroup
          dataTestid="bond-percent-radio"
          options={radioOptions}
          name="bondRate"
          selectedValue={bondRateString}
          onChange={setBondRateString}
          disabled={false}
          labelSize="small"
        />
      )}
      {showBondRateList && (
        <div className="bond-percent-list-container">
          <Dropdown
            dataTestid="bond-percent-list"
            items={percentSelectOptions}
            value={bondRateString}
            onChange={setBondRateString}
            labelKey="mcel-market-value-bond-percentage"
          />
        </div>
      )}
      {firstBlur && checkResult !== CheckResult.OK && (
        <div data-testid="mcel-market-value-notice" className="text--error">
          <FormattedMessage
            id="mcel-market-value-notice"
            values={{
              min: formatNumbers(minValue),
              max: formatNumbers(maxValue),
            }}
          />
        </div>
      )}
      {firstBlur && checkResult === CheckResult.TOO_HIGH && (
        <div data-testid="mcel-market-value-error">
          <Banner
            bannerType="error"
            bannerTitleKey="mcel-market-value-error"
            bannerTitleValues={{ max: formatNumbers(maxValue) }}
          />
        </div>
      )}
    </div>
  );
};

export default MarketValue;
